import React, { Fragment, HTMLAttributes, useContext, useEffect, useRef, useState } from "react";
import { 
    AngleRightBlack,
    AngleRightGray,
    AngleRightWhite2, 
    CopyBlack, 
    ExitBlack, 
    GearBlack,  
    GoogleLogo,  
    HumanWhite,  
    LensBlack,  
    LinkBlack,  
    LinkCoral,   
    LinkedInLogo,   
    OkWhite, 
    PlusBlack, 
    PlusGreen, 
    PlusWhite, 
    PostBlack, 
    ProtectBlack, 
    StarYellow, 
    SupportBlack,
    TrashBlack,
} from "./Imgs";
import { 
    CheckLatin,
    CommunityLogo,
    CreateHSLColor,
    DateFromString,
    DateTimeFromString,  
    GetOG, 
    Industries, 
    IsClosedBlock, 
    IsReadNotification, 
    IsURL, 
    LogOut, 
    MakeValidURL,  
    MergeArrays,  
    ReadNotification,  
    RequestColors,  
    RequestColors2,  
    RequestsLevel, 
    SetDict, 
    Sticky,
    Strings,
} from "./Data";
import { 
    CardDelete,
    CardUpdate,
    EmailAuth,
    EmailPrefsUpdate, 
    FirstLoginAdd, 
    GoogleAuth, 
    InviteTokenBurn, 
    InviteTokenUse, 
    LeaveFeedback, 
    LinkedInAuth, 
    NewOffer, 
    NotificationsRead, 
    OpenStripePortal, 
    ProfileDelete, 
    ProjectDelete, 
    ProjectUpdate, 
    Subscribe, 
    SuggestAFriend
} from "../Requests";
import {
    AngleRight,
    NewAvatar,
    Loading,
    AnimatedLogo,
    Edit,
} from "./Elements.jsx";
import { useSwipeable } from "react-swipeable";
import { useCookies } from "react-cookie";
import { PrivacyPolicy, UserAgreement } from "./UserAgreement";

import QRCode from "react-qr-code";
import { ProfilePageDesktop} from "../Profile/ProfilePagesDesktop";
import { ProfilePageMobile} from "../Profile/ProfilePagesMobile";
import { GlobalContext, ProfileContext } from "./Contexts";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { messages } from "../../i18n/messages";

const PopUp = (props: any) => {
    const popup = useRef(null);
    const thisPopup = useRef(null);

    const [ popupMaxHeight, setPopupMaxHeight ] = useState(0);
    const [ shown, setShown ] = useState(false);

    const handlers = useSwipeable({
        onSwipedDown: e => { 
            setPopupMaxHeight(0);
            setShown(false);                
            setTimeout(() => {
                props.setShow(false);
            }, 300); 
        },
        onSwiping: e => {
            if (thisPopup.current) { 
                setPopupMaxHeight((thisPopup?.current as HTMLDivElement)?.scrollHeight + (props.black ? 30 : 0) - Math.max(0, e.deltaY)); 
            }
        },
        onTouchStartOrOnMouseDown: e => {
            if (thisPopup.current) { 
                (thisPopup?.current as HTMLDivElement).style.transitionDuration = `0s`;
            }
            document.querySelectorAll(`#popup-frame`).forEach(elem => {
                (elem as HTMLDivElement).style.transitionDuration = `0s`;
            })
        },
        onTouchEndOrOnMouseUp: e => {
            if (thisPopup.current) { 
                (thisPopup?.current as HTMLDivElement).style.transitionDuration = '';
            }
            document.querySelectorAll(`#popup-frame`).forEach(elem => {
                (elem as HTMLDivElement).style.transitionDuration = `0.3s`;
            })
        },        
        preventScrollOnSwipe: true,
    })

    useEffect(() => {
        if (!props.hideBg) {
            document.documentElement.style.overflowY = props.show ? 'hidden' : '';
            if (popup.current) {
                (popup.current as HTMLDivElement).style.display = props.show ? '' : 'none';
            }
        }
    }, [props.show])

    useEffect(() => {
        setShown(!props.close);
        if (props.close) {
            // setPopupBottom(- (thisPopup?.current?.scrollHeight ?? 100) - 50);
            setPopupMaxHeight(0);
        } else {
            setTimeout(() => {
                if (thisPopup?.current) {
                    setPopupMaxHeight((thisPopup?.current as HTMLDivElement)?.scrollHeight + (props.black ? 30 : 0));
                }
            }, 100)            
        }        
        if (props.close) {                   
            setTimeout(() => {
                props.setShow(false);
            }, 300); 
        }
    }, [props.close])

    useEffect(() => {
        
        return () => {
            document.documentElement.style.overflowY = '';
        }
    }, []);

    useEffect(() => {
        if (!props.close && !props.onlyBg) {
            if (thisPopup?.current) {
                setPopupMaxHeight((thisPopup?.current as HTMLDivElement)?.scrollHeight + (props.black ? 30 : 0));
            }
        }
    }, [props.content])

    return (<>
        <div className={`popup centered p-3 ${props.blurBg ? 'blur-bg' : ''}`} ref={popup} data-popup-shown={String(shown)} hidden={props.hideBg} 
            onClick={e => {   
                e.stopPropagation();
                if (!props.preventClose) {
                    const closeBtn = document.querySelector("#close-popup-btn") as HTMLButtonElement;
                    if (closeBtn) {
                        closeBtn.click();
                    } else {
                        setPopupMaxHeight(0);
                        setShown(false); 
                        if (typeof props.setClose === `function`) {
                            props.setClose(true);
                        }           
                        setTimeout(() => {
                            props.setShow(false);
                        }, 300);  
                    }
                }
            }} style={{
                zIndex: props.customZIndex ?? undefined,
                backgroundColor: props.transparentBg ? `transparent` : undefined,
            }}>
            {props.onlyBg && props.content}
        </div>
        <div className={`w-flex row right pt-4`} style={{
            maxHeight: popupMaxHeight + 38,
            overflow: 'hidden',
            position: 'fixed',
            bottom: 0,
            left: 0,
            zIndex: props.customZIndex ?? (props.hideBg ? 199 : 201),
            transition: `max-height 0.3s ease-in-out`,
            pointerEvents: `none`,
        }} id={`popup-frame`} onClick={e => { e.stopPropagation() }}>
            <div ref={thisPopup} className={`popup-content px-${props.black ? (props.white ? 4 : 3) : 3} pb-${props.black ? (props.white ? 4 : 3) : 4} b${props.black ? '-3' : 't-3'}`} 
                data-black={Boolean(props.black)} 
                data-white={Boolean(props.white)}  
                data-blue={Boolean(props.blue)} 
                data-transparent={Boolean(props.transparent)}
                hidden={props.onlyBg} style={{
                    // bottom: popupBottom,
                    pointerEvents: `auto`,
                    backgroundColor: props?.color ?? null,
                    marginBottom: props.black ? 30 : 0,
                    marginRight: props.black ? 30 : 0,
                }}>
                <div className={`row center noscroll`} {...(props.preventClose ? {} : handlers)}>
                    <div className={`slider pb-3 pt-1 ${props.black ? 'neg-mb-1' : ''}`} style={{zIndex: 100}}>
                        <div style={props.black ? {backgroundColor: 'transparent'} : {}}></div>
                    </div>
                </div>
                {props.content}
            </div>
        </div>
    </>)
}

const PopUpRight = (props: any) => {
    // const right = props.black ? 30 : 0;

    const popup = useRef(null);
    const thisPopup = useRef(null);

    // const [ popupRight, setPopupRight ] = useState('-1500px');
    const [ popupMaxWidth, setPopupMaxWidth ] = useState(0);
    const [ shown, setShown ] = useState(false);

    useEffect(() => {
        // document.documentElement.style.overflowY = props.show ? 'hidden' : ''; 
        if (popup.current) {
            (popup.current as HTMLDivElement).style.display = props.show ? '' : 'none';
        }
    }, [props.show])

    useEffect(() => {
        setShown(!props.close);
        if (thisPopup.current) {
            setPopupMaxWidth(props.close ? 0 : (thisPopup.current as HTMLDivElement).scrollWidth + (props.black ? 30 : 0));
        }
        if (props.close) {                   
            setTimeout(() => {
                props.setShow(false);
            }, 400); 
        }
    }, [props.close])

    useEffect(() => {
        
        return () => {
            document.documentElement.style.overflowY = '';
        }
    }, []);    

    useEffect(() => {
        if (!props.close) {
            if (thisPopup?.current) {
                setPopupMaxWidth((thisPopup?.current as HTMLDivElement)?.scrollWidth + (props.black ? 30 : 0));
            }
        }
    }, [props.content])

    return (<>
        <div className={`popup`} ref={popup} data-popup-shown={String(shown)} onClick={e => {
            e.stopPropagation();
            document.querySelectorAll(`button#close-popup-btn`).forEach(elem => {
                (elem as HTMLButtonElement).click();
            })
            // setShown(false);
            // setPopupMaxWidth(0);                  
            // setTimeout(() => {
            //     props.setShow(false);
            // }, 400); 
        }}>
            {props.onlyBg && props.content}
        </div>
        <div ref={thisPopup} className={`popup-content-right bl-4`} 
             hidden={props.onlyBg} style={{
                right: 0,
                bottom: 0,
                maxWidth: `min(${popupMaxWidth}px, 100vw)`,
                transition: `max-width 0.4s ease-in-out`,
                backgroundColor: props?.color ?? null,
            }} onScroll={e => { 
                e.stopPropagation();
                if (props.onScroll) { props.onScroll(e) } 
            }}>
            {props.content}
        </div>
    </>)
}

const PopupBottomSettings = (props: any) => {
    const globalContext = useContext(GlobalContext);
    const [ close, setClose ] = useState<boolean>(false);
    const [ deleteReason, setDeleteReason ] = useState<string>(''); 

    const [ select, setSelect ] = useState<any[]>(Strings()?.delete_reasons ?? [
        {
            title: `I am removing duplicates`,
        },
        {
            title: <FormattedMessage id="settings_profile_delete_reason_1"/>,
        },
        {
            title: <FormattedMessage id="settings_profile_delete_reason_2"/>,
        },
        {
            title: <FormattedMessage id="settings_profile_delete_reason_3"/>,
        },
        {
            title: <FormattedMessage id="settings_profile_delete_reason_5"/>,
        },
    ]);

    useEffect(() => {
        if (select[5].enabled) {
            const list = document.querySelector(`#new-settings-delete-reasons-list`) as HTMLDivElement;
            if (list) {
                list.scrollTo({
                    top: list.scrollHeight,
                    behavior: `smooth`,
                })
            }
        }
    }, [select[5].enabled])

    const otherDescription = useRef(null);

    useEffect(() => {
        setClose(!props.settingsTab);
    }, [props.settingsTab]);

    useEffect(() => {
        const header = document.querySelector(`#new-settings-header-popup-content`) as HTMLDivElement;
        if (header) {
            header.style.boxShadow = '';
        }
    }, [props.settingsTab])

    return (
        <PopUp content={<>    
            <div className={`row block top noscroll nowrap nogap neg-mx-4 pl-4 pr-4 p-3 neg-mt-5 pt-5`} id={`new-settings-header-popup-content`} onScroll={e => {}}>
                {!(props.settingsTab - 201) ? 
                <div className={`row center w-flex`}>
                    <NewAvatar size={6} type={props?.profile?.avatar?.style ?? 0} name={props?.profile?.name ?? 'John Doe'}
                            avatar={props?.profile?.avatar?.link} className={`circled`}/> 
                </div> : 
                <p data-size={4} className={'semibold mr-3'} data-color={'black'}>
                    {!(props.settingsTab - 1) && <FormattedMessage id="privacy"/>}
                    {!(props.settingsTab - 2) && <FormattedMessage id="settings_profile_delete"/>}
                    {!(props.settingsTab - 3) && <FormattedMessage id="user_agreement"/>}
                </p>}
            </div>  

            {!(props.settingsTab - 1) && <div className={`w-flex h-9 y-auto neg-mb-4 pb-1`} onScroll={(e: any) => {
                const header = document.querySelector(`#new-settings-header-popup-content`) as HTMLDivElement;
                if (header) {
                    header.style.boxShadow = e.target.scrollTop > 0 ? `0px 0px 13px 0px rgba(0, 0, 0, 0.14), 0px 1px 2px 0px rgba(0, 0, 0, 0.04)` : '';
                }
            }}>
            <PrivacyPolicy onScroll={(e: any) => {
                            const header = document.querySelector(`#new-settings-header-popup-content`) as HTMLDivElement;
                            if (header) {
                                header.style.boxShadow = e.target.scrollTop > 0 ? `0px 0px 13px 0px rgba(0, 0, 0, 0.14), 0px 1px 2px 0px rgba(0, 0, 0, 0.04)` : '';
                            }
                        }}/></div>} 

            {!(props.settingsTab - 3) && <div className={`w-flex h-9 y-auto neg-mb-4 pb-1`} onScroll={(e: any) => {
                const header = document.querySelector(`#new-settings-header-popup-content`) as HTMLDivElement;
                if (header) {
                    header.style.boxShadow = e.target.scrollTop > 0 ? `0px 0px 13px 0px rgba(0, 0, 0, 0.14), 0px 1px 2px 0px rgba(0, 0, 0, 0.04)` : '';
                }
            }}>
            <UserAgreement onScroll={(e: any) => {
                            const header = document.querySelector(`#new-settings-header-popup-content`) as HTMLDivElement;
                            if (header) {
                                header.style.boxShadow = e.target.scrollTop > 0 ? `0px 0px 13px 0px rgba(0, 0, 0, 0.14), 0px 1px 2px 0px rgba(0, 0, 0, 0.04)` : '';
                            }
                        }}/></div>} 

            {!(props.settingsTab - 2) && <>
            <div className={`h-8 y-auto`} onScroll={(e: any) => {
                const header = document.querySelector(`#new-settings-header-popup-content`) as HTMLDivElement;
                if (header) {
                    header.style.boxShadow = e.target.scrollTop > 0 ? `0px 0px 13px 0px rgba(0, 0, 0, 0.14), 0px 1px 2px 0px rgba(0, 0, 0, 0.04)` : '';
                }
            }} id={`new-settings-delete-reasons-list`}>
                <div className={`w-flex`}>
                    {select.map((elem, index) => {return (                
                        <button className={`block btn-checkbox mobile row top left nowrap noscroll mb-1 w-flex`} 
                                data-selected={elem.enabled} style={{padding: 8}}
                                key={index} onClick={() => {
                                    setSelect(select => SetDict(select, [index, 'enabled'], !select[index].enabled))
                                }}>
                            <div className={`checkbox-radio h-3 w-3 b centered noshrink`} data-checked={elem.enabled}>
                                <img src={OkWhite} alt={`v`}></img>
                            </div>
                            <p data-size={5}>{elem.title}</p>
                        </button>
                    )})}
                </div>

                {select[5].enabled && 
                <textarea className={`w-flex h-7 mb-3 b-1 p-2`} placeholder={messages[globalContext.locale].describe_situation} data-size={6}
                            ref={otherDescription} onChange={e => {setDeleteReason(e.target.value)}}></textarea>}
            </div>

            <button className={`block btn-circled-32 mobile mb-1 row center w-flex`} data-color={'black'} 
                    onClick={() => {
                        setClose(true);
                    }}>
                <p><FormattedMessage id="give_one_more_chance"/></p>
            </button> 

            <button className={`block btn-circled-32 mobile row center w-flex`} data-color={'transparent'} 
                    onClick={() => {
                        if (select.filter(val => val.enabled).length && 
                            (!select[5].enabled || !!deleteReason)) {
                            props.setSettingsTab(201)
                        }
                    }} disabled={!select.find(val => !!val.enabled)}>
                <p data-color={`black`}><FormattedMessage id="settings_profile_delete"/></p>
            </button>
            </>}

            {!(props.settingsTab - 201) && <>
            <p data-size={5} className={`center w-flex mb-1`} data-color={`black`}>
                {`Thank's for your feedback,`}&nbsp;
                {props.profile?.fname ?? `friend`}
                {`, we are constantly improving`}&nbsp; 
                {CommunityLogo[globalContext.community]?.title ?? "Osmos"}
            </p> 
            <p data-size={5} className={`center w-flex mb-3`} data-color={`black`}>
                <FormattedMessage id="settings_profile_delete_final_3"/>
            </p>     

            <button className={`block btn-circled-32 mobile mb-1 row center w-flex`} data-color={'black'} 
                    onClick={() => {
                        setClose(true);
                    }}>
                <p><FormattedMessage id="give_one_more_chance"/></p>
            </button> 

            <button className={`block btn-circled-32 mobile row center w-flex `} data-color={'transparent'} 
                    onClick={() => {
                        ProfileDelete(props?.profile?.links?.email, () => { LogOut() })
                    }} id={`delete-user-btn`}>
                <p data-color={`black`}><FormattedMessage id="settings_profile_delete"/></p>
            </button> 
            </>}  

            <div className={`block p-3 b-3 row top nogap nowrap`} data-color={`black`} style={{
                position: 'fixed',
                right: -310,
                bottom: 58,
                width: 300,
                transition: `right 0.3s ease-in-out`,
            }} id={`successfully-copied-label`}>
                <div className={`block w-3 h-3 b-2 centered neg-mr-3`} data-color={`green`}>
                    <img src={OkWhite} alt={`v`} className={`h-2`}></img>
                </div>
                <p data-size={5} className={`semibold w-flex pl-4`} data-color={`white`}><FormattedMessage id="link_copied"/></p>
            </div>
        </>} show={!!props.settingsTab} setShow={(bool: boolean) => {props.setSettingsTab((val: number | null) => bool ? val : null)}} 
        close={close} setClose={setClose}/>
    )
}

const PopupRightSettings = (props: any) => {
    const [ close, setClose ] = useState<boolean>(false);
    // const [ deleteReason, setDeleteReason ] = useState<string>(''); 

    // const [ select, setSelect ] = useState<any[]>(Strings()?.delete_reasons ?? [
    //     {
    //         title: <FormattedMessage id="settings_profile_delete_reason_0"/>,
    //     },
    //     {
    //         title: <FormattedMessage id="settings_profile_delete_reason_1"/>,
    //     },
    //     {
    //         title: <FormattedMessage id="settings_profile_delete_reason_2"/>,
    //     },
    //     {
    //         title: <FormattedMessage id="settings_profile_delete_reason_3"/>,
    //     },
    //     {
    //         title: <FormattedMessage id="settings_profile_delete_reason_4"/>,
    //     },
    //     {
    //         title: <FormattedMessage id="settings_profile_delete_reason_5"/>,
    //     },
    // ]);

    // const otherDescription = useRef(null);

    useEffect(() => {
        setClose(!props.settingsTab);
    }, [props.settingsTab]);    

    useEffect(() => {
        const header = document.querySelector(`#settings-header-popup-content`) as HTMLDivElement;
        if (header) {
            header.style.boxShadow = '';
        }
    }, [props.settingsTab])

    return (
        <PopUpRight onScroll={(e: any) => {
            const header = document.querySelector(`#settings-header-popup-content`)
            if (header) {
                (header as HTMLDivElement).style.boxShadow = e.target.scrollTop > 0 ? `0px 0px 13px 0px rgba(0, 0, 0, 0.14), 0px 1px 2px 0px rgba(0, 0, 0, 0.04)` : ''
            }
        }} content={<div className={`p-5`} style={{width: 804, maxWidth: `100vw`,}}>                      
            <div className={`block neg-mx-5 p-5 neg-mt-5 pb-4 row mb-2 top noscroll nogap nowrap w-flex-p-5`} style={{
                ...Sticky(0, 1),
                width: 804, maxWidth: `100vw`,
            } as HTMLAttributes<HTMLDivElement>} id={`settings-header-popup-content`}>
                {!(props.settingsTab - 1) &&
                <p className={`text-3 semibold`}><FormattedMessage id="privacy"/></p>}

                {!(props.settingsTab - 2) &&
                <p className={`text-3 semibold`}><FormattedMessage id="settings_profile_delete"/></p>}

                {!(props.settingsTab - 3) &&
                <p className={`text-3 semibold`}><FormattedMessage id="user_agreement"/></p>}
                
                {!(props.settingsTab - 201) &&                    
                <NewAvatar name={props.profile?.name} size={7} avatar={props.profile?.avatar?.link} 
                           type={props.profile?.avatar?.style} className={`circled`}/>}

                <button className={`btn-close centered noshrink`} onClick={() => {
                        setClose(true);
                    }} id={`close-popup-btn`} type={`button`}>
                    <div className={`btn-symbol w-3 h-3 filled close centered`}>
                        <img src={PlusWhite} alt={`+`}></img>
                    </div>
                </button>
            </div>

            {!(props.settingsTab - 1) && <PrivacyPolicy desktop={true}/>} 

            {!(props.settingsTab - 3) && <UserAgreement desktop={true}/>} 

            <div className={`block p-3 b-3 row top nogap nowrap`} data-color={`black`} style={{
                position: 'fixed',
                right: -310,
                bottom: 58,
                width: 300,
                transition: `right 0.3s ease-in-out`,
            }} id={`successfully-copied-label`}>
                <div className={`block w-3 h-3 b-2 centered neg-mr-3`} data-color={`green`}>
                    <img src={OkWhite} alt={`v`} className={`h-2`}></img>
                </div>
                <p data-size={5} className={`semibold w-flex pl-4`} data-color={`white`}><FormattedMessage id="link_copied"/></p>
            </div>
        </div>} show={!!props.settingsTab} setShow={(bool: boolean) => {props.setSettingsTab((val: number | null) => bool ? val : null)}} close={close}/>
    )
}

const SettingsPopupRight = (props: any) => {
    const [ close, setClose ] = useState<boolean>(false);
    const [ cookie ] = useCookies([`user_id_cookie`]);   

    const [ newInvite, setNewInvite ] = useState<any>();
    const [ newInviteRequest, setNewInviteRequest ] = useState<any>();

    const [ invites, setInvites ] = useState<any>()

    const otherDescription = useRef(null);

    const [ deleteReason, setDeleteReason ] = useState<string>('');
    const [ settingsPage, setSettingsPage ] = useState<number>(0);
    const [ inviteRequestId, setInviteRequestId ] = useState<string | null>(null);

    const [ select, setSelect ] = useState<any[]>(Strings()?.delete_reasons ?? [
        {
            title: <FormattedMessage id="settings_profile_delete_reason_0"/>,
        },
        {
            title: <FormattedMessage id="settings_profile_delete_reason_1"/>,
        },
        {
            title: <FormattedMessage id="settings_profile_delete_reason_2"/>,
        },
        {
            title: <FormattedMessage id="settings_profile_delete_reason_3"/>,
        },
        {
            title: <FormattedMessage id="settings_profile_delete_reason_4"/>,
        },
        {
            title: <FormattedMessage id="settings_profile_delete_reason_5"/>,
        },
    ]);

    useEffect(() => {
        setInvites(props?.invites ?? null);
    }, [props.invites])

    useEffect(() => {
        props.onInvitesUpdate(invites);
    }, [invites])    

    useEffect(() => {
        setEmailPrefs({
            projectUpdates: {
                enabled: !!props?.profile?.emails_project_news,
                title: <FormattedMessage id="settings_profile_email_pref_2"/>,
            },
            platformUpdates: {
                enabled: !!props?.profile?.emails_platform_updates,
                title: <FormattedMessage id="settings_profile_email_pref_3"/>,
            },
            calendarUpdates: {
                enabled: !!props?.profile?.emails_matches_calendar_reminders,
                title: <FormattedMessage id="ob_notify_matches"/>,
            },
            allUpdates: {
                enabled: true,
                title: ``,
            },
        })
    }, [props.profile])

    useEffect(() => {
        if (newInvite?.token) {
            navigator.clipboard.writeText(`${window.location.origin}?code=${newInvite?.token}`)
            .then(() => {
                if (document.querySelector(`#successfully-copied-label`)) {
                    (document.querySelector(`#successfully-copied-label`) as HTMLDivElement).style.right = `58px`;
                    setTimeout(() => {
                        if (document.querySelector(`#successfully-copied-label`)) {
                            (document.querySelector(`#successfully-copied-label`) as HTMLDivElement).style.right = `-310px`;
                        }
                    }, 2000)
                }
            })

            setInvites((val: any) => SetDict(val, [`pending`], [newInvite].concat(val.pending)));
            setInvites((val: any) => SetDict(val, [`available`], val.available - 1));
        }
    }, [newInvite]);

    useEffect(() => {
        if (newInviteRequest?.token) {
            navigator.clipboard.writeText(`${window.location.origin}/invite/${newInviteRequest?.token}`)
            .then(() => {
                if (document.querySelector(`#successfully-copied-label`)) {
                    (document.querySelector(`#successfully-copied-label`) as HTMLDivElement).style.right = `58px`;
                    setTimeout(() => {
                        if (document.querySelector(`#successfully-copied-label`)) {
                            (document.querySelector(`#successfully-copied-label`) as HTMLDivElement).style.right = `-310px`;
                        }
                    }, 2000)
                }
            })

            setInvites((val: any) => SetDict(val, [`pending`], [newInvite].concat(val.pending)));
            setInvites((val: any) => SetDict(val, [`available`], val?.available - 1));
        }
    }, [newInviteRequest]);

    useEffect(() => {
        setClose(!props.show);
        if (!props.show) {
            setSettingsPage(0);
            setDeleteReason('');
            setSelect(Strings()?.delete_reasons ?? [
                {
                    title: <FormattedMessage id="settings_profile_delete_reason_0"/>,
                },
                {
                    title: <FormattedMessage id="settings_profile_delete_reason_1"/>,
                },
                {
                    title: <FormattedMessage id="settings_profile_delete_reason_2"/>,
                },
                {
                    title: <FormattedMessage id="settings_profile_delete_reason_3"/>,
                },
                {
                    title: <FormattedMessage id="settings_profile_delete_reason_4"/>,
                },
                {
                    title: <FormattedMessage id="settings_profile_delete_reason_5"/>,
                },
            ]);
        }
    }, [props.show]);

    useEffect(() => {
        const btn = document.querySelector(`#open-settings-button`);

        if (btn) {
            switch (btn.getAttribute('data-destination')) {
                case `invites_new`:
                    setSettingsPage(101);
                    btn.removeAttribute('data-destination');
                    break;
                case `invites_new_request`:
                    setInviteRequestId(btn.getAttribute('data-request-id') ?? null);
                    setSettingsPage(10100);
                    btn.removeAttribute('data-destination');
                    btn.removeAttribute('data-request-id');
                    break;                                            
                case `privacy_policy`: 
                    setSettingsPage(3);      
                    btn.removeAttribute('data-destination');
                    break;
                default:
                    break;
            }
        }
    }, [])

    const [ emailPrefs, setEmailPrefs ] = useState<any>({
        projectUpdates: {
            enabled: !!props?.profile?.emails_project_news,
            title: <FormattedMessage id="settings_profile_email_pref_2"/>,
        },
        platformUpdates: {
            enabled: !!props?.profile?.emails_platform_updates,
            title: <FormattedMessage id="settings_profile_email_pref_3"/>,
        },
        calendarUpdates: {
            enabled: !!props?.profile?.emails_matches_calendar_reminders,
            title: <FormattedMessage id="ob_notify_matches"/>,
        },
        allUpdates: {
            enabled: true,
            title: ``,
        },
    })

    return (
        <PopUpRight onScroll={(e: any) => {
            const header = document.querySelector(`#settings-header-popup-content`)
            if (header) {
                (header as HTMLDivElement).style.boxShadow = e.target.scrollTop > 0 ? `0px 0px 13px 0px rgba(0, 0, 0, 0.14), 0px 1px 2px 0px rgba(0, 0, 0, 0.04)` : ''
            }
        }} content={<div className={`p-5`} style={{width: 804}}>                      
            <div className={`block neg-mx-5 p-5 neg-mt-5 pb-4 row mb-2 top noscroll nogap nowrap w-flex-p-5`} style={{
                ...Sticky(0, 1),
                width: 804,
            } as HTMLAttributes<HTMLDivElement>} id={`settings-header-popup-content`}>
                {!settingsPage &&
                <p data-size={3} className={'semibold'}><FormattedMessage id="settings"/></p>}

                {!(settingsPage - 1) &&
                <div>
                    <button className={`btn-double desktop row left mb-2`} onClick={() => {setSettingsPage(0)}}>
                        <div className={`btn-symbol hover w-3 h-3 centered back`} data-color={'black'}>
                            <AngleRight data-color={`black`}/>
                        </div>
                        <p className={`text-5 semibold`}><FormattedMessage id="settings"/></p>
                    </button>
                    <p className={`text-3 semibold`}><FormattedMessage id="invites_text_1"/></p>
                </div>}

                {(!(settingsPage - 101) || !(settingsPage - 10100)) &&
                <div>
                    {!(settingsPage - 101) &&
                    <button className={`btn-double desktop row left mb-2`} onClick={() => {setSettingsPage(1)}}>
                        <div className={`btn-symbol hover w-3 h-3 centered back`} data-color={'black'}>
                            <AngleRight data-color={`black`}/>
                        </div>
                        <p className={`text-5 semibold`}><FormattedMessage id="invites"/></p>
                    </button>}
                    <p className={`text-3 semibold`}>{`You have ${invites?.available} more invites to share with your friends`}</p>
                </div>}

                {(!(settingsPage - 10101) || !(settingsPage - 1010100)) &&
                <div>
                    <button className={`btn-double desktop row left mb-2`} onClick={() => {setSettingsPage(101)}}>
                        <div className={`btn-symbol hover w-3 h-3 centered back`} data-color={'black'}>
                            <AngleRight data-color={`black`}/>
                        </div>
                        <p className={`text-5 semibold`}><FormattedMessage id="your_invites"/></p>
                    </button>
                    <p className={`text-3 semibold`}><FormattedMessage id="scan_qr"/></p>
                </div>}

                {!(settingsPage - 102) &&
                <div>
                    <button className={`btn-double desktop row left mb-2`} onClick={() => {setSettingsPage(1)}}>
                        <div className={`btn-symbol hover w-3 h-3 centered back`} data-color={'black'}>
                            <AngleRight data-color={`black`}/>
                        </div>
                        <p className={`text-5 semibold`}><FormattedMessage id="invites"/></p>
                    </button>
                    <p className={`text-3 semibold`}><FormattedMessage id="requests_pending"/></p>
                </div>}

                {!(settingsPage - 103) &&
                <div>
                    <button className={`btn-double desktop row left mb-2`} onClick={() => {setSettingsPage(1)}}>
                        <div className={`btn-symbol hover w-3 h-3 centered back`} data-color={'black'}>
                            <AngleRight data-color={`black`}/>
                        </div>
                        <p className={`text-5 semibold`}><FormattedMessage id="invites"/></p>
                    </button>
                    <p className={`text-3 semibold`}><FormattedMessage id="requests_accepted"/></p>
                </div>}                

                {!(settingsPage - 2) &&
                <div>
                    <button className={`btn-double desktop row left mb-2`} onClick={() => {setSettingsPage(0)}}>
                        <div className={`btn-symbol hover w-3 h-3 centered back`} data-color={'black'}>
                            <AngleRight data-color={`black`}/>
                        </div>
                        <p className={`text-5 semibold`}><FormattedMessage id="settings"/></p>
                    </button>
                    <p className={`text-3 semibold`}><FormattedMessage id="settings_support_email"/></p>
                </div>}                

                {!(settingsPage - 3) &&
                <div>
                    <button className={`btn-double desktop row left mb-2`} onClick={() => {setSettingsPage(0)}}>
                        <div className={`btn-symbol hover w-3 h-3 centered back`} data-color={'black'}>
                            <AngleRight data-color={`black`}/>
                        </div>
                        <p className={`text-5 semibold`}><FormattedMessage id="settings"/></p>
                    </button>
                    <p className={`text-3 semibold`}><FormattedMessage id="privacy"/></p>
                </div>}              

                {!(settingsPage - 4) &&
                <div>
                    <button className={`btn-double desktop row left mb-2`} onClick={() => {setSettingsPage(0)}}>
                        <div className={`btn-symbol hover w-3 h-3 centered back`} data-color={'black'}>
                            <AngleRight data-color={`black`}/>
                        </div>
                        <p className={`text-5 semibold`}><FormattedMessage id="settings"/></p>
                    </button>
                    <p className={`text-3 semibold`}><FormattedMessage id="user_agreement"/></p>
                </div>}            

                {!(settingsPage - 5) &&
                <div>
                    <button className={`btn-double desktop row left mb-2`} onClick={() => {setSettingsPage(0)}}>
                        <div className={`btn-symbol hover w-3 h-3 centered back`} data-color={'black'}>
                            <AngleRight data-color={`black`}/>
                        </div>
                        <p className={`text-5 semibold`}><FormattedMessage id="settings"/></p>
                    </button>
                    <p className={`text-3 semibold`}><FormattedMessage id="settings_profile_email_prefs"/></p>
                </div>}            

                {!(settingsPage - 500) &&
                <div>
                    <button className={`btn-double desktop row left mb-2`} onClick={() => {setSettingsPage(0)}}>
                        <div className={`btn-symbol hover w-3 h-3 centered back`} data-color={'black'}>
                            <AngleRight data-color={`black`}/>
                        </div>
                        <p className={`text-5 semibold`}><FormattedMessage id="settings"/></p>
                    </button>
                    <p className={`text-3 semibold mb-3`}><FormattedMessage id="marketing_emails"/></p>
                    <p data-size={5} className={`neg-mr-5 w-flex`} style={{maxWidth: 688}}><FormattedMessage id="marketing_unsub"/></p>
                </div>}  

                {!(settingsPage - 6) &&
                <div>
                    <button className={`btn-double desktop row left mb-2`} onClick={() => {setSettingsPage(0)}}>
                        <div className={`btn-symbol hover w-3 h-3 centered back`} data-color={'black'}>
                            <AngleRight data-color={`black`}/>
                        </div>
                        <p className={`text-5 semibold`}><FormattedMessage id="settings"/></p>
                    </button>
                    <p className={`text-3 semibold`}><FormattedMessage id="settings_logout_hint"/></p>
                </div>}   

                {!(settingsPage - 7) &&
                <div>
                    <button className={`btn-double desktop row left mb-2`} onClick={() => {setSettingsPage(0)}}>
                        <div className={`btn-symbol hover w-3 h-3 centered back`} data-color={'black'}>
                            <AngleRight data-color={`black`}/>
                        </div>
                        <p className={`text-5 semibold`}><FormattedMessage id="settings"/></p>
                    </button>
                    <p className={`text-3 semibold`}><FormattedMessage id="settings_profile_settings"/></p>
                </div>}     

                {(!(settingsPage - 501) || !(settingsPage - 50201)) &&
                <NewAvatar size={7} type={props?.profile?.avatar?.style ?? 0} name={props?.profile?.name ?? 'John Doe'}
                           avatar={props?.profile?.avatar?.link}/>}

                {!(settingsPage - 502) &&
                <div>
                    <button className={`btn-double desktop row left mb-2`} onClick={() => {setSettingsPage(0)}}>
                        <div className={`btn-symbol hover w-3 h-3 centered back`} data-color={'black'}>
                            <AngleRight data-color={`black`}/>
                        </div>
                        <p className={`text-5 semibold`}><FormattedMessage id="settings"/></p>
                    </button>
                    <p className={`text-3 semibold`}>{`${props.profile?.fname ?? `Friend`}, we are sorry to see you go`}</p>
                </div>}

                <button className={`block btn-close w-5 h-5 b-2 centered noshrink`} data-color={`light-gray`} onClick={() => {
                        setClose(true);
                    }} id={`close-popup-btn`} type={`button`}>
                    <div className={`btn-symbol w-3 h-3 filled close centered`}>
                        <img src={PlusWhite} alt={`+`}></img>
                    </div>
                </button>
            </div>

            {!settingsPage && <>
            {props.isSettings &&
            <button className={`block p-2 b-1 row w-flex mb-2 btn-double`} data-color={'light-gray'} 
                    onClick={() => { setSettingsPage(2) }}>
                <div className={`row left`}>  
                    <img className={`w-3`} src={SupportBlack} alt={""}></img>
                    <p data-size={5} className={`semibold`} data-color={`black`}><FormattedMessage id="settings_support"/></p>
                </div>
                <img src={AngleRightGray} className={`h-3`} alt={`>`} style={{opacity: 0.4}}></img>
            </button>}

            <button className={`block p-2 b-1 row w-flex mb-4 btn-double`} data-color={'light-gray'} 
                    onClick={() => { setSettingsPage(3) }}>
                <div className={`row left`}>  
                    <img className={`w-3`} src={ProtectBlack} alt={""}></img>
                    <p data-size={5} className={`semibold`} data-color={`black`}><FormattedMessage id="privacy"/></p>
                </div>
                <img src={AngleRightGray} className={`h-3`} alt={`>`} style={{opacity: 0.4}}></img>
            </button>

            {props.isSettings &&
            <button className={`block p-2 b-1 row w-flex mb-2 btn-double`} data-color={'light-gray'} 
                    onClick={() => { setSettingsPage(7) }}>
                <div className={`row left`}>  
                    <img className={`w-3`} src={GearBlack} alt={""}></img>
                    <p data-size={5} className={`semibold`} data-color={`black`}><FormattedMessage id="settings_profile_settings"/></p>
                </div>
                <img src={AngleRightGray} className={`h-3`} alt={`>`} style={{opacity: 0.4}}></img>
            </button>}

            {props.isSettings &&
            <button className={`block p-2 b-1 row w-flex btn-double`} data-color={'light-gray'} 
                    onClick={() => { setSettingsPage(6) }}>
                <div className={`row left`}>  
                    <img className={`w-3`} src={ExitBlack} alt={""}></img>
                    <p data-size={5} className={`semibold`} data-color={`black`}><FormattedMessage id="settings_logout"/></p>
                </div>
                <img src={AngleRightGray} className={`h-3`} alt={`>`} style={{opacity: 0.4}}></img>
            </button>}
            </>}

            {!(settingsPage - 1) && <>
            <button className={`block p-2 b-1 row w-flex mb-2 btn-double btn-transparent`} data-color={'light-gray'} 
                    onClick={() => {
                        setSettingsPage(101);
                    }} disabled={!invites?.available}>
                <div className={`row left`}>  
                    <div className={`block h-3 min-w-4 b-2 centered custom-hover`} data-color={invites?.available ? `green` : `gray`}>
                        <p data-size={6} className={`narrow not-active`} data-color={`white`}>{invites?.available}</p>
                    </div>
                    <p data-size={5} className={`semibold`} data-color={`black`}><FormattedMessage id="available"/></p>
                </div>
                <img src={AngleRightGray} className={`h-3`} alt={`>`} style={{opacity: 0.4}}></img>
            </button>

            <button className={`block p-2 b-1 row w-flex mb-2 btn-double btn-transparent`} data-color={'light-gray'} 
                    onClick={() => {setSettingsPage(102)}} disabled={!invites?.pending?.length}>
                <div className={`row left`}>  
                    <div className={`block h-3 min-w-4 b-2 centered custom-hover`} data-color={invites?.pending?.length ? `green` : 'gray'}>
                        <p data-size={6} className={`narrow not-active`} data-color={`white`}>
                            {(invites?.pending ?? []).filter((val: any) => !val.hidden).length}
                        </p>
                    </div>
                    <p data-size={5} className={`semibold`} data-color={`black`}><FormattedMessage id="pending"/></p>
                </div>
                <img src={AngleRightGray} className={`h-3`} alt={`>`} style={{opacity: 0.4}}></img>
            </button>

            <button className={`block p-2 b-1 row w-flex btn-double btn-transparent`} data-color={'light-gray'} 
                    onClick={() => {setSettingsPage(103)}} disabled={!invites?.accepted?.length}>
                <div className={`row left`}>  
                    <div className={`block h-3 min-w-4 b-2 centered custom-hover`} data-color={invites?.accepted?.length ? `green` : 'gray'}>
                        <p data-size={6} className={`narrow not-active`} data-color={`white`}>{invites?.accepted?.length}</p>
                    </div>
                    <p data-size={5} className={`semibold`} data-color={`black`}><FormattedMessage id="accepted"/></p>
                </div>
                <img src={AngleRightGray} className={`h-3`} alt={`>`} style={{opacity: 0.4}}></img>
            </button>            
            </>}

            {!(settingsPage - 101) && <>
            <button className={`block p-2 b-1 row center w-flex mb-2 btn-double`} data-color={'blue'} 
                    onClick={() => {  
                        InviteTokenUse(setNewInvite); 
                    }}>
                <p data-size={5} className={`semibold`} data-color={`white`} id={`send-link-btn`}><FormattedMessage id="send_link"/></p>
            </button>
            
            <button className={`block p-2 b-1 row center w-flex btn-double`} data-color={'black'} 
                    onClick={() => {
                        InviteTokenUse(setNewInvite, '', 1, {
                            200: () => {
                                setSettingsPage(10101);
                            }
                        })
                    }}>
                <p data-size={5} className={`semibold`} data-color={`white`}><FormattedMessage id="create_qr"/></p>
            </button>            
            </>}

            {!(settingsPage - 10100) && <>
            <button className={`block p-2 b-1 row center w-flex mb-2 btn-double`} data-color={'blue'} 
                    onClick={() => {  
                        InviteTokenUse(setNewInviteRequest, inviteRequestId); 
                    }}>
                <p data-size={5} className={`semibold`} data-color={`white`} id={`send-link-btn`}><FormattedMessage id="send_link"/></p>
            </button>
            
            <button className={`block p-2 b-1 row center w-flex btn-double`} data-color={'black'} 
                    onClick={() => {
                        InviteTokenUse(setNewInviteRequest, inviteRequestId, 1, {
                            200: () => {
                                setSettingsPage(1010100);
                            }
                        })
                    }}>
                <p data-size={5} className={`semibold`} data-color={`white`}><FormattedMessage id="create_qr"/></p>
            </button>            
            </>}

            {!(settingsPage - 10101) && <>
            <div className={`block p-3 b-2 w-flex mb-5 centered column`} data-color={'light-gray'}>
                <div className={`block b-1 h-8 w-8 p-3`}>
                    <QRCode className={`w-flex h-flex`} value={`${window.location.origin}?code=${newInvite?.token}`}/>
                </div>
            </div>            
            </>}

            {!(settingsPage - 1010100) && <>
            <div className={`block p-3 b-2 w-flex mb-5 centered column`} data-color={'light-gray'}>
                <div className={`block b-1 h-8 w-8 p-3`}>
                    <QRCode className={`w-flex h-flex`} value={`${window.location.origin}/invite/${newInviteRequest?.token}`}/>
                </div>
            </div>            
            </>}

            {!(settingsPage - 102) && <div className={`w-flex neg-mb-3`} style={{overflow: 'hidden'}}>
            {(invites?.pending ?? []).map((elem: any, index: number) => {return (
                <div className={`block b-3 p-2 mb-3 w-flex`} data-color={`light-gray`} key={index}
                    style={{
                        transition: `margin 0.3s ease-in-out`,
                    }} id={`pending-link-block-${elem?.token}`} hidden={elem.hidden}>
                    <p data-size={6} className={`mb-2`} data-color={`gray`}>{DateTimeFromString(elem?.time ?? Date.now() / 1000)}</p>
                    <button className={`block row nowrap mb-2 w-flex desktop btn-flex`} onClick={() => {
                        navigator.clipboard.writeText(`${window.location.origin}?code=${elem?.token}`)
                        .then(() => {
                            if (document.querySelector(`#successfully-copied-label`)) {
                                (document.querySelector(`#successfully-copied-label`) as HTMLDivElement).style.right = `58px`;
                                setTimeout(() => {
                                    if (document.querySelector(`#successfully-copied-label`)) {
                                        (document.querySelector(`#successfully-copied-label`) as HTMLDivElement).style.right = `-310px`;
                                    }
                                }, 2000)
                            }
                        })
                    }}>
                        <p data-size={5} data-color={`black`} data-lines={1}>
                            {`${window.location.origin}?code=${elem?.token}`}
                        </p>
                        <img src={LinkBlack} alt={''} className={`hover-icon h-3`}></img>
                    </button>
                    <div className={`row left`}>
                        <button className={`block btn-circled-40 desktop centered`} data-color={`black`}
                                onClick={() => {
                                    InviteTokenBurn(elem?.token, () => {
                                        (document.querySelector(`#pending-link-block-${elem?.token}`) as HTMLDivElement).style.marginLeft = `800px`;
                                        setTimeout(() => {
                                            (document.querySelector(`#pending-link-block-${elem?.token}`) as HTMLDivElement).style.marginTop = `-${(document.querySelector(`#pending-link-block-${elem?.token}`) as HTMLDivElement).offsetHeight + 24}px`;
                                            setTimeout(() => {
                                                setInvites((val: any) => SetDict(val, [`pending`, val.pending.findIndex((val: any) => val.token === elem?.token), 'hidden'], true));
                                            }, 300)
                                        }, 300)                                        
                                    })
                                }}>
                            <p><FormattedMessage id="revoke_link"/></p>
                        </button>
                    </div>
                </div>
            )})}
            </div>}

            {!(settingsPage - 103) && (invites?.accepted ?? []).map((elem: any, index: number) => {return (
                <div className={`block b-3 p-2 w-flex mb-3`} data-color={`light-gray`} key={index}>
                    <div className={`row top nogap mb-2`}>
                        <div>
                            <p data-size={6} className={`mb-2`} data-color={`gray`}>{DateTimeFromString(elem?.time ?? Date.now() / 1000)}</p>
                            <p data-size={5} data-color={`black`}>
                                <span data-color={`blue`}>{elem?.name ?? `User`}</span>
                                {` joined Osmos.`}
                            </p>
                        </div>
                        <NewAvatar name={elem?.name ?? `. .`} type={elem?.avatar?.style ?? 0} 
                                   avatar={elem?.avatar?.link} size={5}/>
                    </div>
                    <div className={`row left`}>
                        <button className={`block b-4 p-2 pl-3 pr-3 centered btn-double`} data-color={`black`}
                                onClick={() => {
                                    Subscribe(cookie['user_id_cookie'], elem?.id);
                                }} disabled={elem?.followed} id={`follow-user-btn`}>
                            <p data-size={5} className={`semibold`} data-color={`white`}><FormattedMessage id="follow"/></p>
                        </button>
                        <button className={`block b-4 p-2 pl-3 pr-3 centered btn-double`} data-color={`tp-black`}
                                onClick={() => {}}>
                            <p data-size={5} className={`semibold`} data-color={`black`}><FormattedMessage id="later"/></p>
                        </button>
                    </div>
                </div>
            )})}

            {!(settingsPage - 2) && <>
            <button className={`block p-2 b-1 row w-flex mb-2 btn-double`} data-color={'light-gray'} onClick={() => {
                        navigator.clipboard.writeText(`support@osmos.social`).then(() => {
                            (document.querySelector(`#successfully-copied-label`) as HTMLDivElement).style.right = `58px`;
                            setTimeout(() => {
                                (document.querySelector(`#successfully-copied-label`) as HTMLDivElement).style.right = `-310px`;
                            }, 2000)
                        });
                    }}>
                <p data-size={5} data-color={`black`}>{`support@osmos.social`}</p>
                <img src={CopyBlack} alt={`copy`} title={`Copy link`}></img>
            </button>          
            </>}

            {!(settingsPage - 3) && <PrivacyPolicy desktop={true} className={`w-flex`}/>}

            {!(settingsPage - 4) && <></>}

            {!(settingsPage - 5) && <>
            <p data-size={4} className={`semibold mb-3`}><FormattedMessage id="marketing_emails_and_announcements"/></p>

            {[`calendarUpdates`, `projectUpdates`, `platformUpdates`].map((elem, index) => {return (
                <button className={`w-flex desktop btn-checkbox row left top nowrap mb-2`} data-selected={emailPrefs[elem].enabled} key={index}
                        onClick={() => {
                            setEmailPrefs((val: any) => SetDict(val, [elem, `enabled`], !val[elem].enabled));
                        }}>
                    <div className={`checkbox-radio h-3 w-3 b centered noshrink`} data-checked={emailPrefs[elem].enabled}>
                        <img src={OkWhite} alt={`v`}></img>
                    </div>
                    <p data-size={5}>{emailPrefs[elem].title}</p>
                </button>
            )})}

            <button className={`block p-2 b-1 row center w-flex mb-2 mt-5 btn-double`} data-color={'black'} 
                    onClick={() => {
                        EmailPrefsUpdate(props?.profile?.id, {
                            "emails_matches_calendar_reminders": emailPrefs[`calendarUpdates`].enabled,
                            "emails_project_news": emailPrefs[`projectUpdates`].enabled,
                            "emails_platform_updates": emailPrefs[`platformUpdates`].enabled,
                        }, () => {
                            props.onEmailPrefsUpdate(emailPrefs[`calendarUpdates`].enabled, emailPrefs[`projectUpdates`].enabled, emailPrefs[`platformUpdates`].enabled);
                            setSettingsPage(0);
                        })
                    }}>
                <p data-size={5} className={`semibold`} data-color={`white`}><FormattedMessage id="save"/></p>
            </button>

            <button className={`block p-2 b-1 row center w-flex btn-double`} data-color={'light-gray'} 
                    onClick={() => {
                        setSettingsPage(500);
                    }}>
                <p data-size={5} className={`semibold`} data-color={`black`}><FormattedMessage id="settings_profile_email_unsub"/></p>
            </button>           
            </>}

            {!(settingsPage - 500) && <>
                <button className={`block p-2 b-1 row center w-flex mb-2 mt-5 btn-double`} data-color={'black'} 
                    onClick={() => {
                        setSettingsPage(5);
                    }}>
                    <p data-size={5} className={`semibold`} data-color={`white`}><FormattedMessage id="go_back"/></p>
                </button>

                <button className={`block p-2 b-1 row center w-flex btn-double`} data-color={'light-gray'} 
                        onClick={() => {
                            EmailPrefsUpdate(props?.profile?.id, {
                                "emails_matches_calendar_reminders": false,
                                "emails_project_news": false,
                                "emails_platform_updates":false,
                            }, () => {
                                props.onEmailPrefsUpdate(false, false, false);
                                setSettingsPage(501);
                            })
                        }}>
                    <p data-size={5} className={`semibold`} data-color={`black`}><FormattedMessage id="unsub"/></p>
                </button>
            </>}

            {!(settingsPage - 501) && <>
            <p data-size={3} className={`semibold w-flex mb-5`} data-color={`black`}>
                {`Thank you for staying with us, ${props.profile?.fname ?? `friend`}, now you are unsubscribed from all newsletters`}
            </p>

            <button className={`block p-2 b-1 row center w-flex btn-double`} data-color={'blue'} 
                    onClick={() => {
                        setClose(true);
                    }}>
                <p data-size={5} className={`semibold`} data-color={`white`}><FormattedMessage id="thanks"/></p>
            </button> 
            </>}

            {!(settingsPage - 502) && <>
            <div className={`w-flex mb-5`}>
                {select.map((elem, index) => {return (                
                    <button className={`block desktop row top left nowrap noscroll mb-2 w-flex btn-checkbox`} data-selected={elem.enabled}
                            key={index} onClick={() => {
                                setSelect(select => SetDict(select, [index, 'enabled'], !select[index].enabled))
                            }}>
                        <div className={`checkbox-radio h-3 w-3 b centered noshrink`} data-checked={elem.enabled}>
                            <img src={OkWhite} alt={`v`}></img>
                        </div>
                        <p data-size={5}>{elem.title}</p>
                    </button>
                )})}
            </div>

            {select[5].enabled && <>
                <p data-size={4} className={`semibold`}><FormattedMessage id="settings_profile_delete_reason_5"/></p>
                <textarea className={`w-flex h-8 mb-5 mt-2 b-1 p-2`} placeholder={`Type a description`}
                          ref={otherDescription} onChange={e => {setDeleteReason(e.target.value)}}></textarea>
            </>}

            <button className={`block p-2 b-1 mb-2 row center w-flex btn-double`} data-color={'black'} 
                    onClick={() => {
                        setClose(true);
                    }}>
                <p><FormattedMessage id="give_one_more_chance"/></p>
            </button> 

            <button className={`block p-2 b-1 mb-2 row center w-flex btn-double`} data-color={'light-gray'} 
                    onClick={() => {
                        if (select.filter(val => val.enabled).length && 
                            (!select[5].enabled || !!deleteReason)) {
                            setSettingsPage(50201)
                        }
                    }} disabled={!select.find(val => !!val.enabled)}>
                <p data-color={`black`}><FormattedMessage id="delete"/></p>
            </button>
            </>}

            {!(settingsPage - 50201) && <>
            <p data-size={3} className={`semibold w-flex mb-5`} data-color={`black`}>
                {`Thank's for your feedback, ${props.profile?.fname ?? `friend`}, we are constantly improving Osmos`}
            </p>            

            <button className={`block p-2 b-1 mb-2 row center w-flex btn-double`} data-color={'black'} 
                    onClick={() => {
                        setClose(true);
                    }}>
                <p><FormattedMessage id="give_one_more_chance"/></p>
            </button> 

            <button className={`block p-2 b-1 mb-2 row center w-flex btn-double`} data-color={'light-gray'} 
                    onClick={() => {
                        ProfileDelete(props?.profile?.links?.email, () => { LogOut() })
                    }} id={`delete-user-btn`}>
                <p data-color={`black`}><FormattedMessage id="delete"/></p>
            </button> 
            </>} 

            {!(settingsPage - 6) && <>
            <button className={`block p-2 b-1 row center w-flex mb-2 btn-double`} data-color={'black'} 
                    onClick={() => {  
                        setSettingsPage(0); 
                    }}>
                <p data-size={5} className={`semibold`} data-color={`white`} id={`send-link-btn`}><FormattedMessage id="cancel"/></p>
            </button>
            
            <button className={`block p-2 b-1 row center w-flex btn-double`} data-color={'light-gray'} 
                    onClick={() => { LogOut() }}>
                <p data-size={5} className={`semibold`} data-color={`black`}><FormattedMessage id="settings_logout"/></p>
            </button>            
            </>}  

            {!(settingsPage - 7) && <>
                <button className={`block p-2 b-1 row w-flex mb-2 btn-double`} data-color={'light-gray'} 
                    onClick={() => { setSettingsPage(5) }}>
                <div className={`row left`}>  
                    <img className={`w-3`} src={PostBlack} alt={""}></img>
                    <p data-size={5} className={`semibold`} data-color={`black`}><FormattedMessage id="email_newsletters"/></p>
                </div>
                <img src={AngleRightGray} className={`h-3`} alt={`>`} style={{opacity: 0.4}}></img>
            </button>

            <button className={`block p-2 b-1 row w-flex btn-double`} data-color={'light-gray'} 
                    onClick={() => { setSettingsPage(502) }}>
                <div className={`row left`}>  
                    <img className={`w-3`} src={TrashBlack} alt={""}></img>
                    <p data-size={5} className={`semibold`} data-color={`black`}><FormattedMessage id="settings_profile_delete"/></p>
                </div>
                <img src={AngleRightGray} className={`h-3`} alt={`>`} style={{opacity: 0.4}}></img>
            </button>           
            </>}           

            <div className={`block p-3 b-3 row top nogap nowrap`} data-color={`black`} style={{
                position: 'fixed',
                right: -310,
                bottom: 58,
                width: 300,
                transition: `right 0.3s ease-in-out`,
            }} id={`successfully-copied-label`}>
                <div className={`block w-3 h-3 b-2 centered neg-mr-3`} data-color={`green`}>
                    <img src={OkWhite} alt={`v`} className={`h-2`}></img>
                </div>
                <p data-size={5} className={`semibold w-flex pl-4`} data-color={`white`}><FormattedMessage id="link_copied"/></p>
            </div>
        </div>} show={props.show} setShow={props.setShow} close={close}/>
    )
}

const GradePopup = (props: any) => {
    const [ close, setClose ] = useState(false);

    useEffect(() => {
        setClose(!props.show);
    }, [props.show]);

    const [ gradePage, setGradePage ] = useState<number>(0);
    const [ waitGrade, setWaitGrade ] = useState<boolean>(false);

    const [ grade, setGrade ] = useState<number[]>([0, 0]);
    // const [ gradeReasons, setGradeReason ] = useState<string[]>([]);

    const Range1 = useRef(null);
    const Range2 = useRef(null);

    useEffect(() => {
        const ranges = [Range1, Range2]
        grade.forEach((elem: number, index: number) => {
            console.warn(ranges[index].current)
            if (ranges[index].current) {
                const rangeElem = ranges[index].current as HTMLInputElement;
                rangeElem.style.backgroundSize = `${100 * elem / Number(rangeElem.max ?? 2)}% 100%`;
            }
        })
    }, [grade])

    return (
        <PopUp content={<> 
            <div className={`row nowrap top w-flex mb-4`}>
                <p data-size={4}>
                    {gradePage === 0 ? `Was this match relevant to\u00A0your request?` :
                     gradePage === 1 ? `Did you learn something new?` : ""}
                </p> 
                <div className={`block h-3 centered b-3 noshrink px-1 mt-1`} data-color={`green`}>
                    <p data-size={7}>{`${gradePage + 1}/2`}</p>
                </div>
            </div>  
            {gradePage === 0 &&
            <div className={`w-flex`}>                  
                <input type={`range`} className={`range-mobile w-flex mb-3`} min={0} max={100} value={grade[0]} data-full={grade[0] === 100}
                       onChange={e => { setGrade(val => SetDict(val, [0], Number(e.target.value))) }}
                       style={{backgroundSize: `${grade[0]}% 100%`}}></input>
                <div className={`row w-flex nowrap px-1 mb-4`}>
                    {["Not relevant at all", <FormattedMessage id="match_rate_relevant"/>].map((elem, index: number) => 
                    <p data-size={8} key={index}>{elem}</p>
                    )}
                </div>
                <div className={`row nowrap w-flex`}>
                    <button className={`block btn-flex mobile row center w-flex`} data-color={`light-gray`}
                            onClick={() => { setClose(true) }}>
                        <p><FormattedMessage id="exit"/></p>
                    </button>
                    <button className={`block btn-flex mobile row center w-flex`} data-color={`black`}
                            onClick={() => { setGradePage(val => val + 1) }}>
                        <p><FormattedMessage id="next"/></p>
                    </button>
                </div>
            </div>} 
            {gradePage === 1 &&
            <div className={`w-flex`}>                  
                <input type={`range`} className={`range-mobile w-flex mb-3`} min={0} max={100} value={grade[1]} data-full={grade[1] === 100}
                       onChange={e => { setGrade(val => SetDict(val, [1], Number(e.target.value))) }}
                       style={{backgroundSize: `${grade[1]}% 100%`}}></input>
                <div className={`row w-flex nowrap px-1 mb-4`}>
                    {["Not really", "Yes, definitely!"].map((elem: string, index: number) => 
                    <p data-size={8} key={index}>{elem}</p>
                    )}
                </div>
                <div className={`row nowrap w-flex`}>
                    <button className={`block btn-flex desktop row center w-flex`} data-color={`light-gray`}
                            onClick={() => { setGradePage(val => val - 1) }}>
                        <p><FormattedMessage id="back"/></p>
                    </button>
                    <button className={`block btn-flex desktop row center w-flex`} data-color={`black`}
                            onClick={() => { 
                                setWaitGrade(false);
                                LeaveFeedback(
                                    props.match?.id,
                                    { rate: grade, reasons: null },
                                    {
                                        0: () => { setWaitGrade(false) },
                                        200: () => { 
                                            setWaitGrade(false);
                                            if (typeof props.onLeaveFeedback === `function`) {
                                                props.onLeaveFeedback();
                                            }
                                            setClose(true); 
                                        },
                                    }
                                )
                            }}>
                        <p><FormattedMessage id="done"/></p>
                        {waitGrade &&
                        <Loading width={24} height={24} color={`white`}/>}
                    </button>
                </div>
            </div>}   
        </>} show={props.show} setShow={props.setShow} close={close} setClose={setClose}/>
    )
}

const GradePopupCenter = (props: any) => {
    const [ close, setClose ] = useState(false);

    useEffect(() => {
        setClose(!props.show);
    }, [props.show]);

    const [ gradePage, setGradePage ] = useState<number>(0);
    const [ waitGrade, setWaitGrade ] = useState<boolean>(false);

    const [ grade, setGrade ] = useState<number[]>([0, 0]);
    // const [ gradeReasons, setGradeReason ] = useState<string[]>([]);

    const Range1 = useRef(null);
    const Range2 = useRef(null);

    useEffect(() => {
        const ranges = [Range1, Range2]
        grade.forEach((elem: number, index: number) => {
            console.warn(ranges[index].current)
            if (ranges[index].current) {
                const rangeElem = ranges[index].current as HTMLInputElement;
                rangeElem.style.backgroundSize = `${100 * elem / Number(rangeElem.max ?? 2)}% 100%`;
            }
        })
    }, [grade])

    return (
        <PopUp onlyBg={true} content={<div className={`block p-4 b-3 w-10 min-h-9 column left`} onClick={e => { e.stopPropagation() }}> 
            <div className={`row nowrap top w-flex`}>
                <p data-size={3}>
                    {gradePage === 0 ? `Was this match relevant to\u00A0your request?` :
                     gradePage === 1 ? `Did you learn something new?` : ""}
                </p> 
                <div className={`block h-3 centered b-3 noshrink px-1`} data-color={`green`}>
                    <p data-size={7}>{`${gradePage + 1}/2`}</p>
                </div>
            </div>  
            {gradePage === 0 &&
            <div className={`w-flex`}>                  
                <input type={`range`} className={`w-flex mb-3`} min={0} max={100} value={grade[0]} data-full={grade[0] === 100}
                       onChange={e => { setGrade(val => SetDict(val, [0], Number(e.target.value))) }}
                       style={{backgroundSize: `${grade[0]}% 100%`}}></input>
                <div className={`row w-flex nowrap px-1 mb-5`}>
                    {["Not relevant at all", <FormattedMessage id="match_rate_relevant"/>].map((elem, index: number) => 
                    <p data-size={8} key={index}>{elem}</p>
                    )}
                </div>
                <div className={`row nowrap w-flex`}>
                    <button className={`block btn-flex desktop row center w-flex`} data-color={`light-gray`}
                            onClick={() => { setClose(true) }}>
                        <p><FormattedMessage id="exit"/></p>
                    </button>
                    <button className={`block btn-flex desktop row center w-flex`} data-color={`black`}
                            onClick={() => { setGradePage(val => val + 1) }}>
                        <p><FormattedMessage id="next"/></p>
                    </button>
                </div>
            </div>} 
            {gradePage === 1 &&
            <div className={`w-flex`}>                  
                <input type={`range`} className={`w-flex mb-3`} min={0} max={100} value={grade[1]} data-full={grade[1] === 100}
                       onChange={e => { setGrade(val => SetDict(val, [1], Number(e.target.value))) }}
                       style={{backgroundSize: `${grade[1]}% 100%`}}></input>
                <div className={`row w-flex nowrap px-1 mb-5`}>
                    {["Not really", "Yes, definitely!"].map((elem: string, index: number) => 
                    <p data-size={8} key={index}>{elem}</p>
                    )}
                </div>
                <div className={`row nowrap w-flex`}>
                    <button className={`block btn-flex desktop row center w-flex`} data-color={`light-gray`}
                            onClick={() => { setGradePage(val => val - 1) }}>
                        <p><FormattedMessage id="back"/></p>
                    </button>
                    <button className={`block btn-flex desktop row center w-flex`} data-color={`black`}
                            onClick={() => { 
                                setWaitGrade(false);
                                LeaveFeedback(
                                    props.match?.id,
                                    { rate: grade, reasons: null },
                                    {
                                        0: () => { setWaitGrade(false) },
                                        200: () => { 
                                            setWaitGrade(false);
                                            if (typeof props.onLeaveFeedback === `function`) {
                                                props.onLeaveFeedback();
                                            }
                                            setClose(true); 
                                        },
                                    }
                                )
                            }}>
                        <p><FormattedMessage id="done"/></p>
                        {waitGrade &&
                        <Loading width={24} height={24} color={`white`}/>}
                    </button>
                </div>
            </div>}   
        </div>} show={props.show} setShow={props.setShow} close={close} setClose={setClose}/>
    )
}

const AddProjectPopup = (props: any) => {
    const [ cookie ] = useCookies(['user_id_cookie']);
    const globalContext = useContext(GlobalContext);

    const [ newPreview, setNewPreview ] = useState<File | null>(null);

    const [ project, setProject ] = useState<any>({
        title: "",
        url: "",
        description: "",
        preview: "",
    })

    const [ previewUrl, setPreviewUrl ] = useState<string>();
    
    useEffect(() => {
        if (newPreview) {
            setPreviewUrl(URL.createObjectURL(newPreview));
        }
    }, [newPreview])

    useEffect(() => {
        setPreviewUrl(props.project?.preview)
    }, [props.project?.preview])

    const [ addButtonDisabled, setAddButtonDisabled ] = useState(false);

    useEffect(() => {
        setProject(props?.project ?? {
            title: "",
            url: "",
            description: "",
            preview: "",
        })
    }, [props.project])

    const [ waitUpdate, setWaitUpdate ] = useState(false);
    const [ waitDelete, setWaitDelete ] = useState(false);

    useEffect(() => {

    }, [waitDelete, waitUpdate])

    const [ showConfirmBack, setShowConfirmBack ] = useState(false);
    const [ showConfirmDelete, setShowConfirmDelete ] = useState(false);
    const [ confirmPopupText, setConfirmPopupText ] = useState<string | React.JSX.Element>('');

    useEffect(() => {
        setConfirmPopupText(val => showConfirmBack ? 
            <FormattedMessage id="confirm_exit_text"/> : 
            (showConfirmDelete ? 'Do you want to delete the project?' : val))
    }, [showConfirmBack, showConfirmDelete])

    const [ invalidForm, setInvalidForm ] = useState(false);

    useEffect(() => {
        document.documentElement.style.overflow = `hidden`;

        return () => {
            document.documentElement.style.overflow = '';
        }
    }, [])
    
    return (
        <form className={`block px-3 pb-2 w-flex h-screen y-auto ${invalidForm && 'invalid-form'}`} data-color={`white`} style={{
            position: `fixed`, top: 0, left: 0, zIndex: 50,
        }}
              onSubmit={e => {
                    e.preventDefault();
                    setAddButtonDisabled(true);
                    setWaitUpdate(true);
                    ProjectUpdate(project?.id ?? 'new', SetDict(project, ['user_id'], cookie['user_id_cookie']), newPreview, () => {
                        props.onClose();
                        props.onSubmit();
                        setProject(props?.project ?? {
                            title: "",
                            url: "",
                            description: "",
                        });
                        setAddButtonDisabled(false);
                        setWaitUpdate(false);
                    }, () => {
                        props.onClose();
                        props.onSubmit();
                        setProject(props?.project ?? {
                            title: "",
                            url: "",
                            description: "",
                        })
                        setAddButtonDisabled(false);
                        setWaitUpdate(false);
                    })
            }} id={`${project?.id ? 'existed' : 'new'}-project-form`} onScroll={e => {
                const header = document.querySelector(`#add-project-header-content`)
                if (header) {
                    (header as HTMLDivElement).style.boxShadow = (e.target as HTMLFormElement).scrollTop > 0 ? `0px 0px 13px 0px rgba(0, 0, 0, 0.14), 0px 1px 2px 0px rgba(0, 0, 0, 0.04)` : ''
                }
                // const footer = document.querySelector(`#add-project-bottom-btn`)
                // if (footer) {
                //     footer.style.boxShadow = e.target.scrollTop < e.target.scrollHeight - e.target.offsetHeight ? `0px 0px 13px 0px rgba(0, 0, 0, 0.14), 0px 1px 2px 0px rgba(0, 0, 0, 0.04)` : 'none'
                // }
             }}>                     
            <div className={`row left pb-3 pt-4 neg-mx-3 px-3 block noscroll nowrap`} id={`add-project-header-content`}
                 style={{
                    ...Sticky(0),
                    transform: `rotate(0deg)`,
                    zIndex: 100,
                 } as HTMLAttributes<HTMLDListElement>}>
                <button className={`btn-circled-24 mobile p-left row nowrap`} onClick={() => {
                    setShowConfirmBack(true);
                    setShowConfirmDelete(false);
                    // props.onClose();
                    // setProject(props?.project ?? {
                    //     title: "",
                    //     url: "",
                    //     description: "",
                    // })
                }} type={`button`}>
                    <img src={AngleRightBlack} alt={`>`} className={`w-3`} style={{transform: `rotate(180deg)`}}></img>
                    <p data-size={5}><FormattedMessage id="projects_add"/></p>
                </button>
            </div>

            <p data-size={5} className={`semibold mb-2`}><FormattedMessage id="projects_url"/></p>
            <div className={`row input-block b-1 mb-3 px-2 ${!IsURL(project?.url) ? 'in' : ''}valid`}>
                <input className={`py-2`} placeholder={messages[globalContext.locale].projects_url_ph} required={true} autoFocus={false}
                    type={`url`} name={`url`} value={project.url} data-size={6} onBlur={async e => {
                            setProject((val: any) => SetDict(val, ['url'], MakeValidURL(e.target.value)));
                            if (e.target.value) {
                                const og = await GetOG(MakeValidURL(e.target.value));
                                if (og.status) {
                                    setProject((val: any) => SetDict(val, ['preview'], og.preview));
                                    setPreviewUrl(val => val ? val : og.preview);
                                    if (!project.title) {
                                        setProject((val: any) => SetDict(val, ['title'], og.title));
                                    }
                                    if (!project.description) {
                                        setProject((val: any) => SetDict(val, ['description'], og.description));
                                    }
                                }
                            }
                        }}
                    onChange={e => {setProject((val: any) => SetDict(val, ['url'], MakeValidURL(e.target.value)))}}></input> 
                <img className={`w-3`} src={!invalidForm || IsURL(project?.url) ? LinkBlack : LinkCoral} alt={`...`}></img>
            </div>
            <p className={`after neg-mt-3 mb-3 pt-1`} data-color={`coral`}></p> 

            <p data-size={5} className={`semibold mb-2`}><FormattedMessage id="project_preview"/></p>
            <div id={`bg-img`} className={`row center block b-1 mb-3 p-2 w-flex`} data-color={`light-gray`}
                style={{backgroundImage: `url(${previewUrl})`, aspectRatio: 2}}>
                {(!!previewUrl) ? 
                <div className={`row left w-flex top h-flex`}>
                    <button className={`block btn mobile w-4 h-4 b-1 centered`} onClick={() => {
                        setNewPreview(null);
                        setPreviewUrl("");
                        setProject((val: any) => SetDict(val, [`preview`], 'delete'));
                    }} type={`button`}>
                        <div className={`btn-symbol w-3 h-3 filled close centered`}>
                            <img src={PlusWhite} alt={`+`}></img>
                        </div>
                    </button>
                </div> : <>
                <input id={`input-img`} className={``} type={`file`} accept={`image/png, image/jpeg, image/gif`}
                    onChange={e => {
                        if (e.target.files?.length) {
                            setNewPreview(e.target.files[0]);
                        }
                    }} name={`preview`}></input>
                <button className={`block btn-flex mobile centered`} data-color={`gray`} type={`button`}
                        onClick={() => { (document.querySelector(`#input-img`) as HTMLInputElement).click() }}>
                    <p><FormattedMessage id="project_upload_preview"/></p>
                </button></>} 
            </div> 

            <p data-size={5} className={`semibold mb-2`}><FormattedMessage id="projects_title"/></p>
            <input className={`mb-3 mobile ${!project?.title?.length ? 'in' : ''}valid ${CheckLatin(project?.title) ? 'only-latin' : ''}`} 
                   placeholder={messages[globalContext.locale].projects_title_ph}
                   required={true} name={`title`} value={project?.title ?? ''} data-size={6} 
                   onChange={e => {setProject((val: any) => SetDict(val, ['title'], e.target.value))}}></input> 
            <p className={`after neg-mt-3 mb-3 pt-1`} data-color={`coral`}></p> 
            
            <p data-size={5} className={`semibold mb-2`}><FormattedMessage id="projects_description"/></p>
            <textarea className={`h-8 b-1 p-2 ${!project?.description?.length ? 'in' : ''}valid ${CheckLatin(project?.description) ? 'only-latin' : ''}`} 
                    placeholder={messages[globalContext.locale].projects_description_ph}
                    required={true} name={`description`} value={project?.description ?? ''} data-size={6}
                    onChange={e => {setProject((val: any) => SetDict(val, ['description'], e.target.value))}}></textarea> 
            <p className={`after pt-1`} data-color={`coral`}></p> 

            {props?.canDelete ? <>            
            <div className={`sticky-bottom- w-flex-p-3 neg-mx-3 block pt-3 pb px-3`} id={`add-project-bottom-btn`}>
                <button className={`block btn-flex mobile row center w-flex`} data-color={`black`} type={`submit`}
                        onClick={() => { setInvalidForm(true) }}>
                    <p><FormattedMessage id="settings_profile_save"/></p>
                </button>
            </div>

            <button className={`block btn-flex mobile row center w-flex mb-6`} data-color={`transparent`} type={`button`} onClick={() => {
                    // setWaitDelete(true);
                    // ProjectDelete(props.project?.id, () => {
                    //     props.onClose();
                    //     props.onDelete();
                    //     setProject(props?.project ?? {
                    //         title: "",
                    //         url: "",
                    //         description: "",
                    //     }) 
                    //     setWaitDelete(false);                          
                    // }, () => {
                    //     setWaitDelete(false);
                    // });  
                    setShowConfirmDelete(true); 
                    setShowConfirmBack(false);
                }}>
                <p data-size={6} className={`semibold`} data-color={`coral`}><FormattedMessage id="project_delete"/></p>
                {/* {waitDelete ?
                <Loading color={`white`} width={24} height={24}/> :
                <div className={`btn-symbol filled close w-3 h-3 centered hover`} data-color={`white`}>
                    <img src={PlusCoral} alt={'+'}></img>
                </div>} */}
            </button>
            </> :
            <div className={`sticky-bottom- pb-6 w-flex-p-3 neg-mx-3 block p-3 pt-3`} id={`add-project-bottom-btn`}>
                <button className={`block btn-flex mobile row center w-flex`} data-color={`black`} type={`submit`} disabled={addButtonDisabled}
                        onClick={() => { setInvalidForm(true) }}>
                    <p><FormattedMessage id="projects_add_btn"/></p>
                </button>
            </div>} 

            {(showConfirmBack || showConfirmDelete) &&
            <ConfirmPopup title=<FormattedMessage id="confirm_header"/> subtitle={confirmPopupText} show={showConfirmBack || showConfirmDelete} 
                        setShow={(show: boolean) => {
                            setShowConfirmBack(show);
                            setShowConfirmDelete(show);
                        }} _id={`yesno`}
                        color_2={`light-gray`} buttonTitle_2={<FormattedMessage id="cancel"/>} onClick_2={() => {}} mobile={true}
                        color_1={showConfirmBack ? `black` : `coral`} buttonTitle_1={showConfirmBack ? <FormattedMessage id="exit"/> : <FormattedMessage id="delete"/>} 
                        onClick_1={() => {
                            setShowConfirmBack(false);
                            setShowConfirmDelete(false);

                            if (!showConfirmBack) {
                                setWaitDelete(true);
                                ProjectDelete(props.project?.id, () => {
                                    props.onClose();
                                    props.onDelete();
                                    setProject(props?.project ?? {
                                        title: "",
                                        url: "",
                                        description: "",
                                    }) 
                                    setWaitDelete(false);                           
                                }, () => {
                                    setWaitDelete(false);
                                });
                            } else {
                                props.onClose();
                                setProject(props?.project ?? {
                                    title: "",
                                    url: "",
                                    description: "",
                                })
                            }
                        }} close={'first'}/>}
        </form>)
}

const AddProjectDesktop = (props: any) => {
    const [ cookie ] = useCookies(['user_id_cookie'])
    const globalContext = useContext(GlobalContext);

    const [ close, setClose ] = useState<boolean>(false);

    const [ invalidForm, setInvalidForm ] = useState<boolean>(false);

    const [ newPreview, setNewPreview ] = useState<File | null>(null);
    // const [ preview, setPreview ] = useState<string | null>(null);

    const [ waitUpdate, setWaitUpdate ] = useState<boolean>(false);
    const [ waitDelete, setWaitDelete ] = useState<boolean>(false);

    useEffect(() => {

    }, [waitDelete, waitUpdate])

    const [ showConfirmBack, setShowConfirmBack ] = useState<boolean>(false);
    const [ showConfirmDelete, setShowConfirmDelete ] = useState<boolean>(false);
    const [ confirmPopupText, setConfirmPopupText ] = useState<string | React.JSX.Element>('');

    const [ project, setProject ] = useState(props?.project ?? {
        title: "",
        url: "",
        description: "",
        preview: "",
    })
    
    const [ previewUrl, setPreviewUrl ] = useState<string>();
    
    useEffect(() => {
        if (newPreview) {
            setPreviewUrl(URL.createObjectURL(newPreview));
        }
    }, [newPreview])

    useEffect(() => {
        setPreviewUrl(props.project?.preview);
    }, [props.project?.preview])

    const [ addButtonDisabled, setAddButtonDisabled ] = useState(false);

    useEffect(() => {
        setClose(!props.show);
    }, [props.show]);

    useEffect(() => {
        setProject(props?.project ?? {
            title: "",
            url: "",
            description: "",
            preview: "",
        })
    }, [props.project])

    useEffect(() => {
        const firstInput = document.querySelector(`#add-project-autofocus-input`);
        setTimeout(() => {
            if (firstInput && !(firstInput as HTMLInputElement).value) {
                (firstInput as HTMLInputElement).focus();
            }
        }, 400)
    }, [])

    useEffect(() => {
        setConfirmPopupText(val => showConfirmBack ? 
            <FormattedMessage id="confirm_exit_text"/> : 
            (showConfirmDelete ? 'Are you sure you want to delete your project?' : val))
        console.warn(showConfirmBack, showConfirmDelete, showConfirmBack ? <FormattedMessage id="confirm_exit_text"/> : (showConfirmDelete ? 'Are you sure you want to delete your project?' : null))
    }, [showConfirmBack, showConfirmDelete])
    
    return (<>
        <PopUp onlyBg={true} show={props.show} setShow={props.setShow} close={close} setClose={setClose} onScroll={(e: any) => { 
                        const header = document.querySelector(`#add-project-popup-content`)
                        if (header) {
                            (header as HTMLDivElement).style.boxShadow = e.target.scrollTop > 0 ? `0px 0px 13px 0px rgba(0, 0, 0, 0.14), 0px 1px 2px 0px rgba(0, 0, 0, 0.04)` : ''
                        }
                    }}
                    content={
                        <form className={`block ${invalidForm && 'invalid-form'} w-10 p-3 b-3`} id={`${project?.id ? 'existed' : 'new'}-project-form`}
                                onSubmit={e => {
                                e.preventDefault();
                                setAddButtonDisabled(true);
                                setWaitUpdate(true);
                                ProjectUpdate(project?.id ?? 'new', SetDict(project, ['user_id'], cookie['user_id_cookie']), newPreview, () => {
                                    setClose(true);
                                    props.onSubmit();
                                    setProject(props?.project ?? {
                                        title: "",
                                        url: "",
                                        description: "",
                                    });
                                    setAddButtonDisabled(false);
                                    setWaitUpdate(false);
                                }, () => {
                                    setClose(true);
                                    props.onSubmit();
                                    setProject(props?.project ?? {
                                        title: "",
                                        url: "",
                                        description: "",
                                    })
                                    setAddButtonDisabled(false);
                                    setWaitUpdate(false);
                                })
                                }} onClick={e => { e.stopPropagation() }}>  
                            <div className={`block row top w-flex`} style={{
                                // ...Sticky(0), 
                                transform: `rotate(0deg)`, 
                                zIndex: 1,
                            } as HTMLAttributes<HTMLDivElement>} data-id={props.headerId}>                
                                <p data-size={5} className={`semibold`}>
                                    {props?.canDelete ? 
                                    <FormattedMessage id="projects_update"/> :
                                    <FormattedMessage id="projects_add"/>}
                                </p>
                                {props?.canClose &&
                                <button className={`btn-close centered`} onClick={() => {
                                    // props.onClose();
                                    // setProject(props?.project ?? {
                                    //     title: "",
                                    //     url: "",
                                    //     description: "",
                                    // })
                                    setShowConfirmBack(true);
                                    setShowConfirmDelete(false);
                                }} type={'button'} id={`close-popup-btn`}>
                                    <div className={`btn-symbol hover w-3 h-3 filled close centered noshrink`}>
                                        <img src={PlusWhite} alt={`+`}></img>
                                    </div>
                                </button>}
                            </div>
                            
                            <div className={`w-flex h-3 neg-mb-3 noshrink`} style={{
                                background: `linear-gradient(rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 0))`,
                                position: `relative`, zIndex: 2
                            }}></div> 
                            <div className={`w-flex h-9 y-auto py-3`}>
                                <p data-size={8} className={`semibold mb-1`}><FormattedMessage id="projects_url"/></p>
                                <div className={`w-flex row nogap desktop input-block mb-3 ${!IsURL(project?.url) ? 'in' : ''}valid`}>
                                    <input className={`desktop`} placeholder={messages[globalContext.locale].projects_url_ph} required={true}
                                        type={`url`} name={`url`} value={project.url} onBlur={async e => {
                                            // setProject(val => SetDict(val, ['url'], MakeValidURL(e.target.value)));
                                            if (e.target.value) {
                                                const og = await GetOG(MakeValidURL(e.target.value));
                                                if (og.status) {
                                                    setProject((val: any) => SetDict(val, ['preview'], og.preview));
                                                    setPreviewUrl(val => val ? val : og.preview);
                                                    if (!project.title) {
                                                        setProject((val: any) => SetDict(val, ['title'], og.title));
                                                    }
                                                    if (!project.description) {
                                                        setProject((val: any) => SetDict(val, ['description'], og.description));
                                                    }
                                                }
                                            }
                                        }} id={`add-project-autofocus-input`}
                                        onChange={e => {setProject((val: any) => SetDict(val, ['url'], MakeValidURL(e.target.value)))}}></input> 
                                    <img className={`w-3`} src={!invalidForm || IsURL(project?.url) ? LinkBlack : LinkCoral} alt={``}></img>
                                </div> 
                                <p className={`after neg-mt-3 mb-3 pt-1`} data-color={`coral`}></p>

                                <p data-size={8} className={`semibold mb-1`}><FormattedMessage id="projects_title"/></p>
                                <input className={`desktop mb-3 ${!project?.title?.length ? 'in' : ''}valid ${CheckLatin(project?.title) ? 'only-latin' : ''}`} 
                                    placeholder={messages[globalContext.locale].projects_title_ph} 
                                    type={`text`}
                                    required={true} name={`title`} value={project.title} 
                                    onChange={e => {setProject((val: any) => SetDict(val, ['title'], e.target.value))}}></input> 
                                <p className={`after neg-mt-3 mb-3 pt-1`} data-color={`coral`}></p>

                                <p data-size={8} className={`semibold mb-1`}><FormattedMessage id="projects_description"/></p>
                                <textarea className={`mb-3 h-7 b-1 p-2 ${!project?.description?.length ? 'in' : ''}valid ${CheckLatin(project?.description) ? 'only-latin' : ''}`} 
                                        placeholder={messages[globalContext.locale].projects_description_ph}
                                        required={true} name={`description`} value={project.description}
                                        onChange={e => {setProject((val: any) => SetDict(val, ['description'], e.target.value))}}></textarea> 
                                <p className={`after neg-mt-3 mb-3 pt-1`} data-color={`coral`}></p>

                                <p data-size={8} className={`semibold mb-1`}><FormattedMessage id="project_preview"/></p>
                                <div id={`bg-img`} className={`row center block b-1 p-2 w-flex`} data-color={`gray-darker`}
                                        style={{backgroundImage: `url(${previewUrl})`, aspectRatio: 2}}>
                                    {(!!previewUrl) ? 
                                    <div className={`row right w-flex top h-flex`}>
                                        <button className={`block btn desktop w-4 h-4 b-1 centered`}
                                                onClick={() => { 
                                                    (document.querySelector(`#input-img`) as HTMLInputElement).click() 
                                                }} type={`button`} data-color={`gray`}>
                                            <Edit color={'black'} className={`w-3 h-3`} hover={false}/>
                                        </button> 
                                        <button className={`block btn w-4 h-4 b-1 centered`} onClick={() => {
                                                setNewPreview(null);
                                                setPreviewUrl(``);
                                                setProject((val: any) => SetDict(val, [`preview`], 'delete'));
                                            }} type={`button`}>
                                            <img className={`hover h-3`} src={TrashBlack} alt={`+`}></img>
                                        </button>
                                    </div> : <>
                                    <input id={`input-img`} className={``} type={`file`} accept={`image/png, image/jpeg, image/gif`}
                                        onChange={e => {
                                            if (e.target.files?.length) {
                                                setNewPreview(e.target.files[0]);
                                            }
                                        }} name={`preview`}></input>
                                    <button className={`block btn-flex desktop centered`} data-color={`light-gray`} type={`button`}
                                            onClick={() => { (document.querySelector(`#input-img`) as HTMLInputElement).click() }}
                                            style={{width: 198}}>
                                        <p><FormattedMessage id="add_picture"/></p>
                                    </button></>} 
                                </div> 
                            </div>                                               
                            <div className={`w-flex h-3 neg-mt-3 noshrink`} style={{
                                background: `linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 70%)`,
                                position: `relative`, zIndex: 2
                            }}></div>

                            {props?.canDelete ? 
                            <div className={`w-flex row left`}>
                                <button className={`block btn-flex desktop w-7 row center`} data-color={`black`} type={`submit`}
                                        onClick={() => { setInvalidForm(true) }}>
                                    <p><FormattedMessage id="settings_profile_save"/></p>
                                </button>
                                {/* <button className={`block btn-flex desktop w-7 row center`} data-color={`transparent`} type={`button`} onClick={() => {
                                        setShowConfirmDelete(true);
                                        setShowConfirmBack(false);  
                                    }}>
                                    <p data-color={`coral`}><FormattedMessage id="project_delete"/></p>
                                </button> */}
                            </div> :
                            <button className={`block btn-flex desktop min-w-7 row center`} data-color={`black`} type={`submit`} disabled={addButtonDisabled}
                                    onClick={() => { setInvalidForm(true) }}>
                                <p><FormattedMessage id="projects_add_btn"/></p>
                            </button>} 
                        </form>}/>
                        
        {(showConfirmBack || showConfirmDelete) &&
        <ConfirmPopup title=<FormattedMessage id="confirm_header"/> subtitle={confirmPopupText} 
                        show={showConfirmBack || showConfirmDelete} setShow={(bool: boolean) => {
                            if (!bool) {
                                setShowConfirmBack(false);
                                setShowConfirmDelete(false);
                            }
                        }} _id={`yesno`}
                        color_2={'light-gray'} buttonTitle_2={<FormattedMessage id="cancel"/>} onClick_2={() => {}} black={true} desktop={true} white={true}
                        color_1={showConfirmBack ? `black` : 'coral'} buttonTitle_1={showConfirmBack ? <FormattedMessage id="exit"/> : <FormattedMessage id="delete"/>} onClick_1={() => {
                            setShowConfirmBack(false);
                            setShowConfirmDelete(false);

                            if (!showConfirmBack) {
                                setWaitDelete(true);
                                ProjectDelete(props.project?.id, () => {
                                    setClose(true);
                                    props.onDelete();
                                    setProject(props?.project ?? {
                                        title: "",
                                        url: "",
                                        description: "",
                                    }) 
                                    setWaitDelete(false);                           
                                }, () => {
                                    setWaitDelete(false);
                                });
                            } else {
                                setClose(true);
                                setProject(props?.project ?? {
                                    title: "",
                                    url: "",
                                    description: "",
                                })
                            }
                        }} close={'both'}/>}
    </>)
}

const AddRequestPopup = (props: any) => {
    const [ request, setRequest ] = useState<number | null>(props.request?.type ?? null);
    const context = useContext(ProfileContext);
    const globalContext = useContext(GlobalContext);

    const [ addButtonDisabled, setAddButtonDisabled ] = useState(false);

    const [ waitUpdate, setWaitUpdate ] = useState<boolean>(false);
    const [ waitDelete, setWaitDelete ] = useState<boolean>(false);

    useEffect(() => {

    }, [waitDelete, waitUpdate])

    const [ showConfirmBack, setShowConfirmBack ] = useState<boolean>(false);
    const [ showConfirmDelete, setShowConfirmDelete ] = useState<boolean>(false);
    const [ confirmPopupText, setConfirmPopupText ] = useState<string | React.JSX.Element>('');

    const [ requestDescription, setRequestDescription ] = useState<string>(props.request?.answers[0] ?? ``);
    const [ tags, setTags ] = useState<string[]>(props.request?.tags ?? []);
    const [ addTag, setAddTag ] = useState<boolean>(false);
    const [ newTag, setNewTag ] = useState<string>("");
    // const [ requestHint, setRequestHint ] = useState<boolean>(false);
    const [ selectBackground, setSelectBackground ] = useState<boolean>(typeof props.request?.style?.background === `number`);
    const [ backgroundType, setBackgroundType ] = useState<number>(props.request?.style?.background ?? 0);
  
    const fontSize = (text: string) => {
        if (text?.length > 145) {
            return 18;
        } 
        if (text?.length > 75) {
            return 20;
        } 
        if (text?.length > 55) {
            return 22;
        } 
        return 24;
    }    

    const hideTagsList = () => {
        setNewTag(''); 
        setAddTag(false);
    }

    useEffect(() => {
        // const imgText = document.querySelector(`#img-text-input`) as HTMLDivElement;
        // if (imgText) {
        //     imgText.textContent = requestDescription;
        // }
        const selectBgBlock = document.querySelector(`#block-select-background`) as HTMLDivElement;
        if (selectBgBlock) {
            selectBgBlock.scrollTo({
                left: selectBackground ? window.innerWidth - 76 - 56 - (9 - backgroundType) * 44 : 0,
                behavior: selectBackground ? `instant` : `instant`,
            });
        }

        const form = document.querySelector(`#${props.request?.id ? 'existed' : 'new'}-request-form`) as HTMLFormElement;
        if (selectBackground && form) {
            form.scrollTo({
                top: form.scrollTop + 57.2,
                behavior: `instant`,
            })
        }
    }, [selectBackground])

    useEffect(() => {
        if (requestDescription?.length > 220) {
            setSelectBackground(false);
        }
    }, [requestDescription])

    useEffect(() => {
        setConfirmPopupText(val => showConfirmBack ? 
            <FormattedMessage id="confirm_exit_text"/> : 
            (showConfirmDelete ? 'Do you want to delete the request?' : val))
    }, [showConfirmBack, showConfirmDelete])

    const [ invalidForm, setInvalidForm ] = useState(false);

    useEffect(() => {
        document.documentElement.style.overflow = `hidden`;

        if (sessionStorage.getItem("request_draft") && !props.request) {
            const draft = JSON.parse(sessionStorage.getItem("request_draft") ?? "{}");
            setRequest(draft?.type ?? null);
            setRequestDescription((draft?.answers ?? [])[0] ?? "");
            setTags(draft?.tags ?? []);
            setBackgroundType(draft?.background_type ?? 0);
            sessionStorage.removeItem("request_draft")
        }

        return () => {
            document.documentElement.style.overflow = '';
        }
    }, [])
    
    return (
        <form className={`block px-3 pb-2 w-flex h-screen y-auto ${invalidForm && 'invalid-form'}`} data-color={`white`} style={{
            position: `fixed`, top: 0, left: 0, zIndex: 50,
        }}
              onSubmit={e => {
                    e.preventDefault();
                    if (request !== null && RequestsLevel(requestDescription)?.isValid) {
                        setAddButtonDisabled(true);
                        setWaitUpdate(true);
                        CardUpdate(props.request?.id ?? 'new', {
                            type: request,
                            answers: [requestDescription, '', '', ''],
                            tags: tags?.length ? tags : [],
                            background_type: selectBackground ? backgroundType : undefined,
                        }, () => {
                            if (context.profile?.requests?.length === 0 && 
                                !(context.profile?.first_login ?? ['requests']).includes(`requests`)) {
                                sessionStorage.setItem(`first_login_requests`, `true`);
                                FirstLoginAdd(context.profile?.id, `requests`);
                            };
                            props.onClose();
                            props.onSubmit();
                            setRequest(props.request?.type ?? null);
                            setAddButtonDisabled(false);
                            setWaitUpdate(false);
                        }, () => {
                            props.onClose();
                            props.onSubmit();
                            setRequest(props.request?.type ?? null)
                            setAddButtonDisabled(false);
                            setWaitUpdate(false);
                        })
                    }
            }} id={`${props.request?.id ? 'existed' : 'new'}-request-form`} onScroll={e => {
                const header = document.querySelector(`#add-project-header-content`)
                if (header) {
                    (header as HTMLDivElement).style.boxShadow = (e.target as HTMLFormElement).scrollTop > 0 ? `0px 0px 13px 0px rgba(0, 0, 0, 0.14), 0px 1px 2px 0px rgba(0, 0, 0, 0.04)` : ''
                }
             }}>                     
            <div className={`row left pb-3 pt-4 neg-mx-3 px-3 block noscroll nowrap`} id={`add-project-header-content`}
                 style={{
                    ...Sticky(0),
                    transform: `rotate(0deg)`,
                    zIndex: 100,
                 } as HTMLAttributes<HTMLDListElement>}>
                <button className={`btn-circled-24 mobile p-left row nowrap`} onClick={() => {
                    setShowConfirmBack(true);
                    setShowConfirmDelete(false);
                }} type={`button`}>
                    <img src={AngleRightBlack} alt={`>`} className={`w-3`} style={{transform: `rotate(180deg)`}}></img>
                    <p data-size={5}>
                        {props.request ? <FormattedMessage id="edit_request"/> : <FormattedMessage id="create_request"/>}
                    </p>
                </button>
            </div>

            <div className={`block b-3`}>
                <p data-size={5} className={`semibold seminarrow mb-3`}>
                    <FormattedMessage id="onboarding_choose_tag"/>
                </p>
                
                <div className={`row left mb-3`}>
                    {[
                        `share experience`,
                        `find a job`,
                        `hire`,
                        `find clients`,
                        `raise funds`,
                        `invest`,
                        `find experts`,
                        `find partners`,
                        `network`,
                    ].map((elem, index) => {return (
                        <button key={index} className={`block btn-circled-32 mobile`} data-color={(index === request) ? `black-40-opacity` : `light-gray`} 
                                type={`button`} onClick={() => { setRequest(index) }} hidden={(/::hidden/).test(elem)}>
                            <p data-color={(index === request) ? null : `black-40-opacity`}>{elem}</p>
                        </button>
                    )})}
                </div>                

                <p data-size={8} className={`semibold seminarrow mb-2`}>
                    <FormattedMessage id="request_industry"/>
                </p>
                {addTag ? <>  
                <input  value={newTag} onChange={e => {
                        setNewTag(e.target.value);
                    }} onKeyDown={e => {
                        if (e.key === "Enter") {
                            e.preventDefault();
                            e.stopPropagation();
                            if (newTag) {
                                setTags((val: any) => val.includes(newTag.trim()) ? val : val.concat([newTag.trim()]));
                                hideTagsList();
                            }
                        }
                    }} onFocus={e => {
                        setAddTag(true);
                    }} className={`mobile mb-2`} 
                    placeholder={messages[globalContext.locale].industry_ph}
                    style={{
                        lineHeight: `30px`, paddingTop: 14, paddingBottom: 14,
                    }} id={`input-industry`} data-size={6}></input>               
                <div className={`row left mb-2`}>
                    {((
                        Industries.filter((val: string) => val.toLowerCase().includes(newTag.toLocaleLowerCase().trim()))?.length &&
                        newTag.trim()?.length
                    ) ?
                    MergeArrays(tags, Industries.filter((val: string) => val.toLowerCase().includes(newTag.toLocaleLowerCase().trim()))) : (tags?.length ? tags : context.profile?.industry ?? []))
                    .map((elem: string, index: number) => {return (
                        <button className={`block btn-circled-32 mobile row`} 
                                data-color={tags.includes(elem) ? 'transparent' : `light-gray`}
                                data-border={tags.includes(elem) ? `green-1` : null}
                                onClick={() => {
                                    setTags((val: any) => 
                                        val.includes(elem) ? val.filter((val_: string) => val_ !== elem) : val.concat([elem])
                                    )
                                    const input = document.querySelector(`#input-tag`) as HTMLInputElement;
                                    if (input) {
                                        input.focus();
                                    }
                                }} type={`button`} id={`select-btn`} key={index}>
                            <p data-size={7} data-color={tags.includes(elem) ? 'green' : `black-40-opacity`}>
                                {elem}
                            </p>
                            {tags.includes(elem) &&
                            <img src={PlusGreen} alt={`+`} style={{transform: `rotate(45deg)`}} className={` neg-mr w-2`}></img>}
                        </button>
                    )})}
                </div>
                <button className={`block btn-circled-32 mobile row center w-7`} onClick={e => {
                        e.stopPropagation();
                        hideTagsList();
                    }} data-color={`black`} type={`button`}>
                        <p><FormattedMessage id="apply"/></p>
                </button>
                </> : <>
                <div className={`row left mb-1`} hidden={!tags?.length}>
                    {tags.map((elem: string, index: number) => 
                    <button className={`block btn-circled-32 mobile row`} data-color={`transparent`} data-border={`green-1`}
                            onClick={() => { setTags(val => val.filter(elem_ => elem_ !== elem)) }} key={index} type={`button`}>
                        <p data-color={`green`} data-size={7}>{elem}</p>
                        <img src={PlusGreen} alt={`+`} style={{transform: `rotate(45deg)`}} className={` neg-mr w-2`}></img>
                    </button>)}
                </div>
                <button className={`block btn-circled-32 mobile p-left row left noscroll nowrap`} onClick={e => {
                    e.stopPropagation();
                    setAddTag(true);
                }} data-color={`black`} type={`button`}>
                    <div className={`btn-symbol w-3 h-3 centered`}>
                        <img src={PlusWhite} alt={`+`}></img>
                    </div>
                    <p><FormattedMessage id="add_industry"/></p>
                </button></>}

                <p data-size={5} className={`semibold px mt-2`}>
                {selectBackground ? <FormattedMessage id="request_select_background"/> : <FormattedMessage id="request_describe_request"/>}
                </p>
                <p data-size={6} className={`px mt`} data-color={`black-40-opacity`} hidden={selectBackground}>
                    <FormattedMessage id="request_describe_request_hint_1"/>{` `}
                    <FormattedMessage id="request_describe_request_hint_2"/>
                </p>

                <div className={`block w-flex mt-2`} style={{
                    position: `relative`, height: selectBackground ? 284 : 164, flexDirection: `column`,
                }}>
                    <textarea className={`p-2 mobile h-flex valid ${selectBackground ? "b-3" : "b-1"}`} data-size={6} 
                            placeholder={(selectBackground || globalContext.locale) ? 
                                messages[globalContext.locale].edit_describe_request : 
                                Strings().requests[request ?? 0]?.placeholder}
                            name={`answer`} onChange={e => {
                                // setRequestHint(e.target.value?.length < 50);
                                setRequestDescription(e.target.value);
                                if (e.target.value?.length < 50) {
                                    e.target.classList.replace(`valid`, `invalid`);
                                } else {
                                    e.target.classList.replace(`invalid`, `valid`);
                                }
                            }} value={requestDescription} style={{
                                wordBreak: `break-word`,
                                ...(selectBackground ? {
                                    fontSize: fontSize(requestDescription), 
                                    fontWeight: 600, lineHeight: `120%`,
                                } : {
                                    paddingTop: 14, marginBottom: -28,
                                    fontSize: 14, lineHeight: `140%`,
                                }),
                            }} data-color={selectBackground ? RequestColors2[backgroundType]?.color : `light-gray`}
                            data-text-color={selectBackground ? RequestColors2[backgroundType]?.textColor : `black`}></textarea>
                    
                    <div className={`block row nowrap noshrink gap-0`} style={{
                        position: `absolute`, 
                        right: 14, bottom: 14, 
                        maxWidth: selectBackground ? `calc(100% - 28px)` : 40,
                        transition: `max-width 0.2s ease-in-out, padding 0.2s ease-in-out`,
                        borderRadius: selectBackground ? 11.5 : 5.5, 
                        padding: selectBackground ? 8 : 2,
                        backgroundColor: `lin`, 
                        overflow: selectBackground ? `auto` : `hidden`,
                        flexDirection: `row-reverse`, boxShadow: `0px 1px 3px 0px #00000024`
                    }} id={`block-select-background`}>
                        <button style={{width: 36, height: 36, borderRadius: 3.5}} 
                        className={`centered block`} onClick={() => { 
                            setSelectBackground(val => !val);
                        }} type={`button`} data-color={selectBackground ? `light-gray` : (requestDescription?.length > 220 ? `gray` : `green`)} 
                        disabled={requestDescription?.length > 220}>
                            <p data-size={5} data-color={selectBackground ? `black` : `white`} className={`semibold seminarrow`}>{`Aa`}</p>
                        </button>
                        <div className={`row left noshrink nogap neg-my neg-ml`} style={{flexDirection: `row`}}>
                            {RequestColors.map((elem: any, index: number) => {return (
                                <div className={`block`} style={{padding: 2, borderRadius: 7.5}} key={index}
                                     data-color={index === backgroundType ? 'black-40-opacity' : null}>
                                    <div className={`block`} style={{padding: 2, borderRadius: 5.5}}>
                                        <button className={`row block`} type={`button`} style={{
                                            // background: `linear-gradient(${elem.gradient.join(", ")})`,
                                            width: 36, height: 36, borderRadius: 3.5,
                                        }} onClick={() => { setBackgroundType(index) }} data-color={elem[0]}></button>
                                    </div>
                                </div>
                            )})}
                        </div>
                    </div>
                </div>
                <div className={`block h w-flex mt b row left`} data-color={`light-gray`} hidden={true}>
                    <div className={`h-flex b`} style={RequestsLevel(requestDescription)?.style}></div>
                </div>
                {RequestsLevel(requestDescription)?.isText &&
                <p data-size={6} className={`mt`} hidden={true}
                    data-color={!RequestsLevel(requestDescription)?.isValid ? `coral` : `black-40-opacity`}>
                    {/* {RequestsLevel(requestDescription)?.text} */}
                    <FormattedMessage id={RequestsLevel(requestDescription)?.locale_text}/>
                </p>}
                {(/[а-яА-Я]/).exec(requestDescription) && 
                <p data-size={6} className={`mt`} data-color={`coral`}>
                    <FormattedMessage id="ob_onlylatin"/>
                </p>}
            </div>             

            {props?.canDelete ? <>            
            <div className={`sticky-bottom- w-flex-p-3 neg-mx-3 block pt-3 pb px-3`} id={`add-project-bottom-btn`}>
                <button className={`block btn-flex mobile row center w-flex`} data-color={`black`} type={`submit`}
                        onClick={() => { setInvalidForm(true) }}>
                    <p><FormattedMessage id="settings_profile_save"/></p>
                </button>
            </div>

            <button className={`block btn-flex mobile row center w-flex mb-6`} data-color={`transparent`} type={`button`} onClick={() => {
                    // setWaitDelete(true);
                    // ProjectDelete(props.project?.id, () => {
                    //     props.onClose();
                    //     props.onDelete();
                    //     setProject(props?.project ?? {
                    //         title: "",
                    //         url: "",
                    //         description: "",
                    //     }) 
                    //     setWaitDelete(false);                          
                    // }, () => {
                    //     setWaitDelete(false);
                    // });  
                    setShowConfirmDelete(true); 
                    setShowConfirmBack(false);
                }}>
                <p data-color={`coral`}><FormattedMessage id="request_delete"/></p>
            </button>
            </> :
            <div className={`sticky-bottom- pb-6 w-flex-p-3 neg-mx-3 block p-3 pt-3`} id={`add-project-bottom-btn`}>
                <button className={`block btn-flex mobile row center w-flex`} data-color={`black`} type={`submit`} disabled={addButtonDisabled}
                        onClick={() => { setInvalidForm(true) }}>
                    <p>{props.request ? <FormattedMessage id="settings_profile_save"/> : <FormattedMessage id="create_request"/>}</p>
                </button>
            </div>} 

            {(showConfirmBack || showConfirmDelete) &&
            <ConfirmPopup title={<FormattedMessage id="confirm_header"/>} subtitle={confirmPopupText} 
                        show={showConfirmBack || showConfirmDelete} setShow={(show: boolean) => {
                            setShowConfirmBack(show);
                            setShowConfirmDelete(show);
                        }} _id={`yesno`}
                        color_3={`light-gray`} 
                        buttonTitle_3={props.request ? undefined : <FormattedMessage id="save_draft"/>} 
                        onClick_3={() => {
                            sessionStorage.setItem(
                                "request_draft",
                                JSON.stringify({
                                    type: request,
                                    answers: [requestDescription, '', '', ''],
                                    tags: tags?.length ? tags : [],
                                    background_type: selectBackground ? backgroundType : undefined,
                                })
                            )
                            props.onClose();
                        }}
                        color_2={`light-gray`} 
                        buttonTitle_2={<FormattedMessage id="cancel"/>} 
                        onClick_2={() => {}} mobile={true}
                        color_1={showConfirmBack ? `black` : `coral`} 
                        buttonTitle_1={showConfirmBack ? <FormattedMessage id="exit"/> : <FormattedMessage id="delete"/>} 
                        onClick_1={() => {
                            setShowConfirmBack(false);
                            setShowConfirmDelete(false);

                            if (!showConfirmBack) {
                                setWaitDelete(true);
                                CardDelete(props.request?.id, () => {
                                    props.onClose();
                                    props.onDelete();
                                    setRequest(props.request?.type ?? null) 
                                    setWaitDelete(false);                           
                                }, () => {
                                    setWaitDelete(false);
                                });
                            } else {
                                props.onClose();
                                setRequest(props.request?.type ?? null)
                            }
                        }} close={'first'}/>}
        </form>)
}

const AddRequestDesktop = (props: any) => {
    const [ close, setClose ] = useState<boolean>(false);
    const context = useContext(ProfileContext);
    const globalContext = useContext(GlobalContext);

    const [ invalidForm, setInvalidForm ] = useState<boolean>(false);

    const [ waitUpdate, setWaitUpdate ] = useState<boolean>(false);
    const [ waitDelete, setWaitDelete ] = useState<boolean>(false);

    useEffect(() => {

    }, [waitDelete, waitUpdate])

    const [ showConfirmBack, setShowConfirmBack ] = useState<boolean>(false);
    const [ showConfirmDelete, setShowConfirmDelete ] = useState<boolean>(false);
    const [ confirmPopupText, setConfirmPopupText ] = useState<string | React.JSX.Element>('');

    const [ request, setRequest ] = useState<number | null>(props.request?.type ?? null)

    const [ addButtonDisabled, setAddButtonDisabled ] = useState(false);

    const [ requestDescription, setRequestDescription ] = useState<string>(props.request?.answers[0] ?? ``);
    const [ tags, setTags ] = useState<string[]>(props.request?.tags ?? []);
    const [ addTag, setAddTag ] = useState<boolean>(false);
    const [ newTag, setNewTag ] = useState<string>("");
    // const [ requestHint, setRequestHint ] = useState<boolean>(false);
    const [ selectBackground, setSelectBackground ] = useState<boolean>(typeof props.request?.style?.background === `number`);
    const [ backgroundType, setBackgroundType ] = useState<number>(props.request?.style?.background ?? 0);
  
    const fontSize = (text: string) => {
        if (text?.length > 145) {
            return 18;
        } 
        if (text?.length > 75) {
            return 20;
        } 
        if (text?.length > 55) {
            return 22;
        } 
        return 24;
    }

    const hideTagsList = () => {
        setNewTag(''); 
        setAddTag(false);
    }

    // useEffect(() => {
    //     const imgText = document.querySelector(`#img-text-input`) as HTMLDivElement;
    //     if (imgText) {
    //         imgText.textContent = requestDescription;
    //     }
    // }, [selectBackground])

    useEffect(() => {
        if (requestDescription?.length > 220) {
            setSelectBackground(false);
        }
    }, [requestDescription])

    useEffect(() => {
        setClose(!props.show);
    }, [props.show]);

    useEffect(() => {
        const firstInput = document.querySelector(`#add-project-autofocus-input`);
        setTimeout(() => {
            if (firstInput) {
                (firstInput as HTMLInputElement).focus();
            }
        }, 400)

        if (sessionStorage.getItem("request_draft") && !props.request) {
            const draft = JSON.parse(sessionStorage.getItem("request_draft") ?? "{}");
            setRequest(draft?.type ?? null);
            setRequestDescription((draft?.answers ?? [])[0] ?? "");
            setTags(draft?.tags ?? []);
            setBackgroundType(draft?.background_type ?? 0);
            sessionStorage.removeItem("request_draft")
        }
    }, [])

    useEffect(() => {
        setConfirmPopupText(val => showConfirmBack ? 
            <FormattedMessage id="confirm_exit_text"/> : 
            (showConfirmDelete ? 'Do you want to delete the project?' : val))
    }, [showConfirmBack, showConfirmDelete])
    
    return (<>
        <PopUp show={props.show} setShow={props.setShow} close={close} onScroll={(e: any) => { 
                        const header = document.querySelector(`#add-project-popup-content`)
                        if (header) {
                            (header as HTMLDivElement).style.boxShadow = e.target.scrollTop > 0 ? `0px 0px 13px 0px rgba(0, 0, 0, 0.14), 0px 1px 2px 0px rgba(0, 0, 0, 0.04)` : ''
                        }
                    }}
                    onlyBg={true} content={
                    <form className={`block b-3 px-3 pb-3 ${invalidForm && 'invalid-form'}`} id={`${props.request?.id ? 'existed' : 'new'}-request-form`}
                            onClick={e => { 
                                e.stopPropagation();
                                // hideTagsList(); 
                            }} onSubmit={e => {
                                e.preventDefault();
                                if (request !== null && RequestsLevel(requestDescription)?.isValid) {
                                    setAddButtonDisabled(true);
                                    setWaitUpdate(true);
                                    CardUpdate(props.request?.id ?? 'new', {
                                        type: request,
                                        answers: [requestDescription, '', '', ''],
                                        tags: tags?.length ? tags : [],
                                        background_type: selectBackground ? backgroundType : undefined,
                                    }, () => {
                                        if (context.profile?.requests?.length === 0 && 
                                            !(context.profile?.first_login ?? ['requests']).includes(`requests`)) {
                                            sessionStorage.setItem(`first_login_requests`, `true`);
                                            FirstLoginAdd(context.profile?.id, `requests`);
                                        }
                                        setClose(true);
                                        props.onSubmit();
                                        setRequest(props.request?.type ?? null);
                                        setAddButtonDisabled(false);
                                        setWaitUpdate(false);
                                    }, () => {
                                        setClose(true);
                                        props.onSubmit();
                                        setRequest(props.request?.type ?? null);
                                        setAddButtonDisabled(false);
                                        setWaitUpdate(false);
                                    })
                                }
                            }} style={{width: 694, maxWidth: `100vw`, overflow: `hidden`}}>                       
                            
                        <div className={`w-flex h-3 neg-mb-3 noshrink`} style={{
                                background: `linear-gradient(rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 0))`,
                                position: `relative`, zIndex: 2
                            }}></div> 
                        <div className={`block w-flex y-auto py-3`} style={{height: 504}}>     
                            <div className={`nowrap row right w-flex neg-mb-3`} 
                                 style={Sticky(0) as HTMLAttributes<HTMLDivElement>} data-id={props.headerId}>
                                {props?.canClose &&
                                <button className={`btn-close centered`} onClick={() => {
                                    setShowConfirmBack(true);
                                    setShowConfirmDelete(false);
                                }} type={'button'} id={`close-popup-btn`}>
                                    <div className={`btn-symbol hover w-3 h-3 filled close centered noshrink`}>
                                        <img src={PlusWhite} alt={`+`}></img>
                                    </div>
                                </button>}
                            </div>                                          
                            <p data-size={5} className={`semibold seminarrow mb-2`}>
                                <FormattedMessage id="describe_expectations"/>&nbsp;
                                {CommunityLogo[globalContext.community]?.title ?? "Osmos"}
                            </p>                            
                            <div className={`row left mb-3 gap-0`}>
                                {[
                                    `share experience`,
                                    `find a job`,
                                    `hire`,
                                    `find clients`,
                                    `raise funds`,
                                    `invest`,
                                    `find experts`,
                                    `find partners`,
                                    `network`,
                                ].map((elem, index) => {return (
                                    <button key={index} className={`block btn-circled-32 desktop`} data-color={(index === request) ? `transparent` : `light-gray`} 
                                            data-border={(index === request) ? "green-1" : null}
                                            type={`button`} onClick={() => { setRequest(index) }} hidden={(/::hidden/).test(elem)}>
                                        <p data-color={(index === request) ? `green` : `black-40-opacity`}>{elem}</p>
                                    </button>
                                )})}
                            </div>

                            <p data-size={8} className={`semibold seminarrow mb-2`}>
                                <FormattedMessage id="request_industry"/>
                            </p>
                            {addTag ? <>  
                            <input  value={newTag} onChange={e => {
                                    setNewTag(e.target.value);
                                }} onKeyDown={e => {
                                    if (e.key === "Enter") {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        if (newTag) {
                                            setTags((val: any) => val.includes(newTag.trim()) ? val : val.concat([newTag.trim()]));
                                            hideTagsList();
                                        }
                                    }
                                }} onFocus={e => {
                                    setAddTag(true);
                                }} className={`desktop mb-1`} 
                                placeholder={messages[globalContext.locale].industry_ph}
                                style={{
                                    lineHeight: `30px`, paddingTop: 14, paddingBottom: 14,
                                }} id={`input-industry`}></input>               
                            <div className={`row left mb-2`}>
                                {((
                                    Industries.filter((val: string) => val.toLowerCase().includes(newTag.toLocaleLowerCase().trim()))?.length &&
                                    newTag.trim()?.length
                                ) ?
                                MergeArrays(tags, Industries.filter((val: string) => val.toLowerCase().includes(newTag.toLocaleLowerCase().trim()))) : (tags?.length ? tags : context.profile?.industry ?? []))
                                .map((elem: string, index: number) => {return (
                                    <button className={`block btn-circled-32 desktop row`} 
                                            data-color={tags.includes(elem) ? 'transparent' : `light-gray`}
                                            data-border={tags.includes(elem) ? `green-1` : null}
                                            onClick={() => {
                                                setTags((val: any) => 
                                                    val.includes(elem) ? val.filter((val_: string) => val_ !== elem) : val.concat([elem])
                                                )
                                                const input = document.querySelector(`#input-tag`) as HTMLInputElement;
                                                if (input) {
                                                    input.focus();
                                                }
                                            }} type={`button`} id={`select-btn`} key={index}>
                                        <p data-color={tags.includes(elem) ? 'green' : `black-40-opacity`}>
                                            {elem}
                                        </p>
                                        {tags.includes(elem) &&
                                        <img src={PlusGreen} alt={`+`} style={{transform: `rotate(45deg)`}} className={` neg-mr w-2`}></img>}
                                    </button>
                                )})}
                            </div>
                            <button className={`block btn-circled-32 desktop row center w-7`} onClick={e => {
                                    e.stopPropagation();
                                    hideTagsList();
                                }} data-color={`black`} type={`button`}>
                                    <p><FormattedMessage id="apply"/></p>
                            </button>
                            </> : <>
                            <div className={`row left mb-1`} hidden={!tags?.length}>
                                {tags.map((elem: string, index: number) => 
                                <button className={`block btn-circled-32 desktop row`} data-color={`transparent`} data-border={`green-1`}
                                        onClick={() => { setTags(val => val.filter(elem_ => elem_ !== elem)) }} key={index} type={`button`}>
                                    <p data-color={`green`}>{elem}</p>
                                    <img src={PlusGreen} alt={`+`} style={{transform: `rotate(45deg)`}} className={` neg-mr w-2`}></img>
                                </button>)}
                            </div>
                            <button className={`block btn-circled-32 desktop p-left row left noscroll nowrap`} onClick={e => {
                                e.stopPropagation();
                                setAddTag(true);
                            }} data-color={`black`} type={`button`}>
                                <div className={`btn-symbol w-3 h-3 centered`}>
                                    <img src={PlusWhite} alt={`+`}></img>
                                </div>
                                <p><FormattedMessage id="add_industry"/></p>
                            </button></>}

                            <p data-size={5} className={`semibold seminarrow px mt-3`}>
                                {selectBackground ? <FormattedMessage id="request_select_background"/> : <FormattedMessage id="request_describe_request"/>}
                            </p>
                            <p data-size={6} className={`semibold px mt`} data-color={`black-40-opacity`} hidden={selectBackground}>
                                <FormattedMessage id="request_describe_request_hint_1"/><br/> 
                                <FormattedMessage id="request_describe_request_hint_2"/>
                            </p>

                            <div className={`block w-flex mt-2`} style={{
                                position: `relative`, height: selectBackground ? 203 : 165, flexDirection: `column`,
                            }}>
                                <textarea className={`h-flex p-2 desktop valid ${selectBackground ? "b-3" : "b-1"}`} 
                                        placeholder={selectBackground ? messages[globalContext.locale]?.edit_describe_request : Strings().requests[request ?? 0]?.placeholder}
                                        value={requestDescription} name={`answer`} onChange={e => {
                                            // setRequestHint(e.target.value?.length < 50);
                                            setRequestDescription(e.target.value);
                                            if (!RequestsLevel(e.target.value)?.isValid) {
                                                e.target.classList.replace(`valid`, `invalid`);
                                            } else {
                                                e.target.classList.replace(`invalid`, `valid`);
                                            }
                                        }} required={true} autoFocus={true} style={{
                                            wordBreak: `break-word`,
                                            ...(selectBackground ? {
                                                fontSize: fontSize(requestDescription), 
                                                fontWeight: 600, lineHeight: `120%`,
                                                padding: 24,
                                            } : {
                                                paddingTop: 14, marginBottom: -28,
                                                fontSize: 16, lineHeight: `140%`,
                                            }),
                                        }} data-color={selectBackground ? RequestColors2[backgroundType]?.color : `light-gray`}
                                        data-text-color={selectBackground ? RequestColors2[backgroundType]?.textColor : `black`}></textarea>

                                <div className={`p-2 pt-3`} style={{
                                        outline: `none`, pointerEvents: `none`,
                                        height: 0, wordBreak: `break-all`,
                                        ...(selectBackground ? {
                                            marginBottom: -38,
                                            opacity: 0.25,
                                            color: RequestColors2[backgroundType]?.textColor, 
                                            textAlign: `center`, fontSize: fontSize(requestDescription), 
                                            fontWeight: 600, lineHeight: `120%`,
                                        } : {
                                            color: `#00000066`, paddingTop: 14, marginBottom: -28,
                                            fontSize: 16, lineHeight: `140%`,
                                        }),
                                    }} hidden={!!requestDescription?.length || true}>
                                    {selectBackground ? messages[globalContext.locale]?.edit_describe_request : Strings().requests[request ?? 0]?.placeholder}
                                </div>
                                <div className={`p-2 pt-3 pb-6`} id={'img-text-input'} style={{
                                        outline: `none`, wordBreak: `break-word`,
                                        ...(selectBackground ? {
                                            color: RequestColors2[backgroundType]?.textColor, 
                                            textAlign: `center`, fontSize: fontSize(requestDescription), 
                                            fontWeight: 600, lineHeight: `120%`,
                                        } : {
                                            color: `#000000`, paddingTop: 14, paddingBottom: 14,
                                            fontSize: 16, lineHeight: `140%`,
                                        }),
                                    }} onInput={e => { 
                                        setRequestDescription((e.target as HTMLDivElement).textContent ?? "") 
                                    }}
                                     role={`textbox`} contentEditable={true} hidden={true}></div>

                                <div className={`block row nowrap noshrink`} style={{
                                    position: `absolute`, right: 14, bottom: 14, 
                                    maxWidth: selectBackground ? 590 : 44,
                                    transition: `max-width 0.2s ease-in-out, padding 0.2s ease-in-out`,
                                    borderRadius: selectBackground ? 11.5 : 5.5, 
                                    border: `solid 2px #FFFFFF`, padding: selectBackground ? 6 : 0,
                                    backgroundColor: `lin`, overflow: `hidden`,
                                    flexDirection: `row-reverse`,
                                    boxShadow: `0px 1px 3px 0px #00000024`,
                                }}>
                                    <button style={{width: 40, height: 40, borderRadius: 3.5}} 
                                    className={`centered block`} onClick={() => { 
                                        setSelectBackground(val => !val);
                                    }} type={`button`} data-color={selectBackground ? `light-gray` : (requestDescription?.length > 220 ? `gray` : `green`)} 
                                    disabled={requestDescription?.length > 220}>
                                        <p data-size={5} data-color={selectBackground ? `black` : `white`} className={`semibold seminarrow`}>{`Aa`}</p>
                                    </button>
                                    <div className={`row left noshrink`} style={{width: 526}}>
                                        {RequestColors2.map((elem: any, index: number) => {return (
                                            <div className={`block`} style={{padding: 2, borderRadius: 7.5, margin: -2}} key={index}
                                                 data-color={index === backgroundType ? 'black-40-opacity' : null}>
                                                <div className={`block`} style={{padding: 2, borderRadius: 5.5}}
                                                     data-color={index === backgroundType ? null : elem?.color}>
                                                    <button className={`row block`} type={`button`} style={{
                                                        // background: `linear-gradient(${elem.gradient.join(", ")})`,
                                                        width: 36, height: 36, borderRadius: 3.5,
                                                    }} onClick={() => { setBackgroundType(index) }} data-color={elem?.color}></button>
                                                </div>
                                            </div>
                                        )})}
                                    </div>
                                </div>
                            </div>
                            
                            <div className={`block h w-flex mt-1 b row left`} data-color={`light-gray`} hidden={true}>
                                <div className={`h-flex b`} style={RequestsLevel(requestDescription)?.style}></div>
                            </div>
                            {RequestsLevel(requestDescription)?.isText && (!selectBackground || !RequestsLevel(requestDescription)?.isValid) &&
                            <p data-size={6} className={`mt`} hidden={true}
                                data-color={!RequestsLevel(requestDescription)?.isValid ? `coral` : `black-40-opacity`}>
                                {RequestsLevel(requestDescription)?.text}
                            </p>}
                            {(/[а-яА-Я]/).exec(requestDescription) && 
                            <p data-size={6} className={`mt`} data-color={`coral`}>
                                <FormattedMessage id="ob_onlylatin"/>
                            </p>}
                        </div>                                             
                        <div className={`w-flex h-3 neg-mt-3 noshrink`} style={{
                            background: `linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 70%)`,
                            position: `relative`, zIndex: 2
                        }}></div>

                        {props?.canDelete ? 
                        <>
                            <div className={`sticky-bottom- row bottom center w-flex mb-1`}>
                                <button className={`block btn-flex desktop w-flex row center`} data-color={`black`} type={`submit`}
                                        onClick={() => { setInvalidForm(true) }} disabled={!RequestsLevel(requestDescription)?.isValid}>
                                    <p><FormattedMessage id="settings_profile_save"/></p>
                                </button>
                            </div>

                            <button className={`block btn-flex desktop w-flex row center`} data-color={`transparent`} type={`button`} onClick={() => {
                                    setShowConfirmDelete(true);
                                    setShowConfirmBack(false);  
                                }}>
                                <p data-color={`coral`}><FormattedMessage id="request_delete"/></p>
                            </button>
                        </> :
                        <div className={`sticky-bottom- row bottom center w-flex`}>
                            <button className={`block btn-flex desktop w-flex row center`} data-color={`black`} type={`submit`} 
                                    disabled={addButtonDisabled || !RequestsLevel(requestDescription)?.isValid}
                                    onClick={() => { setInvalidForm(true) }}>
                                <p>{props.request ? <FormattedMessage id="settings_profile_save"/> : <FormattedMessage id="create_request"/>}</p>
                            </button>
                        </div>} 

                        <div className={`block p-3 b-3 popup-box-shadow`} style={{
                            position: 'fixed',
                            zIndex: 10,
                            bottom: showConfirmBack || showConfirmDelete ? 30 : -200,
                            right: 30,
                            width: 350,
                            transition: `bottom 0.3s ease-in-out`,
                        }} id={`successfully-copied-label`} hidden={true}>
                            <p data-size={4} className={`w-flex`}>
                                {confirmPopupText}
                            </p>
                            <div className={`row left gap-2 mt-2`}>
                                <button className={`block btn-circled-40 w-6 centered`} data-color={`black`}
                                        onClick={() => {
                                            setShowConfirmBack(false);
                                            setShowConfirmDelete(false);
                                        }} type={`button`}>
                                    <p data-size={5} className={`semibold`}><FormattedMessage id="no"/></p>
                                </button>

                                <button className={`block btn-circled-40 w-6 centered`} data-color={`light-gray`}
                                        onClick={() => {
                                            setShowConfirmBack(false);
                                            setShowConfirmDelete(false);

                                            if (!showConfirmBack) {
                                                setWaitDelete(true);
                                                CardDelete(props.request?.id, () => {
                                                    setClose(true);
                                                    props.onDelete();
                                                    setRequest(props.request?.type ?? null) 
                                                    setWaitDelete(false);                           
                                                }, () => {
                                                    setWaitDelete(false);
                                                });
                                            } else {
                                                setClose(true);
                                                setRequest(props.request?.type ?? null)
                                            }
                                        }} type={`button`}>
                                    <p data-size={5} className={`semibold`}><FormattedMessage id="yes"/></p>
                                </button>
                            </div>
                        </div>
                    </form>}/>
                                                          
                        
        {(showConfirmBack || showConfirmDelete) &&
        <ConfirmPopup title={<FormattedMessage id="confirm_header"/>} subtitle={confirmPopupText} 
                        show={showConfirmBack || showConfirmDelete} setShow={(bool: boolean) => {
                            if (!bool) {
                                setShowConfirmBack(false);
                                setShowConfirmDelete(false);
                            }
                        }} _id={`yesno`}
                        color_3={`light-gray`} 
                        buttonTitle_3={props.request ? undefined : <FormattedMessage id="save_draft"/>} 
                        onClick_3={() => {
                            sessionStorage.setItem(
                                "request_draft",
                                JSON.stringify({
                                    type: request,
                                    answers: [requestDescription, '', '', ''],
                                    tags: tags?.length ? tags : [],
                                    background_type: selectBackground ? backgroundType : undefined,
                                })
                            )
                            setClose(true);
                        }}
                        color_2={'light-gray'} 
                        buttonTitle_2={<FormattedMessage id="cancel"/>} 
                        onClick_2={() => {}} black={true} desktop={true} white={true}
                        color_1={showConfirmBack ? `black` : 'coral'} 
                        buttonTitle_1={showConfirmBack ? <FormattedMessage id="exit"/> : <FormattedMessage id="delete"/>} 
                        onClick_1={() => {
                            setShowConfirmBack(false);
                            setShowConfirmDelete(false);

                            if (!showConfirmBack) {
                                setWaitDelete(true);
                                CardDelete(props.request?.id, () => {
                                    setClose(true);
                                    props.onDelete();
                                    setRequest(props.request?.type ?? null) 
                                    setWaitDelete(false);                           
                                }, () => {
                                    setWaitDelete(false);
                                });
                            } else {
                                setClose(true);
                                setRequest(props.request?.type ?? null)
                            }
                        }} close={'both'}/>}
    </>)
}

const SelectPopup = (props: any) => {
    const [ close, setClose ] = useState(false);

    useEffect(() => {
        setClose(!props.show);
    }, [props.show]);

    return (
        <PopUp content={<>                       
            <div className={`row top noscroll nogap nowrap neg-mx-4 neg-mt-5 pt-5 p-3 pl-4 pr-4`} id={`select-requests-popup-content`}>
                <p data-size={4} className={'semibold mr-3'} data-color={'black'}>{props.title}</p>
                <button className={`btn-symbol mobile w-3 h-3 filled close centered`} onClick={() => {
                    setClose(true);
                }}>
                    <img src={PlusWhite} alt={`+`}></img>
                </button>
            </div>
            <div className={`w-flex select-list mb-3`} onScroll={e => { 
                const header = document.querySelector(`#select-requests-popup-content`)
                if (header) {
                    (header as HTMLDivElement).style.boxShadow = (e.target as HTMLDivElement).scrollTop > 0 ? `0px 0px 13px 0px rgba(0, 0, 0, 0.14), 0px 1px 2px 0px rgba(0, 0, 0, 0.04)` : ''
                }
            }}>
                {(props.select ?? []).map((elem: any, index: number) => {return (                
                    <button className={`block btn-checkbox mobile row top nowrap left mt-2 w-flex`} key={index}
                        data-selected={!(props.selected - index)}
                        onClick={() => {props.setSelected(index)}}>
                        <div className={`checkbox-radio w-3 h-3 b-2 centered noshrink `} data-checked={!(props.selected - index)}>
                            <div className={`checkbox-center h-1 w-1 b`}></div>
                        </div>
                        <p data-size={5} className={`semibold`} data-color={!(props.selected - index) ? 'white' : 'dark-gray'}>{elem.title}</p> 
                    </button>
                )})}
            </div>

            <button className={`block btn-flex mobile row w-flex mt-2`} data-color={`black`}
                    onClick={() => {
                        props.buttonOnClick();
                        setClose(true);
                    }}>
                <p data-size={5} className={`semibold`}>{props?.buttonTitle ?? 'Ok, go next!'}</p>
                <div className={`btn-symbol w-3 h-3 centered`} data-color={`white`}>
                    <img src={AngleRightWhite2} alt={'>'} className={`not-scale w-3 neg-m`}></img>
                </div>
            </button>
        </>} show={props.show} setShow={props.setShow} close={close} setClose={setClose}/>
    )
}

const SelectPopupRight = (props: any) => {
    const [ close, setClose ] = useState<boolean>(false);

    useEffect(() => {
        setClose(!props.show);
    }, [props.show]);

    return (
        <PopUpRight onScroll={(e: any) => { 
            const header = document.querySelector(`#select-requests-popup-content`)
            if (header) {
                (header as HTMLDivElement).style.boxShadow = e.target.scrollTop > 0 ? `0px 0px 13px 0px rgba(0, 0, 0, 0.14), 0px 1px 2px 0px rgba(0, 0, 0, 0.04)` : ''
            }
        }} content={<div className={`px-5`}>                       
            <div className={`block row top noscroll nogap nowrap neg-mx-5 p-5 pb-4 mb-2 w-flex-p-5`} style={Sticky(0, 1) as HTMLAttributes<HTMLDivElement>}
                 id={`select-requests-popup-content`}>
                <p data-size={3} className={'semibold w-flex pr-6'}>{props.title}</p>
                <button className={`block btn-close h-5 w-5 neg-ml-5 b-2 centered`} data-color={`light-gray`} onClick={() => {
                        setClose(true);
                    }} id={`close-popup-btn`}> 
                    <div className={`btn-symbol w-3 h-3 filled close centered hover`}>
                        <img src={PlusWhite} alt={`+`}></img>
                    </div>
                </button>
            </div>
            {(props.select ?? []).map((elem: any, index: number) => {return (                
                <button className={`block btn-checkbox desktop row left mt-2 w-flex`} key={index}
                     data-selected={!(props.selected - index)}
                     onClick={() => {props.setSelected(index)}}>
                    <div className={`checkbox-radio w-3 h-3 b-2 centered`} data-checked={!(props.selected - index)}>
                        <div className={`checkbox-center h-1 w-1 b`}></div>
                    </div>
                    <p data-size={5} className={`semibold`}>{elem.title}</p> 
                </button>
            )})}

            <button className={`block btn-flex desktop row w-flex mb-5 mt-2`} data-color={`black`}
                    onClick={() => {
                        props.buttonOnClick();
                        setClose(true);
                    }}>
                <p data-size={5} className={`semibold`}>{props?.buttonTitle ?? 'Ok, go next!'}</p>
                <div className={`btn-symbol w-3 h-3 centered`} data-color={`white`}>
                    <img src={AngleRightWhite2} alt={'>'} className={`not-scale w-3 neg-m`}></img>
                </div>
            </button>
        </div>} show={props.show} setShow={props.setShow} close={close}/>
    )
}

const SelectSeveralPopup = (props: any) => {
    const [ close, setClose ] = useState(false);
    const [ select, setSelect ] = useState(props.select);

    async function RequestsArray() {
        return select.reduce((init: Array<any>, aim: any) => {
            if (aim.enabled) {
                return init.concat(aim.title);
            } else {return init}
        }, [])
    }

    useEffect(() => {
        setClose(!props.show);
    }, [props.show]);

    useEffect(() => {
        async function effect() {            
            if (props.setSelect) {
                props.setSelect(await RequestsArray());
            }
        }

        effect();
    }, [select])

    return (
        <PopUp content={<>                       
            <div className={`row top noscroll nogap nowrap neg-mx-4 neg-mt-5 pt-5 p-3 pl-4 pr-4`} id={`select-requests-popup-content`}>
                <p data-size={4} className={`semibold`}>{props.title}</p>
                <button className={`btn-symbol mobile w-3 h-3 filled close centered`} onClick={() => {
                    setClose(true);
                }}>
                    <img src={PlusWhite} alt={`+`}></img>
                </button>
            </div>
            <div className={`w-flex select-list mb-3`} onScroll={e => { 
                const header = document.querySelector(`#select-requests-popup-content`)
                if (header) {
                    (header as HTMLDivElement).style.boxShadow = (e.target as HTMLDivElement).scrollTop > 0 ? `0px 0px 13px 0px rgba(0, 0, 0, 0.14), 0px 1px 2px 0px rgba(0, 0, 0, 0.04)` : ''
                }
            }}>
                {(select ?? []).map((elem: any, index: number) => {return (                
                    <button className={`block btn-checkbox mobile b-1 row top left nowrap noscroll ${!!index && `mt-2`} w-flex`} data-selected={elem.enabled}
                            key={index} onClick={() => {
                                setSelect((select: any) => SetDict(select, [index, 'enabled'], !select[index].enabled))
                            }}>
                        <div className={`checkbox-radio h-3 w-3 b centered noshrink`} data-checked={elem.enabled}>
                            <img src={OkWhite} alt={`v`}></img>
                        </div>
                        <p data-size={6} style={{marginTop: 2}}>{elem.title}</p>
                    </button>
                )})}
            </div>

            <button className={`block btn-flex mobile centered w-flex`} data-color={`black`}
                    onClick={() => {
                        props.buttonOnClick();
                        setClose(true);
                    }} disabled={!(select ?? []).filter((val: any) => !!val.enabled)?.length}>
                <p id={props.btnId}>{props.buttonTitle}</p>
            </button>
        </>} show={props.show} setShow={props.setShow} close={close} setClose={setClose}/>
    )
}

const SelectSeveralPopupRight = (props: any) => {
    const [ close, setClose ] = useState<boolean>(false);
    const [ select, setSelect ] = useState(props.select);

    async function RequestsArray() {
        return select.reduce((init: Array<any>, aim: any) => {
            if (aim.enabled) {
                return init.concat(aim.title);
            } else {return init}
        }, [])
    }

    useEffect(() => {
        setClose(!props.show);
    }, [props.show]);

    useEffect(() => {
        async function effect() {            
            if (props.setSelect) {
                props.setSelect(await RequestsArray());
            }
        }

        effect();
    }, [select])

    return (
        <PopUpRight onScroll={(e: any) => { 
            const header = document.querySelector(`#select-requests-popup-content`)
            if (header) {
                (header as HTMLDivElement).style.boxShadow = e.target.scrollTop > 0 ? `0px 0px 13px 0px rgba(0, 0, 0, 0.14), 0px 1px 2px 0px rgba(0, 0, 0, 0.04)` : ''
            }
        }} content={<div className={`px-5`}>                       
            <div className={`block row top noscroll nogap nowrap neg-mx-5 p-5 pb-4 mb-2 w-flex-p-5`} style={Sticky(0, 1) as HTMLAttributes<HTMLDivElement>}
                 id={`select-requests-popup-content`}>
                <p data-size={3} className={'semibold w-flex pr-6'}>{props.title}</p>
                <button className={`block btn-close h-5 w-5 neg-ml-5 b-2 centered`} data-color={`light-gray`} onClick={() => {
                        setClose(true);
                    }} id={`close-popup-btn`}> 
                    <div className={`btn-symbol w-3 h-3 filled close centered hover`}>
                        <img src={PlusWhite} alt={`+`}></img>
                    </div>
                </button>
            </div>
            <div className={`w-flex select-list`}>
                {select.map((elem: any, index: number) => {return (                
                    <button className={`block btn-checkbox desktop b-1 row top left nowrap noscroll mb-2 w-flex`} data-selected={elem.enabled}
                            key={index} onClick={() => {
                                setSelect((select: any) => SetDict(select, [index, 'enabled'], !select[index].enabled))
                            }}>
                        <div className={`checkbox-radio h-3 w-3 b centered noshrink`} data-checked={elem.enabled}>
                            <img src={OkWhite} alt={`v`}></img>
                        </div>
                        <p data-size={5}>{elem.title}</p>
                    </button>
                )})}
            </div>
            
            <div className={`sticky-bottom block bt-1 w-flex pb-5`}>
                <button className={`block btn-flex desktop centered w-flex`} data-color={`black`}
                        onClick={() => {
                            props.buttonOnClick();
                            setClose(true);
                        }} id={props.btnId} disabled={!(select ?? []).filter((val: any) => !!val.enabled)?.length}>
                    <p>{props.buttonTitle}</p>
                </button>
            </div>
        </div>} show={props.show} setShow={props.setShow} close={close}/>
    )
}

const ConfirmPopup = (props: any) => {
    const [ close, setClose ] = useState(false);

    useEffect(() => {
        setClose(!props.show);
    }, [props.show]);

    return (
        <PopUp black={props.black} white={props.white} content={props.desktop ? 
        <div className={`block b-3 column p-4 min-w-9`} style={{width: props.width}}>
            <p data-size={4} className={`semibold seminarrow center mb-2`}>{props.title}</p>
            {!!props.subtitle &&
            <p data-size={5} className={`center`}>{props.subtitle}</p>}
            <div className={`row center nowrap w-flex mt-3`}>
                <button className={`block btn-rectangle-40 desktop centered`} data-color={props.color_1}
                        onClick={() => {
                            props.onClick_1(); 
                            if (props.close === 'both' || props.close === 'first') {
                                setClose(true);
                            }
                        }} type={`button`} style={{minWidth: 153}}>
                    <p>{props.buttonTitle_1}</p>
                </button>

                <button className={`block btn-rectangle-40 desktop centered`} data-color={props.color_2}
                        onClick={() => {
                            props.onClick_2(); 
                            if (props.close === 'both' || props.close === 'second') {
                                setClose(true);
                            }
                        }} type={`button`} style={{minWidth: 153}}>
                    <p>{props.buttonTitle_2}</p>
                </button>
                
                {props.color_3 && props.onClick_3 && props.buttonTitle_3 &&
                <button className={`block btn-rectangle-40 desktop centered`} data-color={props.color_3}
                        onClick={() => {
                            props.onClick_3(); 
                            if (props.close === 'both' || props.close === 'second') {
                                setClose(true);
                            }
                        }} type={`button`} style={{minWidth: 153}}>
                    <p>{props.buttonTitle_3}</p>
                </button>}
            </div>
        </div> : <>                       
            {props.subtitle ? <>
                <p data-size={5} className={'semibold mb-1'}>{props.title}</p>
                <p data-size={6} className={'mb-3'}>{props.subtitle}</p>
            </> :
            <p data-size={4} className={'center mb-3'} data-color={`black`}>{props.title}</p>}    

            <div className={`row nowrap center`}>
                <button className={`w-flex block btn-rectangle-40 ${!props.desktop ? 'mobile' : 'desktop'}`} data-color={props.color_1}
                        onClick={() => {
                            props.onClick_1(); 
                            if (props.close === 'both' || props.close === 'first') {
                                setClose(true);
                            }
                        }}>
                    <p className={`center`}>{props.buttonTitle_1}</p>
                </button>

                <button className={`w-flex block btn-rectangle-40 ${!props.desktop ? 'mobile' : 'desktop'}`} data-color={props.color_2}
                        onClick={() => {
                            props.onClick_2(); 
                            if (props.close === 'both' || props.close === 'second') {
                                setClose(true);
                            }
                        }}>
                    <p className={`center`}>{props.buttonTitle_2}</p>
                </button>
                
                {/* {props.color_3 && props.onClick_3 && props.buttonTitle_3 &&
                <button className={`w-flex block btn-rectangle-40 ${!props.desktop ? 'mobile' : 'desktop'}`} data-color={props.color_3}
                        onClick={() => {
                            props.onClick_3(); 
                            if (props.close === 'both' || props.close === 'second') {
                                setClose(true);
                            }
                        }}>
                    <p className={`center`}>{props.buttonTitle_3}</p>
                </button>} */}
            </div>
        </>} show={props.show} setShow={props.setShow} close={close} setClose={setClose} 
        onlyBg={props.desktop} customZIndex={202}/>
    )
}

const InfoPopup = (props: any) => {
    const [ close, setClose ] = useState(false);

    useEffect(() => {
        setClose(!props.show);
    }, [props.show]);

    useEffect(() => {
        if (props.noButton) {
            setTimeout(() => {
                setClose(true);
                setTimeout(() => {
                    props.onOk()
                }, 500);
            }, 2000)
        }
    }, [])

    return (
        <PopUp black={true} content={<>                       
            <div className={`row left top noscroll nogap nowrap noshrink`}>
                <div className={`btn-symbol filled w-3 h-3 centered neg-mr-3`} data-color={`green`}><img src={OkWhite} alt={`ok`}></img></div>
                <p data-size={5} className={'semibold pl-4'} data-color={`white`}>{props.title}</p>
            </div>
            
            {!props.noButton && 
            <button className={`block btn-circled-${!props.desktop ? '32 mobile' : '40 desktop'} row ml-4 mt-2`} data-color={`green`}
                    onClick={e => {
                        e.stopPropagation();
                        setClose(true);
                        setTimeout(() => {
                            props.onOk()
                        }, 500);                       
                    }}>
                <p>{props.buttonTitle}</p>
            </button>}
        </>} show={props.show} setShow={props.setShow} close={close} setClose={setClose} hideBg={props.hideBg}/>
    )
}

const NewEventsPopup = (props: any) => {
    const navigate = useNavigate();
    const context = useContext(ProfileContext);

    const [ close, setClose ] = useState(false);
    const [ events, setEvents ] = useState<any[]>();

    useEffect(() => {
        setEvents(props.events);
    }, [props.events])

    useEffect(() => {
        setClose(!props.show);
    }, [props.show]);

    useEffect(() => {
        if (events && (events ?? []).filter((val: any) => (
            !val.hidden &&
            !IsReadNotification(val)
        )).length === 0) {
            setClose(true);
        }
    }, [events])

    const HideNotification = (
        notification: any,
        index: number,
    ) => {
        const thisPopup = document.querySelector(`#new-pair-popup-${index}`) as HTMLDivElement;
        if (thisPopup) {
            thisPopup.style.opacity = `0`;
            setTimeout(() => {
                thisPopup.style.marginBottom = `-${thisPopup.offsetHeight + 14}px`;
                setTimeout(() => {
                    ReadNotification(notification);
                    setEvents(val => val ? SetDict(val, [index, 'hidden'], true) : val)
                    
                    const notificationIndex = (context.notifications ?? []).findIndex((val: any) => (
                        val?.type === notification?.type &&
                        val?.from_user === notification?.from_user &&
                        val?.target_id === notification?.target_id
                    ))
                    context.setNotifications((val: any[]) => val ? SetDict(val, [notificationIndex, `is_read`], true) : val);
                }, 300);
            }, 300);
        }
    }

    return (
        <PopUp black={true} transparent={true} content={
        <div>
        {(events ?? []).map((elem: any, index: number) => { return (
            <div className={`block w-flex p-3 b-3 popup-box-shadow mt-2`} key={index} id={`new-pair-popup-${index}`}
                 style={{transition: `opacity 0.3s ease-in-out, margin-bottom 0.3s ease-in-out`}}
                 hidden={IsReadNotification(elem) || elem?.hidden}>   
                <div className={`row right w-flex neg-mb-3`}>
                    <button className={`btn-symbol btn w-3 h-3 filled close centered`} onClick={() => {
                        // setClose(true);
                        HideNotification(elem, index);
                    }}>
                        <img src={PlusWhite} alt={`+`}></img>
                    </button>
                </div>   
                <p data-size={5} className={`semibold seminarrow mb-2`}>
                    {elem?.type === 1 ? `New match` :
                     elem?.type === 6 ? `${elem?.name} suggested you for` :
                     elem?.type === 7 ? `${elem?.name} suggested` : `New Event`}
                </p>  
                {(elem?.type === 1 || elem?.type === 7)? <>
                <div className={`row w-flex left top nowrap mb-2`}>           
                    <NewAvatar name={elem?.target?.name} type={elem?.target?.avatar?.style}
                               avatar={elem?.target?.avatar?.link} size={7}/>
                    <div>
                        <p data-size={6} className={`semibold seminarrow`}>{elem?.target?.name}</p>
                        <p data-size={7} data-color={`black-40-opacity`} data-lines={1} className={`mb`}>
                            {elem?.target?.occupation}
                        </p>
                        <p data-size={6} data-lines={4}>
                            {elem?.target?.about}
                        </p>
                    </div>
                </div>
                                
                <div className={`row left nowrap`}>
                    <button className={`block btn-rectangle-32 desktop`} data-color={`black`}
                            onClick={e => {
                                e.stopPropagation();
                                HideNotification(elem, index);
                                navigate(`/profile/chat?chat_id=${elem?.target_id}`);                       
                            }} hidden={elem?.type === 7}>
                        <p><FormattedMessage id="go_to_chat"/></p>
                    </button>
                    <button className={`block btn-rectangle-32 desktop`} data-color={elem?.type === 7 ? `black` : `light-gray`}
                            onClick={e => {
                                e.stopPropagation();
                                HideNotification(elem, index);
                                navigate(`/profile/${elem?.type === 7 ? elem?.target_id : elem.from_user}`);                       
                            }}>
                        <p><FormattedMessage id="full_profile"/></p>
                    </button>
                </div>                
                </> : <>
                <div className={`block w-flex b-2 p-2 mb-2`} data-border={`gray-1`}>
                    <div className={`row w-flex left top nowrap mb-1`}>           
                        <NewAvatar name={elem?.target?.name} type={elem?.target?.avatar?.style}
                                avatar={elem?.target?.avatar?.link} size={3}/>
                        <div>
                            <p data-size={6} className={`semibold seminarrow`}>{elem?.target?.name}</p>
                            <p data-size={7} data-color={`black-40-opacity`} data-lines={1} className={`mb`}>
                                {elem?.target?.occupation}
                            </p>
                        </div>
                    </div>
                    <p data-size={6} data-lines={4}>
                        {elem?.text}
                    </p>
                </div>
                                
                <button className={`block btn-rectangle-32 desktop`} data-color={`black`}
                        onClick={e => {
                            e.stopPropagation();
                            HideNotification(elem, index);
                            navigate(`/request/${elem?.target_id}`);                       
                        }}>
                    <p><FormattedMessage id="go_to_request"/></p>
                </button>               
                </>}
            </div>
        )})}
        </div>} show={props.show} setShow={props.setShow} close={close} hideBg={true}/>
    )
}

const FirstOfferPopup = (props: any) => {
    const [ close, setClose ] = useState(false);

    useEffect(() => {
        setClose(!props.show);
    }, [props.show]);

    return (
        <PopUp black={true} transparent={true} content={
        <div className={`block p-3 b-3`} data-border={`bright-green-10`}>
            <p data-size={5} className={`semibold seminarrow mb-1`}><FormattedMessage id="first_connection"/></p>
            <p data-size={8} className={`mb-3`}>
                <FormattedMessage id="congrats_begin"/><br/>
                <FormattedMessage id="we_will_notify_about_answer"/>
            </p>
            <button className={`block btn-circled-40 row center`} style={{width: 123}}
                    onClick={() => { setClose(true) }} data-color={`bright-green`}>
                <p>{`Thank's`}</p>
            </button>
        </div>} show={props.show} setShow={props.setShow} close={close} hideBg={true}/>
    )
}

const FirstOfferPopupMobile = (props: any) => {
    const [ close, setClose ] = useState(false);

    useEffect(() => {
        setClose(!props.show);
    }, [props.show]);

    return (
        <PopUp transparent={true} content={
        <div className={`block neg-mx-3 neg-my-4`} data-border={`bright-green-8`} 
             style={{borderRadius: '28px 28px 0 0', padding: `30px 16px 30px 16px`}}>
            <p data-size={5} className={`semibold seminarrow mb-2`}><FormattedMessage id="first_connection"/></p>
            <p data-size={8} className={`mb-2`}>
                <FormattedMessage id="congrats_begin"/><br/>
                <FormattedMessage id="we_will_notify_about_answer"/>
            </p>
            <button className={`block btn-circled-40 row center`} style={{width: 123}}
                    onClick={() => { setClose(true) }} data-color={`bright-green`}>
                <p>{`Thank's`}</p>
            </button>
        </div>} show={props.show} setShow={props.setShow} close={close} mobile={true}/>
    )
}

const NewMatchPopupMobile = (props: any) => {
    const [ close, setClose ] = useState(false);

    useEffect(() => {
        setClose(!props.show);
    }, [props.show]);

    return (
        <PopUp content={<>                       
            <div className={`column top mb-3`}>                
                <NewAvatar name={props.pair?.name} type={props.pair?.avatar?.style}
                            avatar={props.pair?.avatar?.link} size={5} className={`mb-3`}/>
                <p data-size={4} className={`center`}>{`${props.pair?.name} is your new match!`}</p>
            </div>
            
            <div className={`row center nowrap`}>
                <button className={`block btn-circled-32 mobile`} data-color={`black`}
                        onClick={e => {
                            e.stopPropagation();
                            if (typeof props.toChat === 'function') {
                                props.toChat();
                            }                       
                        }}>
                    <p><FormattedMessage id="go_to_chat"/></p>
                </button>
                <button className={`block btn-circled-32 mobile`} data-color={`light-gray`}
                        onClick={e => {
                            e.stopPropagation();
                            if (typeof props.toProfile === 'function') {
                                props.toProfile();
                            }                       
                        }}>
                    <p><FormattedMessage id="full_profile"/></p>
                </button>
            </div>
        </>} show={props.show} setShow={props.setShow} close={close} setClose={setClose} hideBg={false}/>
    )
}

const PopupCenter = (props: any) => {
    const [ close, setClose ] = useState(false);

    useEffect(() => {
        if (close && typeof props.onClose === `function`) {
            props.onClose();
        }
    }, [close])

    useEffect(() => {
        setClose(!props.show);
    }, [props.show]);

    return (
        <PopUp onlyBg={true} content={<>
            <div className={`block p-3 b-3 w-${props.desktop || !props.mobile ? 9 : `flex`} center-popup`} data-color={`black`} onClick={e => { e.stopPropagation() }}>
                <div className={`row right w-flex mb-${props.desktop || !props.mobile ? 3 : 2}`}>
                    <button className={`btn btn-symbol filled close w-3 h-3 centered`} data-color={`white`} type={`button`} onClick={() => {
                        setClose(true);
                    }}>
                        <img src={PlusBlack} alt={`+`}></img>
                    </button>
                </div>
                <div className={`mb-${props.desktop || !props.mobile ? 3 : 4} column top`}>
                    <p data-size={props.desktop || !props.mobile ? 4 : 5} className={`semibold seminarrow`} data-color={`white`}>
                        <FormattedMessage id="email_sent"/>
                    </p>
                    <p data-size={props.desktop || !props.mobile ? 4 : 5} className={`semibold seminarrow mb-${props.desktop || !props.mobile ? 3 : 2}`} data-color={`white`}><FormattedMessage id="check_inbox"/></p>
                    <button className={`block btn-circled-${props.desktop || !props.mobile ? `40 desktop mb-3` : `32 mobile`}`} data-color={`green`}
                            onClick={() => {
                                if (props.mailbox) {
                                    window.location.href = props.mailbox;
                                } else {
                                    setClose(true);
                                }
                            }}>
                        <p>{props.mailbox ? <FormattedMessage id="reg_open_mailbox"/> : `Ok, I got it`}</p>
                    </button>
                </div>
            </div>
        </>} show={props.show} setShow={props.setShow} close={close} setClose={setClose}/>
    )
}

const PopupNotifications = (props: any) => {
    const context = useContext(ProfileContext);

    const [ close, setClose ] = useState(false);

    useEffect(() => {
        if (close && typeof props.onClose === `function`) {
            props.onClose();
        }
    }, [close])

    useEffect(() => {
        setClose(!props.show);
    }, [props.show]);

    useEffect(() => {   
        NotificationsRead(`all`, () => {
            (context.notifications ?? []).forEach((elem: any, index: number) => {
                context.setNotifications((val: any) => SetDict(val, [index, `isRead`], true));
            })
        })
    }, [])

    return (
        <PopUp onlyBg={true} content={<div className={`w-flex h-flex row right top center-popup`}>
            <div className={`block pb-3 px-4 pt-2 b-3 w-10 neg-mr md-neg-mr-4 neg-mt-2`} onClick={e => { e.stopPropagation() }}>
                <div className={`row py-1 nowrap w-flex`}>
                    <p data-size={4} className={`semibold seminarrow`}><FormattedMessage id="notifications"/></p>
                    <button className={`btn btn-symbol filled close w-3 h-3 centered`} data-color={`black`} type={`button`} onClick={() => {
                        setClose(true);
                    }}>
                        <img src={PlusWhite} alt={`+`}></img>
                    </button>
                </div>
                {(context.notifications ?? []).slice(0, 7).map((elem: any, index: number) => {return (
                    <div className={`w-flex row left top nowrap mt-2`} key={index}>
                        <div className={`h-5 w-3 centered noshrink neg-ml-3 neg-mr-1`} hidden={elem.is_read}>
                            <div className={`block w-1 h-1 b`} data-color={`coral`}></div>
                        </div> 
                        <NewAvatar size={5} name={`${elem.fname} ${elem.lname}`} type={elem.avatar?.style}
                                   avatar={elem.avatar?.link}/>
                        <div>                            
                            <p data-size={6} data-lines={3} className={`mb-1`}>
                                <span className={`semibold`}>
                                    {elem?.type < 8 ? `${elem?.fname} ${elem.lname} ` : 
                                    elem?.type === 8 ? "" : "New interesting request "}
                                </span>&nbsp;
                                {elem.type === 0 ? `is ready to help you: ${elem.text}` :
                                 elem.type === 1 ? `is your new match!` :
                                 elem.type === 2 ? `sent you a new message: ${elem.text}` :
                                 elem.type === 3 ? `started following you!` :
                                 elem.type === 4 ? `posted a new request: ${elem.text}` : 
                                 elem.type === 5 ? `started following ${elem.text} new people. View ${elem.fname}'s following to find new connections.` :
                                 elem.type === 6 ? <>
                                     {`suggested you for `}
                                     <span className={`semibold`}>{`${elem.target?.name}'s `}</span>
                                     {`request: ${elem.text}`}
                                 </> : 
                                 elem.type === 7 ? <>
                                     {`suggested `}
                                     <span className={`semibold`}>{elem.target?.name}</span>
                                     {` for your request: ${elem.text}`}
                                 </> : 
                                 elem.type === 8 ? <>
                                     {`Seems like `} 
                                     <span className={`semibold`}>{`${elem?.fname} ${elem.lname}`}</span>
                                     {` and 5 more people `}
                                     <span className={`semibold`}>{`can help`}</span>
                                     {` you with your request`}
                                 </> : <>
                                     {`for you from `}
                                     <span className={`semibold`}>{`${elem?.fname} ${elem.lname}`}</span>
                                 </>}
                            </p>
                            <a className={`block btn-rectangle-24 desktop row`} 
                               data-color={(elem.type === 1 || elem.type === 2) ? `black` : `light-gray`} 
                               href={elem.type === 0 ? `/profile/chat?offer_id=0` :
                                     elem.type === 1 ? `/profile/chat?chat_id=${elem.target_id}` :
                                     elem.type === 2 ? (elem?.chat_offer ? `profile/chat?offer_id=0` : `/messenger/${elem.target_id}`) :
                                     elem.type === 3 ? `/profile/${elem.target_id}` :
                                     elem.type === 4 ? `/request/${elem.target_id}` :
                                     elem.type === 5 ? `/profile/${elem.target_id}?target=following` : 
                                     elem.type === 6 ? `/request/${elem.target_id}` : 
                                     elem.type === 7 ? `/profile/${elem.target_id}` : 
                                     elem.type === 8 ? `/profile/${elem?.target_id}` : `/request/${elem?.target_id}`} 
                               style={{width: `max-content`}}>
                                <p>{elem.type === 0 ? <FormattedMessage id="go_to_chat"/> :
                                    elem.type === 1 ? <FormattedMessage id="go_to_chat"/> :
                                    elem.type === 2 ? <FormattedMessage id="go_to_chat"/> :
                                    elem.type === 3 ? <FormattedMessage id="go_to_profile"/> :
                                    elem.type === 4 ? <FormattedMessage id="go_to_request"/> :
                                    elem.type === 5 ? <FormattedMessage id="expand_network"/> :
                                    elem.type === 6 ? <FormattedMessage id="go_to_request"/> :
                                    elem.type === 7 ? <FormattedMessage id="go_to_profile"/> : 
                                    elem.type === 8 ? `Check recommendation` : <FormattedMessage id="check_request"/>}</p>
                            </a>
                        </div>
                    </div>
                )})}
                <a href={`/profile/notifications`} 
                   className={`btn desktop w-flex row center my-2`}>
                    <p className={`semibold seminarrow`} data-size={5}><FormattedMessage id="notifications_all"/></p>
                </a>
            </div>
        </div>} show={props.show} setShow={props.setShow} close={close}/>
    )
}

const PopupCenterCanHelp = (props: any) => {
    const [ cookie ] = useCookies([`user_id_cookie`]);
    const [ close, setClose ] = useState(false);
    const context = useContext(ProfileContext);

    const [ waitResponse, setWaitResponse ] = useState<boolean>(false);

    const [ message, setMessage ] = useState<string>(
        props.request?.id ? `Hi! I can help you with...` : 
        ``
    );

    useEffect(() => {
        setClose(!props.show);
    }, [props.show]);

    return (
        <PopUp onlyBg={true} customZIndex={202} content={<>
            <form className={`block p-4 b-3 w-10 center-popup`} data-color={`white`} onClick={e => { e.stopPropagation() }}
                  onSubmit={e => {
                        e.preventDefault();
                        setWaitResponse(true);
                        NewOffer({
                            user_id: cookie[`user_id_cookie`],
                            pair_id: props.request?.user_id,
                            card_id: props.request?.id ?? null,
                            text: message,
                        }, () => {
                            setClose(true);
                            setWaitResponse(false);
                            if (typeof props.onRequested === 'function') {
                                props.onRequested(props.request?.id);
                            }
                            if (typeof props.request?.onSent === 'function') {
                                props.request?.onSent();
                            }
                        }, () => { setWaitResponse(false) })
                        .then(res => {
                            if (res.status === 200) {
                                res.json().then(data => {
                                    if (data?.first_offer) {
                                        context.setEnableCongratsPopup(true);
                                        context.setEnableCongrats(true);
                                        setTimeout(() => {
                                            context.setEnableCongrats(false);
                                        }, 3000)
                                    }
                                })
                            }
                        })
                  }}>
                <div className={`row w-flex mb-3`}>
                    <p data-size={4} className={`semibold seminarrow`}><FormattedMessage id="write_message"/></p>
                    <button className={`btn btn-symbol filled close w-3 h-3 centered`} data-color={`black`} type={`button`} onClick={() => {
                        setClose(true);
                    }}>
                        <img src={PlusWhite} alt={`+`}></img>
                    </button>
                </div>

                <textarea className={`mb-2 w-flex b-1 p-2`} placeholder={`Type a message`} name={`message`}
                          value={message} onChange={e => { setMessage(e.target.value) }} style={{height: 158}}></textarea>

                <button className={`block btn-circled-40 desktop row center`} data-color={`black`} 
                        type={`submit`} disabled={!message || waitResponse}>
                    <p><FormattedMessage id="send"/></p>
                    {waitResponse && 
                    <Loading color={`white`} width={24} height={24}/>}
                </button>
            </form>
        </>} show={props.show} setShow={props.setShow} close={close}/>
    )
}

const PopupCanHelpMobile = (props: any) => {
    const [ cookie ] = useCookies([`user_id_cookie`]);
    const [ close, setClose ] = useState(false);
    const context = useContext(ProfileContext);

    const [ waitResponse, setWaitResponse ] = useState<boolean>(false);

    const [ message, setMessage ] = useState<string>(
        props.request?.id ? `Hi! I can help you with...` : 
        ``
    );

    useEffect(() => {
        setClose(!props.show);
    }, [props.show]);

    return (
        <PopUp content={<>
            <form className={`w-flex`} data-color={`white`} onClick={e => { e.stopPropagation() }}
                  onSubmit={e => {
                    e.preventDefault();
                    setWaitResponse(true);
                    NewOffer({
                        user_id: cookie[`user_id_cookie`],
                        pair_id: props.request?.user_id,
                        card_id: props.request?.id ?? null,
                        text: message,
                    }, () => {
                        setClose(true);
                        setWaitResponse(false);
                        if (typeof props.onRequested === 'function') {
                            props.onRequested(props.request?.id);
                        }                        
                        if (typeof props.request?.onSent === 'function') {
                            props.request?.onSent();
                        }
                    }, () => { setWaitResponse(false) })
                    .then(res => {
                        if (res.status === 200) {
                            res.json().then(data => {
                                if (data?.first_offer) {
                                    context.setEnableCongrats(true);
                                    context.setEnableCongratsPopup(true);
                                    setTimeout(() => {
                                        context.setEnableCongrats(false);
                                    }, 3000)
                                }
                            })
                        }
                    })
                  }}>
                <p data-size={5} className={`semibold seminarrow mb-2`}><FormattedMessage id="write_message"/></p>

                <textarea className={`mb-2 w-flex h-6 b-1 p-2`} placeholder={`Type a message`} name={`message`}
                          value={message} onChange={e => { setMessage(e.target.value) }} data-size={6}></textarea>

                <button className={`block btn-circled-32 mobile row center w-7`} data-color={`black`} 
                        type={`submit`} disabled={!message || waitResponse}>
                    <p><FormattedMessage id="send"/></p>
                    {waitResponse && 
                    <Loading color={`white`} width={24} height={24}/>}
                </button>
            </form>
        </>} show={props.show} setShow={props.setShow} close={close} setClose={setClose}/>
    )
}

const PopupCenterCanHelp2 = (props: any) => {
    const [ cookie ] = useCookies([`user_id_cookie`]);
    const [ close, setClose ] = useState(false);
    const context = useContext(ProfileContext);

    const [ waitResponse, setWaitResponse ] = useState<boolean>(false);

    const [ message, setMessage ] = useState<string>(`I hope you're doing well! I'm looking for … and thought you'd be the perfect person to help out!`);

    useEffect(() => {
        setClose(!props.show);
    }, [props.show]);

    return (
        <PopUp onlyBg={true} content={<>
            <form className={`block p-4 b-3 w-10 center-popup`} data-color={`white`} onClick={e => { e.stopPropagation() }}
                  onSubmit={e => {
                        e.preventDefault();
                        setWaitResponse(true);
                        NewOffer({
                            user_id: cookie[`user_id_cookie`],
                            pair_id: props.request?.user_id,
                            card_id: props.request?.id,
                            text: message,
                        }, () => {
                            setClose(true);
                            setWaitResponse(false);
                            if (typeof props.onRequested === 'function') {
                                props.onRequested(props.request?.id);
                            }                            
                            if (typeof props.request?.onSent === 'function') {
                                props.request?.onSent();
                            }
                        }, () => { setWaitResponse(false) })
                        .then(res => {
                            if (res.status === 200) {
                                res.json().then(data => {
                                    if (data?.first_offer) {
                                        context.setEnableCongrats(true);
                                        context.setEnableCongratsPopup(true);
                                        setTimeout(() => {
                                            context.setEnableCongrats(false);
                                        }, 3000)
                                    }
                                })
                            }
                        })
                  }}>
                <div className={`row w-flex mb-3`}>
                    <p data-size={4} className={`semibold seminarrow`}><FormattedMessage id="write_message"/></p>
                    <button className={`btn btn-symbol filled close w-3 h-3 centered`} data-color={`black`} type={`button`} onClick={() => {
                        setClose(true);
                    }}>
                        <img src={PlusWhite} alt={`+`}></img>
                    </button>
                </div>

                <div className={`block w-flex p-3 mb-1 b-2`} data-border={`gray-1`}>
                    <div className={`row left mb-2 nowrap`}>
                        <NewAvatar size={3} name={props.request?.name} avatar={props.request?.avatar?.link} 
                                   type={props.request?.avatar?.style}/>
                        <div>
                            <p data-size={6} className={`semibold seminarrow`}>{props.request?.name}</p>
                            <p data-size={7} data-color={`black-40-opacity`}>{props.request?.occupation}</p>
                        </div>
                    </div>
                    <p data-lines={3} data-size={6}>
                        {(props.request?.answers[0] ?? '').trim().split(`\n`).map((elem: string, index: number) => {return (<Fragment key={index}>
                            {elem}<br/>
                        </Fragment>)})}
                    </p>
                </div>

                <textarea className={`mb-2 w-flex b-1 p-2`} placeholder={`Type a message`} name={`message`}
                          value={message} onChange={e => { setMessage(e.target.value) }} style={{height: 158}}></textarea>

                <button className={`block btn-circled-40 desktop row center`} data-color={`black`} 
                        type={`submit`} disabled={!message || waitResponse}>
                    <p><FormattedMessage id="send"/></p>
                    {waitResponse && 
                    <Loading color={`white`} width={24} height={24}/>}
                </button>
            </form>
        </>} show={props.show} setShow={props.setShow} close={close} setClose={setClose}/>
    )
}

const PopupCanHelpMobile2 = (props: any) => {
    const [ cookie ] = useCookies([`user_id_cookie`]);
    const [ close, setClose ] = useState(false);
    const context = useContext(ProfileContext);

    const [ waitResponse, setWaitResponse ] = useState<boolean>(false);

    const [ message, setMessage ] = useState<string>(`I hope you're doing well! I'm looking for … and thought you'd be the perfect person to help out!`);

    useEffect(() => {
        setClose(!props.show);
    }, [props.show]);

    return (
        <PopUp content={<>
            <form className={`w-flex`} data-color={`white`} onClick={e => { e.stopPropagation() }}
                  onSubmit={e => {
                    e.preventDefault();
                    setWaitResponse(true);
                    NewOffer({
                        user_id: cookie[`user_id_cookie`],
                        pair_id: props.request?.user_id,
                        card_id: props.request?.id,
                        text: message,
                    }, () => {
                        setClose(true);
                        setWaitResponse(false);
                        if (typeof props.onRequested === 'function') {
                            props.onRequested(props.request?.id);
                        }
                        if (typeof props.request?.onSent === 'function') {
                            props.request?.onSent();
                        }
                    }, () => {setWaitResponse(false) })
                    .then(res => {
                        if (res.status === 200) {
                            res.json().then(data => {
                                if (data?.first_offer) {
                                    context.setEnableCongrats(true);
                                    context.setEnableCongratsPopup(true);
                                    setTimeout(() => {
                                        context.setEnableCongrats(false);
                                    }, 3000)
                                }
                            })
                        }
                    })
                  }}>
                <p data-size={5} className={`semibold seminarrow mb-2`}><FormattedMessage id="write_message"/></p>                

                <div className={`block w-flex p-2 pb-2 mb-1 b-2`} data-border={`gray-1`}>
                    <div className={`row left mb-2 nowrap`}>
                        <NewAvatar size={3} name={props.request?.name} avatar={props.request?.avatar?.link} 
                                   type={props.request?.avatar?.style}/>
                        <div>
                            <p data-size={6} className={`semibold seminarrow`}>{props.request?.name}</p>
                            <p data-size={7} data-color={`black-40-opacity`}>{props.request?.occupation}</p>
                        </div>
                    </div>
                    <p data-lines={3} data-size={6}>
                        {(props.request?.answers[0] ?? '').trim().split(`\n`).map((elem: string, index: number) => {return (<Fragment key={index}>
                            {elem}<br/>
                        </Fragment>)})}
                    </p>
                </div>

                <textarea className={`mb-2 w-flex h-6 b-1 p-2`} placeholder={`Type a message`} name={`message`}
                          value={message} onChange={e => { setMessage(e.target.value) }} data-size={6}></textarea>

                <button className={`block btn-circled-32 mobile row center w-7`} data-color={`black`} 
                        type={`submit`} disabled={!message || waitResponse}>
                    <p><FormattedMessage id="send"/></p>
                    {waitResponse && 
                    <Loading color={`white`} width={24} height={24}/>}
                </button>
            </form>
        </>} show={props.show} setShow={props.setShow} close={close} setClose={setClose}/>
    )
}

const PopupCenterCanHelpLanding = (props: any) => {
    const [ close, setClose ] = useState(false);
    // const navigate = useNavigate();

    const [ message, setMessage ] = useState<string>(`I hope you're doing well! I'm looking for … and thought you'd be the perfect person to help out!`);
    
    const fontSize = (text: string) => {
        if (text?.length > 145) {
            return 14;
        } 
        if (text?.length > 75) {
            return 16;
        } 
        if (text?.length > 55) {
            return 18;
        } 
        return 20;
    }
    
    useEffect(() => {
        setClose(!props.show);
    }, [props.show]);

    return (
        <PopUp onlyBg={true} content={<>
            <div className={`block p-4 b-3 w-10 center-popup`} data-color={`white`} onClick={e => { e.stopPropagation() }}>
                <div className={`row w-flex mb-3`}>
                    <p data-size={4} className={`semibold seminarrow`}><FormattedMessage id="write_message"/></p>
                    <button className={`btn btn-symbol filled close w-3 h-3 centered`} data-color={`black`} type={`button`} onClick={() => {
                        setClose(true);
                    }}>
                        <img src={PlusWhite} alt={`+`}></img>
                    </button>
                </div>

                <div className={`block w-flex p-3 mb-1 b-2`} data-border={`gray-1`} hidden={true}>
                    <div className={`row left mb-2 nowrap`}>
                        <NewAvatar size={3} name={props.request?.name} avatar={props.request?.avatar?.link} 
                                   type={props.request?.avatar?.style}/>
                        <div>
                            <p data-size={6} className={`semibold seminarrow`}>{props.request?.name}</p>
                            <p data-size={7} data-color={`black-40-opacity`}>{props.request?.occupation}</p>
                        </div>
                    </div>
                    <p data-lines={3} data-size={6}>
                        {(props.request?.answers[0] ?? '').trim().split(`\n`).map((elem: string, index: number) => {return (<Fragment key={index}>
                            {elem}<br/>
                        </Fragment>)})}
                    </p>
                </div>

                <div className={`block w-flex b-2 pt-2 mb-1`} data-border={`light-gray-2`} 
                    data-color={typeof props?.request?.style?.background === 'number' ? RequestColors2[props?.request?.style?.background]?.color : `transparent`} 
                    style={{overflow: `hidden`}}>
                    <div className={`w-flex row left gap-0 px-2`} hidden={!props.request?.user?.industry?.length} style={{overflow: `hidden`, maxHeight: 39}}>
                        {(props?.request?.user?.industry ?? []).map((elem: string, index: number) => {return (
                            <button className={`block btn-circled-24 desktop mb-2`} key={index} data-border={typeof props?.request?.style?.background !== 'number' ? true : null}
                                    onClick={e => {
                                        e.stopPropagation();
                                        if (typeof props.setFilterByIndustry === `function`) {
                                            props.setFilterByIndustry((val:string[]) => val.includes(elem) ? val : val.concat([elem]));
                                        }
                                    }} style={typeof props?.request?.style?.background !== 'number' ? {border: `solid 1px ${CreateHSLColor(elem)}`} : {}}>
                                <p style={{color: CreateHSLColor(elem)}} className={`not-active`}>{elem}</p>
                            </button>
                        )})}
                    </div>

                    <div className={`w-flex px-2 column left bottom clickable`} data-lines={typeof props?.request?.style?.background === 'number' ? null : 3}
                        id={`feed-card-about-${props?.request?.id}`}
                        style={typeof props?.request?.style?.background === 'number' ? {
                            paddingBottom: 14, fontSize: fontSize(props?.request?.answers[0]),
                            minHeight: 50,
                        } : {fontSize: 14, minHeight: 28}}>
                        {props?.request?.answers.reduce((init: string, elem: string) => {
                            return `${init}${init && '\n'}${elem.trim()}`;
                        }, '').trim().split(`\n`).map((elem: string, index: string) => {return ( elem !== '' ?
                        <p className={`article ${typeof props?.request?.style?.background === 'number' ? `semibold seminarrow` : ``}`} 
                        data-color={typeof props?.request?.style?.background === 'number' ? RequestColors2[props?.request?.style?.background]?.textColor : 'black'} key={index}>
                            {elem}
                        </p> : null)})}
                    </div>
                    
                    <div className={`block row w-flex nowrap nogap p-2`}
                        data-color={typeof props?.request?.style?.background === 'number' ? null : `transparent`}>
                        <div className={`row left nowrap clickable`} onClick={e => {
                            e.stopPropagation();
                            if (typeof props.onAvatarClick === `function`) {
                                props.onAvatarClick();
                            } else {
                                const newWindow: WindowProxy | null = window.open(`/profile/${props?.request?.user?.id}`, '_blank');
                                if (newWindow) {
                                    newWindow.focus();
                                }
                            }
                        }}>
                            <NewAvatar name={props?.request?.user?.name} type={props?.request?.user?.avatar?.style ?? 0} size={1}
                                    avatar={props?.request?.user?.avatar?.link}/>

                            <div className={`neg-my`}>
                                <p data-size={7} className={`semibold seminarrow`} data-color={'black'}>
                                    {props?.request?.user?.name ?? ``}
                                </p>
                                <p data-size={7} className={`seminarrow`} data-color={`black-40-opacity`} data-lines={1}>
                                    {props?.request?.user?.occupation ?? ``}
                                </p>
                            </div>
                        </div>
                    </div>
                </div> 

                <textarea className={`mb-2 w-flex b-1 p-2`} placeholder={`Type a message`} name={`message`}
                          value={message} onChange={e => { setMessage(e.target.value) }} style={{height: 158}}></textarea>

                <button className={`block btn-circled-40 desktop row center`} data-color={`black`} 
                   disabled={!message} onClick={() => {
                        setClose(true);
                        window.location.href = `https://my.osmos.social/?force_logout=true&can_help=${encodeURIComponent(message)}&request_id=${encodeURIComponent(props.request?.id)}&redirect=${encodeURIComponent(`/profile/requested`)}`
                   }}>
                    <p><FormattedMessage id="sign_in_send"/></p>
                </button>
            </div>
        </>} show={props.show} setShow={props.setShow} close={close} setClose={setClose}/>
    )
}

const PopupCanHelpMobileLanding = (props: any) => {
    const [ close, setClose ] = useState(false);
    // const navigate = useNavigate();

    const [ message, setMessage ] = useState<string>(`I hope you're doing well! I'm looking for … and thought you'd be the perfect person to help out!`);

    const fontSize = (text: string) => {
        if (text?.length > 145) {
            return 14;
        } 
        if (text?.length > 75) {
            return 16;
        } 
        if (text?.length > 55) {
            return 18;
        } 
        return 20;
    }

    useEffect(() => {
        setClose(!props.show);
    }, [props.show]);

    return (
        <PopUp content={<>
            <div className={`w-flex`} data-color={`white`} onClick={e => { e.stopPropagation() }}>
                <p data-size={5} className={`semibold seminarrow mb-2`}><FormattedMessage id="write_message"/></p>                

                <div className={`block w-flex b-2 pt-2 mb-1`} data-border={`light-gray-2`} 
                    data-color={typeof props?.request?.style?.background === 'number' ? RequestColors2[props?.request?.style?.background]?.color : `transparent`} 
                    style={{overflow: `hidden`}}>
                    <div className={`w-flex row left gap-0 px-2 neg-mt pt`} hidden={!props.request?.user?.industry?.length} style={{overflow: `hidden`, maxHeight: 39}}>
                        {(props?.request?.user?.industry ?? []).map((elem: string, index: number) => {return (
                            <button className={`block btn-circled-24 desktop mb-2`} key={index} data-border={typeof props?.request?.style?.background !== 'number' ? true : null}
                                    onClick={e => {
                                        e.stopPropagation();
                                        if (typeof props.setFilterByIndustry === `function`) {
                                            props.setFilterByIndustry((val:string[]) => val.includes(elem) ? val : val.concat([elem]));
                                        }
                                    }} style={typeof props?.request?.style?.background !== 'number' ? {border: `solid 1px ${CreateHSLColor(elem)}`} : {}}>
                                <p style={{color: CreateHSLColor(elem)}} className={`not-active`}>{elem}</p>
                            </button>
                        )})}
                    </div>

                    <div className={`w-flex px-2 column left bottom clickable`} data-lines={typeof props?.request?.style?.background === 'number' ? null : 3}
                        id={`feed-card-about-${props?.request?.id}`}
                        style={typeof props?.request?.style?.background === 'number' ? {
                            paddingBottom: 14, fontSize: fontSize(props?.request?.answers[0]),
                            minHeight: 50,
                        } : {fontSize: 14, minHeight: 28}}>
                        {props?.request?.answers.reduce((init: string, elem: string) => {
                            return `${init}${init && '\n'}${elem.trim()}`;
                        }, '').trim().split(`\n`).map((elem: string, index: string) => {return ( elem !== '' ?
                        <p className={`article ${typeof props?.request?.style?.background === 'number' ? `semibold seminarrow` : ``}`} 
                        data-color={typeof props?.request?.style?.background === 'number' ? RequestColors2[props?.request?.style?.background]?.textColor : 'black'} key={index}>
                            {elem}
                        </p> : null)})}
                    </div>
                    
                    <div className={`block row w-flex nowrap nogap p-2`}
                        data-color={typeof props?.request?.style?.background === 'number' ? null : `transparent`}>
                        <div className={`row left nowrap clickable`} onClick={e => {
                            e.stopPropagation();
                            if (typeof props.onAvatarClick === `function`) {
                                props.onAvatarClick();
                            } else {
                                const newWindow: WindowProxy | null = window.open(`/profile/${props?.request?.user?.id}`, '_blank');
                                if (newWindow) {
                                    newWindow.focus();
                                }
                            }
                        }}>
                            <NewAvatar name={props?.request?.user?.name} type={props?.request?.user?.avatar?.style ?? 0} size={1}
                                    avatar={props?.request?.user?.avatar?.link}/>

                            <div className={`neg-my`}>
                                <p data-size={7} className={`semibold seminarrow`} data-color={'black'}>
                                    {props?.request?.user?.name ?? ``}
                                </p>
                                <p data-size={7} className={`seminarrow`} data-color={`black-40-opacity`} data-lines={1}>
                                    {props?.request?.user?.occupation ?? ``}
                                </p>
                            </div>
                        </div>
                    </div>
                </div> 

                <textarea className={`mb-2 w-flex h-6 b-1 p-2`} placeholder={`Type a message`} name={`message`}
                          value={message} onChange={e => { setMessage(e.target.value) }} data-size={6}></textarea>

                <button className={`block btn-circled-32 mobile row center w-7`} data-color={`black`} 
                        disabled={!message} onClick={() => {
                            setClose(true);
                            window.location.href = `https://my.osmos.social/?force_logout=true&can_help=${encodeURIComponent(message)}&request_id=${encodeURIComponent(props.request?.id)}&redirect=${encodeURIComponent(`/profile/requested`)}`
                       }}>
                    <p><FormattedMessage id="sign_in_send"/></p>
                </button>
            </div>
        </>} show={props.show} setShow={props.setShow} close={close} setClose={setClose}/>
    )
}

const PopupCenterInvite = (props: any) => {
    const [ close, setClose ] = useState(false);
    const globalContext = useContext(GlobalContext);

    const [ waitResponse, setWaitResponse ] = useState<boolean>(false);
    const [ addInvitees, setAddInvitees ] = useState<boolean>(false);
    const [ search, setSearch ] = useState<string>(``);

    const inviteeSearchInput = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if (addInvitees && inviteeSearchInput.current) {
            inviteeSearchInput.current.focus();
        } 
        if (!addInvitees) {
            setSearch(``);
        }
    }, [addInvitees])

    useEffect(() => {
        if (props.invitees.filter((val: any) => val?.user?.sendInvite)?.length === 0) {
            setClose(true);
        }
    }, [props.invitees])

    useEffect(() => {
        setClose(!props.show);
    }, [props.show]);

    return (
        <PopUp onlyBg={true} content={<>
            <form className={`block p-4 b-3 w-10 center-popup`} onClick={e => { e.stopPropagation(); setAddInvitees(false); }}
                  onSubmit={e => {
                        e.preventDefault();
                        setWaitResponse(true);
                        InviteTokenUse(props.setNewInvite, null, props.invitees.filter((val: any) => val?.user?.sendInvite)?.length, {
                            200: () => {
                                setWaitResponse(false);
                                (e.target as Element).classList.remove(`invalid-form`);
                                setClose(true);
                            }, 
                            0: () => { setWaitResponse(false) },
                        })
                  }}>
                <div className={`row w-flex mb-3`}>
                    <p data-size={4} className={`semibold seminarrow`}><FormattedMessage id="invite_write_message"/></p>
                    <button className={`btn btn-symbol filled close w-3 h-3 centered`} data-color={`black`} type={`button`} onClick={() => {
                        setClose(true);
                    }}>
                        <img src={PlusWhite} alt={`+`}></img>
                    </button>
                </div>

                {/* <input className={`desktop mb-1`} readOnly={true} value={props.email}></input> */}
                <div className={`w-flex mb-1 gap-0 row left`} onClick={e => { e.stopPropagation() }}>
                    {props.invitees?.map((elem: any, index: number) => {return (elem?.user?.sendInvite ?
                        <button className={`block btn-rectangle-40 desktop row p-left p-right`} data-color={`light-gray`} key={index} type={`button`}
                                onClick={() => { 
                                    props.setInvitees((val: any[]) => SetDict(val, [index, "user", "sendInvite"], false));
                                    setAddInvitees(false); 
                                }}>
                            <NewAvatar size={1} type={0} avatar={elem?.user?.avatar?.link} className={`not-active`}/>
                            <p>{elem?.user?.email}</p>
                            <div className={`btn-symbol centered close w-3 h-3 ml`}>
                                <img src={PlusBlack} alt={``}></img>
                            </div>
                        </button> : null
                    )})}
                    <button className={`block btn-rectangle-40 desktop row p-left p-right`} data-color={`light-gray`} type={`button`}
                            onClick={() => { setAddInvitees(true) }} hidden={addInvitees}>
                        <p><FormattedMessage id="add"/></p>
                        <div className={`btn-symbol centered w-3 h-3 ml-1`} data-color={addInvitees ? `white` : `black`}>
                            <img src={addInvitees ? PlusWhite : PlusBlack} alt={``}></img>
                        </div>
                    </button>
                </div>

                <input className={`desktop mb`} value={search} onChange={e => { setSearch(e.target?.value ?? ``) }} autoFocus={true}
                       hidden={!addInvitees} ref={inviteeSearchInput} placeholder={messages[globalContext.locale].search_by_email} onClick={e => { e.stopPropagation() }}></input>

                <div className={`w-flex h-8 neg-mb-8 input-select-list`} hidden={!addInvitees} onClick={e => { e.stopPropagation() }}>
                    <div className={`block p-1 b-1 w-flex max-h-8 neg-mb-8 y-auto`} data-color={`light-gray`}>
                        {props.invitees.map((elem: any, index: number, array: any[]) => {
                        const prev = !array[index - 1]?.user?.sendInvite !== !elem?.user?.sendInvite || !index;
                        const next = !array[index + 1]?.user?.sendInvite !== !elem?.user?.sendInvite || index + 1 === array?.length;
                        return ((elem?.user?.email ?? '').startsWith(search) ? <Fragment key={index}>
                            <div className={`block row p-1 left clickable nowrap ${(prev && next) ? `b-1` : (prev ? `bt-1` : (next ? `bb-1` : ``))}`} tabIndex={index}
                                    onClick={() => { 
                                        setAddInvitees(false);
                                        props.setInvitees((val: any[]) => SetDict(val, [index, "user", "sendInvite"], !elem?.user?.sendInvite));
                                    }} id={`select-btn`} data-color={elem?.user?.sendInvite ? `white` : `light-gray`}>
                                <NewAvatar size={3} type={0} avatar={elem?.user?.avatar?.link} className={`not-active`}/>
                                <p className={`semibold seminarrow`} data-size={8}>{elem?.user?.email}</p>
                            </div>
                            <div className={`block w-flex`} data-color={`gray`} 
                                 style={{height: 1, marginTop: -1}} hidden={next}></div>
                        </Fragment> : null)})}
                        <div className={`block w-flex`} data-color={`light-gray`} 
                                style={{height: 1, marginTop: -1}}></div>
                    </div>
                </div>

                <textarea className={`mb-2 w-flex b-1 p-2`} placeholder={`Type a message`} name={`message`} required={true}
                          value={props.message} onChange={e => { props.setMessage(e.target.value) }} style={{height: 158}}></textarea>

                <button className={`block btn-circled-40 desktop row center w-7`} data-color={`black`} type={`submit`} 
                        disabled={!props.message || waitResponse}>
                    <p><FormattedMessage id="invite_send"/></p>
                    {waitResponse && 
                    <Loading color={`white`} width={24} height={24}/>}
                </button>
            </form>
        </>} show={props.show} setShow={props.setShow} close={close}/>
    )
}

const PopupInviteMobile = (props: any) => {
    const [ close, setClose ] = useState(false);
    const globalContext = useContext(GlobalContext);

    const [ waitResponse, setWaitResponse ] = useState<boolean>(false);
    const [ addInvitees, setAddInvitees ] = useState<boolean>(false);
    const [ search, setSearch ] = useState<string>(``);

    const inviteeSearchInput = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if (addInvitees && inviteeSearchInput.current) {
            inviteeSearchInput.current.focus();
        } 
        if (!addInvitees) {
            setSearch(``);
        }
    }, [addInvitees])

    useEffect(() => {
        if (props.invitees.filter((val: any) => val?.user?.sendInvite)?.length === 0) {
            setClose(true);
        }
    }, [props.invitees])

    useEffect(() => {
        setClose(!props.show);
    }, [props.show]);

    return (
        <PopUp content={<>
            <form className={`w-flex y-auto neg-my-4 py-4`} data-color={`white`} 
                  onClick={e => { 
                    e.stopPropagation();
                    setAddInvitees(false); 
                }} style={{maxHeight: 600}}
                  onSubmit={e => {
                    e.preventDefault();
                    setWaitResponse(true);
                    InviteTokenUse(props.setNewInvite, null, props.invitees.filter((val: any) => val?.user?.sendInvite)?.length, {
                        200: () => {
                            setWaitResponse(false);
                            (e.target as Element).classList.remove(`invalid-form`);
                            setClose(true);
                        }, 
                        0: () => { setWaitResponse(false) }
                    })
                  }}>
                <p data-size={5} className={`semibold seminarrow mb-2`}>
                    <FormattedMessage id="invite_write_message"/>
                </p>
                
                {/* <input className={`mobile mb-1`} readOnly={true} value={props.email}
                       data-size={6}></input> */}
                <div className={`w-flex gap-0 row left`} onClick={e => { e.stopPropagation() }}>
                    {props.invitees?.map((elem: any, index: number) => {return (elem?.user?.sendInvite ?
                        <button className={`block btn-rectangle-40 mobile row p-left p-right`} data-color={`light-gray`} key={index} type={`button`}
                                onClick={() => { 
                                    props.setInvitees((val: any[]) => SetDict(val, [index, "user", "sendInvite"], false));
                                    setAddInvitees(false); 
                                }}>
                            <NewAvatar size={1} type={0} avatar={elem?.user?.avatar?.link} className={`not-active`}/>
                            <p data-size={6}>{elem?.user?.email}</p>
                            <div className={`btn-symbol centered close w-3 h-3 ml`}>
                                <img src={PlusBlack} alt={``}></img>
                            </div>
                        </button> : null
                    )})}
                    <button className={`block btn-rectangle-40 mobile row p-left p-right`} data-color={`light-gray`} type={`button`}
                            onClick={() => { setAddInvitees(val => !val) }} hidden={addInvitees}>
                        <p data-size={6}><FormattedMessage id="add"/></p>
                        <div className={`btn-symbol centered w-3 h-3 ml-1`} data-color={addInvitees ? `white` : `black`}>
                            <img src={addInvitees ? PlusWhite : PlusBlack} alt={``}></img>
                        </div>
                    </button>
                </div>

                {addInvitees ? <>
                <input className={`mobile my`} value={search} onChange={e => { setSearch(e.target?.value ?? ``) }} autoFocus={true} data-size={6}
                       ref={inviteeSearchInput} placeholder={messages[globalContext.locale].search_by_email} onClick={e => { e.stopPropagation() }}
                       onBlur={e => {
                            if (e.relatedTarget?.id !== `select-btn`) {
                                setAddInvitees(false);
                            }
                       }}></input>

                <div className={`w-flex max-h-7 input-select-list`} onClick={e => { e.stopPropagation() }}>
                    <div className={`block p-1 b-1 w-flex max-h-7 y-auto`} data-color={`light-gray`}>
                        {props.invitees.map((elem: any, index: number, array: any[]) => {                        
                        const prev = !array[index - 1]?.user?.sendInvite !== !elem?.user?.sendInvite || !index;
                        const next = !array[index + 1]?.user?.sendInvite !== !elem?.user?.sendInvite || index + 1 === array?.length;
                        return ((elem?.user?.email ?? '').startsWith(search) ? <Fragment key={index}>
                            <div className={`block row p-1 left nowrap ${(prev && next) ? `b-1` : (prev ? `bt-1` : (next ? `bb-1` : ``))}`} tabIndex={index}
                                    onClick={() => { 
                                        setAddInvitees(false);
                                        props.setInvitees((val: any[]) => SetDict(val, [index, "user", "sendInvite"], !elem?.user?.sendInvite));
                                    }} id={`select-btn`} data-color={elem?.user?.sendInvite ? `white` : `light-gray`}>
                                <NewAvatar size={3} type={0} avatar={elem?.user?.avatar?.link} className={`not-active`}/>
                                <p className={`semibold seminarrow`} data-size={8}>{elem?.user?.email}</p>
                            </div>
                            <div className={`block w-flex`} data-color={`gray`} 
                                 style={{height: 1, marginTop: -1}} hidden={next}></div>
                        </Fragment> : null)})}
                        <div className={`block w-flex`} data-color={`light-gray`} 
                                style={{height: 1, marginTop: -1}}></div>
                    </div>
                </div>
                </> : <>
                <textarea className={`my-2 w-flex h-6 b-1 p-2`} placeholder={`Type a message`} name={`message`} 
                          value={props.message} onChange={e => { props.setMessage(e.target.value) }} data-size={6}></textarea>

                <button className={`block btn-circled-40 mobile row center w-7`} data-color={`black`} type={`submit`} 
                        disabled={!props.message || waitResponse}>
                    <p><FormattedMessage id="send"/></p>
                    {waitResponse && 
                    <Loading color={`white`} width={24} height={24}/>}
                </button></>}
            </form>
        </>} show={props.show} setShow={props.setShow} close={close} setClose={setClose}/>
    )
}

const PopupCenterSignIn = (props: any) => {
    const navigate = useNavigate();
    const [ cookie, setCookie ] = useCookies([`user_id_cookie`, `no_sign_in_cookie`]);

    const [ searchParams ] = useSearchParams();
    const [ close, setClose ] = useState<boolean>(false);
    const [ email, setEmail ] = useState<string>(``);
    const [ waitEmail, setWaitEmail ] = useState<boolean>(false);

    useEffect(() => {
        setClose(!props.show);
    }, [props.show]);

    useEffect(() => {
        return () => {            
            setEmail(``);
            if (!cookie[`user_id_cookie`]) {
                setCookie(`no_sign_in_cookie`, `true`);
            }
        }
    }, [])

    return (
        <PopUp onlyBg={true} content={<>
            <div className={`block p-4 b-3 w-9 column top center-popup`} data-color={`white`} onClick={e => { e.stopPropagation() }}>
                <div className={`row w-flex mb-3 top nowrap`}> 
                <div className={`w-3`}></div>                   
                    <p data-size={4} className={`semibold seminarrow`}><FormattedMessage id="sign_in"/></p>
                    <button className={`btn btn-symbol filled close w-3 h-3 centered`} data-color={`black`} type={`button`} onClick={() => {
                        setClose(true);
                    }}>
                        <img src={PlusWhite} alt={`+`}></img>
                    </button>
                </div>

                {[
                    {title: `Google`, logo: GoogleLogo, onClick: () => {
                        GoogleAuth(
                            searchParams.get('code'), 
                            searchParams.get('community'), 
                            encodeURIComponent(`/profile`), 
                            null,
                            {
                                203: () => {
                                    navigate('/notfound');
                                }
                            },
                        )
                    }, text: '', shown: true},
                    {title: `LinkedIn`, logo: LinkedInLogo, onClick: () => {
                        LinkedInAuth(
                            searchParams.get('code'), 
                            searchParams.get('community'), 
                            encodeURIComponent(`/profile`), 
                            null,
                            {
                                203: () => {
                                    navigate('/notfound');
                                }
                            },
                        )
                    }, text: '', shown: false},
                ].map((elem, index) => {return (
                    <button className={`btn-google desktop row center ${!!index ? `mt-2` : ``} w-flex`} key={elem.title} 
                            data-color={`tp-black`} onClick={() => {elem.onClick()}}
                            hidden={!elem.shown} id={`continue-with-${elem.title.toLowerCase()}-button`}>
                        <img className={``} src={elem.logo} alt={`li`}></img>
                        <p>{`Continue with ${elem.title}`}</p>
                    </button>
                )})}
                <div className={`w-flex row py-2 nowrap`}>
                    <div className={`block w-flex`} data-color={`black-10-opacity`} style={{height: 1}}></div>
                    <p data-size={5} data-color={`black-40-opacity`} className={`noshrink semibold seminarrow`}>
                        <FormattedMessage id="or"/>
                    </p>
                    <div className={`block w-flex`} data-color={`black-10-opacity`} style={{height: 1}}></div>
                </div>
                <form className={`w-flex`} onSubmit={e => {
                        e.preventDefault();
                        if (email) {
                            setWaitEmail(true); 
                            setTimeout(() => { setWaitEmail(false) }, 5000);
                            EmailAuth(
                                email, () => {}, () => {}, 
                                searchParams.get('code'), 
                                searchParams.get('community'), 
                                encodeURIComponent(`/profile`), 
                                null,
                                {
                                    200: () => {                                     
                                        setWaitEmail(false);
                                        setClose(true);
                                    },
                                    203: () => {
                                        navigate('/notfound');
                                    }
                                },
                            )
                        } 
                    }} id={`continue-with-email-form`}>
                    <input autoComplete={`off`} className={`desktop mb-2`} placeholder={`Email address`} type={`email`}
                        value={email} onChange={e => {
                            setEmail(e.target.value);
                        }} required={true}></input> 
                    
                    <button className={`block btn-flex desktop w-flex row center`}
                            data-color={`black`} type={`submit`}>
                        {waitEmail ? 
                        <Loading color={`white`} width={24} height={24}/> : 
                        <p><FormattedMessage id="continue"/></p>}
                    </button>
                </form>
                <div className={`mt-3 row center nogap nowrap`}>
                    <p data-size={5} data-color={`black-40-opacity`} className={`semibold seminarrow`}>
                        <FormattedMessage id="reg_new_in"/>&nbsp;	
                        <a href={`/`}>
                            <span data-color={`black`}><FormattedMessage id="reg_join_now"/></span>
                        </a>
                    </p>
                </div>
            </div>
        </>} show={props.show} setShow={props.setShow} close={close}/>
    )
}

const PopupSignInMobile = (props: any) => {
    const navigate = useNavigate();
    const [ cookie, setCookie ] = useCookies([`user_id_cookie`, `no_sign_in_cookie`]);

    const [ searchParams ] = useSearchParams();
    const [ close, setClose ] = useState<boolean>(false);
    const [ email, setEmail ] = useState<string>(``);
    const [ waitEmail, setWaitEmail ] = useState<boolean>(false);

    useEffect(() => {
        setClose(!props.show);
    }, [props.show]);

    useEffect(() => {
        return () => {
            setEmail(``);
            if (!cookie[`user_id_cookie`]) {
                setCookie(`no_sign_in_cookie`, `true`);
            }
        }
    }, [])

    return (
        <PopUp content={<>                 
            <p data-size={4} className={`center semibold seminarrow mb-3`}><FormattedMessage id="sign_in"/></p>

            {[
                {title: `Google`, logo: GoogleLogo, onClick: () => {
                    GoogleAuth(
                        searchParams.get('code'), 
                        searchParams.get('community'), 
                        encodeURIComponent(`/profile`), 
                        null,
                        {
                            203: () => {
                                navigate('/notfound');
                            }
                        },
                    )
                }, text: '', shown: true},
                {title: `LinkedIn`, logo: LinkedInLogo, onClick: () => {
                    LinkedInAuth(
                        searchParams.get('code'), 
                        searchParams.get('community'), 
                        encodeURIComponent(`/profile`), 
                        null,
                        {
                            203: () => {
                                navigate('/notfound');
                            }
                        },
                    )
                }, text: '', shown: false},
            ].map((elem, index) => {return (
                <button className={`btn-google mobile row center ${!!index ? `mt-2` : ``} w-flex`} key={elem.title} 
                        data-color={`tp-black`} onClick={() => {elem.onClick()}}
                        hidden={!elem.shown} id={`continue-with-${elem.title.toLowerCase()}-button`}>
                    <img className={``} src={elem.logo} alt={`li`}></img>
                    <p>{`Continue with ${elem.title}`}</p>
                </button>
            )})}
            <div className={`w-flex row py-2 nowrap`}>
                <div className={`block w-flex`} data-color={`black-10-opacity`} style={{height: 1}}></div>
                <p data-size={8} data-color={`black-40-opacity`} className={`noshrink semibold seminarrow`}>
                    <FormattedMessage id="or"/>
                </p>
                <div className={`block w-flex`} data-color={`black-10-opacity`} style={{height: 1}}></div>
            </div>
            <form className={`w-flex`} onSubmit={e => {
                    e.preventDefault();
                    if (email) {
                        setWaitEmail(true); 
                        setTimeout(() => { setWaitEmail(false) }, 5000);
                        EmailAuth(
                            email, () => {}, () => {}, 
                            searchParams.get('code'), 
                            searchParams.get('community'), 
                            encodeURIComponent(`/profile`), 
                            null,
                            {
                                200: () => {                                     
                                    setWaitEmail(false);
                                    setClose(true);
                                },
                                203: () => {
                                    navigate('/notfound');
                                }
                            },
                        )
                    } 
                }} id={`continue-with-email-form`}>
                <input autoComplete={`off`} className={`mobile mb-1`} placeholder={`Email address`} type={`email`}
                        data-size={6}
                    value={email} onChange={e => {
                        setEmail(e.target.value);
                    }} required={true}></input> 
                
                <button className={`block btn-flex mobile w-flex row center`}
                        data-color={`black`} type={`submit`}>
                    {waitEmail ? 
                    <Loading color={`white`} width={24} height={24}/> : 
                    <p><FormattedMessage id="continue"/></p>}
                </button>
            </form>
            <div className={`mt-3 row center nogap nowrap`}>
                <p data-size={8} data-color={`black-40-opacity`} className={`semibold seminarrow`}>
                    <FormattedMessage id="reg_new_in"/>&nbsp;	
                    <a href={`/`}>
                        <span data-color={`black`}><FormattedMessage id="reg_join_now"/></span>
                    </a>
                </p>
            </div>
        </>} show={props.show} setShow={props.setShow} close={close} setClose={setClose}/>
    )
}

const PopupCenterUnsubscribe = (props: any) => {
    const [ close, setClose ] = useState<boolean>(false);

    useEffect(() => {
        setClose(!props.show);
    }, [props.show]);

    return (
        <PopUp onlyBg={true} content={<>
            <div className={`block p-4 b-3 w-${props.desktop || !props.mobile ? 10 : `flex`} sm-col-9`} 
                 onClick={e => { e.stopPropagation() }}>
                <div className={`row right w-flex neg-mb-3`}>
                    <button className={`btn btn-symbol filled close w-3 h-3 centered`} data-color={`black`} type={`button`} onClick={() => {
                        setClose(true);
                    }}>
                        <img src={PlusWhite} alt={`+`}></img>
                    </button>
                </div>
                {!props.thanks ? 
                <div className={`column top w-flex`}>
                    <p data-size={props.desktop || !props.mobile ? 5 : 6} className={`semibold seminarrow mb-4`}>
                        <FormattedMessage id="marketing_emails"/>
                    </p>
                    <p data-size={props.desktop || !props.mobile ? 4 : 5} className={`center mb-4 px-4`}>
                        <FormattedMessage id="marketing_unsub"/>
                    </p>
                    <button className={`block btn-circled-40 desktop mb`} 
                            data-color={`black`}
                            onClick={() => {
                                setClose(true);
                            }}>
                        <p><FormattedMessage id="give_one_more_chance"/></p>
                    </button>
                    <button className={`block btn-circled-40 desktop`} 
                            data-color={`transparent`}
                            onClick={() => {
                                if (typeof props.onUnsubscribeAll === `function`) {
                                    props.onUnsubscribeAll();
                                }
                            }}>
                        <p data-color={`black`}><FormattedMessage id="unsub"/></p>
                    </button>
                </div> : 
                <div className={`column top w-flex`}>
                    <NewAvatar size={6} name={props.profile?.name} avatar={props.profile?.avatar?.link} 
                               type={props.profile?.avatar?.style}  className={`circled mb-2`}/>                    
                    <p data-size={props.desktop || !props.mobile ? 4 : 5} className={`center mb-4`}>
                        {`Thank you for staying with us, ${props.profile?.fname ?? `friend`}, now you are unsubscribed from all marketing email newsletters`}
                    </p>
                    <button className={`block btn-circled-40 desktop mb w-8 row center`} 
                            data-color={`black`}
                            onClick={() => {
                                setClose(true);
                            }}>
                        <p><FormattedMessage id="thanks"/></p>
                    </button>
                </div>}
            </div>
        </>} show={props.show} setShow={props.setShow} close={close}/>
    )
}

const PopupUnsubscribeMobile = (props: any) => {
    const [ close, setClose ] = useState<boolean>(false);

    useEffect(() => {
        setClose(!props.show);
    }, [props.show]);

    return (
        <PopUp content={<>
            <div className={`w-flex`} onClick={e => { e.stopPropagation() }}>
                {!props.thanks ? 
                <div className={`column top w-flex`}>
                    <p data-size={5} className={`semibold seminarrow mb-3`}>
                        <FormattedMessage id="marketing_emails"/>
                    </p>
                    <p data-size={4} className={`center mb-3`}>
                        <FormattedMessage id="marketing_unsub"/>
                    </p>
                    <div className={`row nowrap w-flex`}>
                        <button className={`block btn-circled-40 mobile w-flex row center`} 
                                data-color={`black`}
                                onClick={() => {
                                    setClose(true);
                                }}>
                            <p><FormattedMessage id="go_back"/></p>
                        </button>
                        <button className={`block btn-circled-40 mobile w-flex row center`} 
                                data-color={`light-gray`}
                                onClick={() => {
                                    if (typeof props.onUnsubscribeAll === `function`) {
                                        props.onUnsubscribeAll();
                                    }
                                }}>
                            <p data-color={`black`}><FormattedMessage id="unsub"/></p>
                        </button>
                    </div>
                </div> : 
                <div className={`column top w-flex`}>
                    <NewAvatar size={6} name={props.profile?.name} avatar={props.profile?.avatar?.link} 
                               type={props.profile?.avatar?.style}  className={`circled mb-2`}/>                    
                    <p data-size={4} className={`center mb-3`}>
                        {`Thank you for staying with us, ${props.profile?.fname ?? `friend`}, now you are unsubscribed from all marketing email newsletters`}
                    </p>
                    <button className={`block btn-circled-40 mobile mb w-8 row center`} 
                            data-color={`black`}
                            onClick={() => {
                                setClose(true);
                            }}>
                        <p><FormattedMessage id="thanks"/></p>
                    </button>
                </div>}
            </div>
        </>} show={props.show} setShow={props.setShow} close={close} setClose={setClose}/>
    )
}

const PopupCenterLogOut = (props: any) => {
    const [ close, setClose ] = useState<boolean>(false);

    useEffect(() => {
        setClose(!props.show);
    }, [props.show]);

    return (
        <PopUp onlyBg={true} content={<>
            <div className={`block p-4 b-3 w-${props.desktop || !props.mobile ? 10 : `flex`} sm-col-9`} 
                 onClick={e => { e.stopPropagation() }}>
                <div className={`row right w-flex neg-mb-3`}>
                    <button className={`btn btn-symbol filled close w-3 h-3 centered`} data-color={`black`} type={`button`} onClick={() => {
                        setClose(true);
                    }}>
                        <img src={PlusWhite} alt={`+`}></img>
                    </button>
                </div>
                <div className={`column top w-flex`}>
                    <NewAvatar size={6} name={props.profile?.name} avatar={props.profile?.avatar?.link} 
                               type={props.profile?.avatar?.style}  className={`circled mb-2`}/>                    
                    <p data-size={props.desktop || !props.mobile ? 4 : 5} className={`center mb-4`} style={{maxWidth: 400}}>
                        <FormattedMessage id="settings_logout_hint"/>
                    </p>
                    <button className={`block btn-rectangle-40 desktop mb w-8 row center`} 
                            data-color={`black`}
                            onClick={() => {
                                setClose(true);
                            }}>
                        <p><FormattedMessage id="settings_logout_stay"/></p>
                    </button>
                    <button className={`block btn-rectangle-40 desktop w-8 row center`} 
                            data-color={`transparent`}
                            onClick={() => { LogOut() }}>
                        <p data-color={`black`}><FormattedMessage id="settings_logout"/></p>
                    </button>
                </div>
            </div>
        </>} show={props.show} setShow={props.setShow} close={close}/>
    )
}

const PopupCenterDelete = (props: any) => {
    const globalContext = useContext(GlobalContext);

    const [ close, setClose ] = useState<boolean>(false);
    const [ deleteTab, setDeleteTab ] = useState<number>(2);
    
    const [ deleteReason, setDeleteReason ] = useState<string>(''); 

    const [ select, setSelect ] = useState<any[]>([
        {
            title: <FormattedMessage id="settings_profile_delete_reason_0"/>,
        },
        {
            title: <FormattedMessage id="settings_profile_delete_reason_1"/>,
        },
        {
            title: <FormattedMessage id="settings_profile_delete_reason_2"/>,
        },
        {
            title: <FormattedMessage id="settings_profile_delete_reason_3"/>,
        },
        {
            title: <FormattedMessage id="settings_profile_delete_reason_4"/>,
        },
        {
            title: <FormattedMessage id="settings_profile_delete_reason_5"/>,
        },
    ]);

    const otherDescription = useRef(null);

    useEffect(() => {
        setClose(!props.show);
    }, [props.show]);

    return (
        <PopUp onlyBg={true} content={
        <div className={`block p-4 b-3 w-${props.desktop || !props.mobile ? 10 : `flex`} sm-col-9`} 
             onClick={e => { e.stopPropagation() }}>
            <div className={`row w-flex mb-3`}>
                <p data-size={4} className={`semibold seminarrow`}><FormattedMessage id="settings_profile_delete"/></p>
                <button className={`btn btn-symbol filled close w-3 h-3 centered`} data-color={`black`} type={`button`} onClick={() => {
                    setClose(true);
                }}>
                    <img src={PlusWhite} alt={`+`}></img>
                </button>
            </div>
            {!(deleteTab - 2) && <>
            <div className={`w-flex mb-2`}>
                {select.map((elem, index) => {return (                
                    <button className={`block btn-checkbox desktop row top left nowrap noscroll ${!!index ? `mt` : ``} w-flex`} 
                            data-selected={elem.enabled} style={{padding: 8}}
                            key={index} onClick={() => {
                                setSelect(select => SetDict(select, [index, 'enabled'], !select[index].enabled))
                            }}>
                        <div className={`checkbox-radio h-3 w-3 b centered noshrink`} data-checked={elem.enabled}>
                            <img src={OkWhite} alt={`v`}></img>
                        </div>
                        <p data-size={5}>{elem.title}</p>
                    </button>
                )})}
            </div>

            {select[5].enabled &&
            <textarea className={`w-flex h-6 b-1 p-2`} placeholder={messages[globalContext.locale].describe_situation}
                        ref={otherDescription} onChange={e => {setDeleteReason(e.target.value)}}></textarea>}

            <p data-size={6} className={`mb-3`}>
                <FormattedMessage id="settings_profile_delete_hint_1"/>&nbsp;{DateFromString(props.profile?.subscription?.expire_at)}{`.`}
                <br/>
                <FormattedMessage id="settings_profile_delete_hint_2"/>
            </p>

            <div className={`row left nowrap`}>
                <button className={`block btn-rectangle-40 desktop row center`} data-color={'black'} 
                        style={{width: 153}} onClick={() => {
                            setClose(true);
                        }}>
                    <p><FormattedMessage id="cancel"/></p>
                </button> 

                <button className={`block btn-rectangle-40 desktop row center`} data-color={'light-gray'} 
                        style={{minWidth: 153}} onClick={() => {
                            if (select.filter(val => val.enabled).length && 
                                (!select[5].enabled || !!deleteReason)) {
                                setDeleteTab(201)
                            }
                        }} disabled={!select.find(val => !!val.enabled)}>
                    <p data-color={`black`}><FormattedMessage id="settings_profile_delete"/></p>
                </button>
            </div>
            </>}

            {!(deleteTab - 201) && <>
            <NewAvatar name={props.profile?.name} size={8} avatar={props.profile?.avatar?.link} 
                        type={props.profile?.avatar?.style} className={`circled my-4`}/>

            <p data-size={5} className={`w-flex mb-1`} data-color={`black`}>
                <FormattedMessage id="settings_profile_delete_final_1"/>,&nbsp;
                {props.profile?.fname ?? `friend`},&nbsp;
                <FormattedMessage id="settings_profile_delete_final_2"/>
            </p>  
            <p data-size={5} className={`w-flex mb-3`} data-color={`black`}>
            <FormattedMessage id="settings_profile_delete_final_3"/>
            </p>     

            <div className={`row left nowrap`}>
                <button className={`block btn-rectangle-40 desktop row center`} data-color={'black'} 
                        style={{width: 153}} onClick={() => {
                            setClose(true);
                        }}>
                    <p><FormattedMessage id="cancel"/></p>
                </button> 

                <button className={`block btn-rectangle-40 desktop row center`} data-color={'light-gray'} 
                        style={{minWidth: 153}} onClick={() => {
                            ProfileDelete(props?.profile?.links?.email, () => { LogOut() })
                        }} id={`delete-user-btn`}>
                    <p data-color={`black`}><FormattedMessage id="settings_profile_delete"/></p>
                </button> 
            </div>
            </>}  
        </div>} show={props.show} setShow={props.setShow} close={close}/>
    )
}

const PopupLogOutMobile = (props: any) => {
    const [ close, setClose ] = useState<boolean>(false);

    useEffect(() => {
        setClose(!props.show);
    }, [props.show]);

    return (
        <PopUp content={<>
            <div className={`w-flex`} 
                 onClick={e => { e.stopPropagation() }}>
                <div className={`column top w-flex`}>
                    <NewAvatar size={6} name={props.profile?.name} avatar={props.profile?.avatar?.link} 
                               type={props.profile?.avatar?.style}  className={`circled mb-2`}/>                    
                    <p data-size={props.desktop || !props.mobile ? 4 : 5} className={`center mb-4`} style={{maxWidth: 400}}>
                        <FormattedMessage id="settings_logout_hint"/>
                    </p>
                    <button className={`block btn-rectangle-40 mobile w-8 row center mb`} 
                            data-color={`black`}
                            onClick={() => {
                                setClose(true);
                            }}>
                        <p><FormattedMessage id="stay_signed_in"/></p>
                    </button>
                    <button className={`block btn-rectangle-40 mobile w-8 row center`} 
                            data-color={`transparent`}
                            onClick={() => { LogOut() }}>
                        <p data-color={`black`}><FormattedMessage id="settings_logout"/></p>
                    </button>
                </div>
            </div>
        </>} show={props.show} setShow={props.setShow} close={close} setClose={setClose}/>
    )
}

const PopupCenterChangeEmail = (props: any) => {
    const [ close, setClose ] = useState(false);

    useEffect(() => {
        setClose(!props.show);
    }, [props.show]);

    return (
        <PopUp onlyBg={true} content={<>
            <div className={`block p-4 b-3 w-${props.desktop || !props.mobile ? 9 : `flex`} center-popup`} onClick={e => { e.stopPropagation() }}>
                <div className={`row right w-flex neg-mb-3`}>
                    <button className={`btn btn-symbol filled close w-3 h-3 centered`} data-color={`black`} type={`button`} onClick={() => {
                        setClose(true);
                    }}>
                        <img src={PlusWhite} alt={`+`}></img>
                    </button>
                </div>
                <div className={`column top`}>
                    <p data-size={props.desktop || !props.mobile ? 4 : 5} className={`center semibold seminarrow mb-2`}>
                        <FormattedMessage id="verify_new_email"/>
                    </p>
                    <p data-size={props.desktop || !props.mobile ? 5 : 8} className={`center`}>
                        {`Please check your inbox to verify your\u00A0new email.`}
                    </p>
                    <button className={`block btn-rectangle-${props.desktop || !props.mobile ? `40 desktop` : `32 mobile`} mt-3`} data-color={`black`}
                            onClick={() => {
                                if (props.mailbox) {
                                    window.location.href = props.mailbox;
                                } else {
                                    setClose(true);
                                }
                            }}>
                        <p>{props.mailbox ? <FormattedMessage id="reg_open_mailbox"/> : `Ok, I got it`}</p>
                    </button>
                </div>
            </div>
        </>} show={props.show} setShow={props.setShow} close={close}/>
    )
}

const PopupCenterSuggestAFriend = (props: any) => {
    const globalContext = useContext(GlobalContext);

    const [ close, setClose ] = useState(false);
    const context = useContext(ProfileContext);

    const [ search, setSearch] = useState<string>(``);
    const [ message, setMessage ] = useState<string>(`Hey! It seems like this person is in need of a professional like you.\nI hope this could lead to a fruitful collaboration.`);
    const [ friendAutoFill, setFriendAutoFill ] = useState<boolean>(false);
    const [ friend, setFriend ] = useState<any>();

    const [ waitServer, setWaitServer ] = useState<boolean>(false);

    const FilterFriends = (friends: any[], search: string) => {
        return friends.filter(val => (
            (val.fname ?? '').toLowerCase().startsWith(search.toLowerCase()) ||
            (val.lname ?? '').toLowerCase().startsWith(search.toLowerCase()) ||
            (val.name ?? '').toLowerCase().startsWith(search.toLowerCase())
        ))
    }

    useEffect(() => {
        setClose(!props.show);
    }, [props.show]);

    return (
        <PopUp onlyBg={true} content={<>
            <form className={`block p-4 b-3 w-10 center-popup`} data-color={`white`} onClick={e => { e.stopPropagation() }}
                  onSubmit={e => {
                        e.preventDefault();
                        setWaitServer(true);
                        SuggestAFriend(
                            friend?.id,
                            props.request?.id,
                            message ? message : null,
                            () => { 
                                setWaitServer(false);
                                setClose(true); 
                            }, () => { 
                                setWaitServer(false); 
                            },
                        )                        
                  }}>
                <div className={`row w-flex mb-3`}>
                    <p data-size={4} className={`semibold seminarrow`}><FormattedMessage id="suggest_friend"/></p>
                    <button className={`btn btn-symbol filled close w-3 h-3 centered`} data-color={`black`} type={`button`} onClick={() => {
                        setClose(true);
                    }}>
                        <img src={PlusWhite} alt={`+`}></img>
                    </button>
                </div>

                <div className={`block w-flex p-3 mb-2 b-2`} data-border={`gray-1`}>
                    <div className={`row left mb-2 nowrap`}>
                        <NewAvatar size={3} name={props.request?.name} avatar={props.request?.avatar?.link} 
                                   type={props.request?.avatar?.style}/>
                        <div>
                            <p data-size={6} className={`semibold seminarrow`} data-lines={1}>{props.request?.name}</p>
                            <p data-size={7} data-color={`black-40-opacity`} data-lines={1}>{props.request?.occupation}</p>
                        </div>
                    </div>
                    <p data-lines={4} data-size={6}>
                        {(props.request?.answers[0] ?? '').trim().split(`\n`).map((elem: string, index: number) => {return (<Fragment key={index}>
                            {elem}<br/>
                        </Fragment>)})}
                    </p>
                </div>

                <div className={`input-block desktop row nowrap`} data-color={`light-gray`}
                     style={{padding: `0 10px`}}>
                    {!friend ?
                    <input className={`desktop`} placeholder={messages[globalContext.locale].search_by_name} 
                           onChange={e => { 
                            setSearch(e.target.value); 
                        }} onBlur={e => { 
                            if (e.relatedTarget?.id === `select-btn`) {

                            } else {
                                setFriendAutoFill(false); 
                            }
                        }} onFocus={e => { 
                            setFriendAutoFill(true);
                        }}></input> :
                    <button className={`block btn-rectangle-32 desktop row p-left p-right `} onClick={() => { 
                                setFriend(null);
                                setSearch(``); 
                            }}
                            style={{margin: `13px 3px`}}>
                        <NewAvatar size={1} name={friend.name} avatar={friend.avatar?.link} type={friend.avatar?.style}/>
                        <p>{friend.name}</p>
                        <div className={`w-3 h-3 centered`}>
                            <img src={PlusBlack} alt={`+`} style={{transform: `rotate(45deg)`, width: 12}}></img>
                        </div>
                    </button>}
                    <img src={LensBlack} alt={``} className={`w-3`}></img>
                </div>

                <div className={`w-flex h-8 neg-mb-8 input-select-list pt-1`} id={`input-friend-list`} hidden={!friendAutoFill}>
                    <div className={`block p-2 b-2 w-flex max-h-8 neg-mb-8 y-auto`} data-color={`light-gray`}>
                        {FilterFriends(context.subscriptions?.follow ?? [], search)
                        .map((elem: any, index: number) => {return (
                            <div className={`label btn desktop row p-1 row left clickable`} tabIndex={index}
                                    onClick={() => {
                                        setFriend(elem);
                                        setFriendAutoFill(false);
                                    }} id={`select-btn`} key={index}>
                                <NewAvatar name={elem.name} avatar={elem.avatar?.link} type={elem.avatar?.style} size={3}/>
                                <div>
                                    <p data-size={6} className={`semibold seminarrow`}>{elem.name}</p>
                                    <p data-size={7} data-color={`black-40-opacity`} data-lines={1} className={`ellipsis w-flex`}>
                                        {elem.occupation}
                                    </p>
                                </div>
                            </div>
                        )})}
                    </div>
                </div>

                <textarea className={`desktop w-flex h-7 mt-1 b-1 p-2`} value={message} onChange={e => {
                    setMessage(e.target.value);
                }} placeholder="Type a message"></textarea>

                <button className={`block btn-circled-40 desktop row center mt-2`} data-color={`black`} type={`submit`} disabled={!friend}>
                    <p><FormattedMessage id="suggest"/></p>
                    {waitServer &&
                    <Loading width={24} height={24} color={`white`}/>}
                </button>
            </form>
        </>} show={props.show} setShow={props.setShow} close={close}/>
    )
}

const PopupSuggestAFriendMobile = (props: any) => {
    const globalContext = useContext(GlobalContext);

    const [ close, setClose ] = useState(false);
    const context = useContext(ProfileContext);

    const [ search, setSearch] = useState<string>(``);
    const [ message, setMessage ] = useState<string>(`Hey! It seems like this person is in need of a professional like you. I hope this could lead to a fruitful collaboration.`);
    const [ friendAutoFill, setFriendAutoFill ] = useState<boolean>(false);
    const [ friend, setFriend ] = useState<any>();

    const [ waitServer, setWaitServer ] = useState<boolean>(false);

    const FilterFriends = (friends: any[], search: string) => {
        return friends.filter(val => (
            (val.fname ?? '').toLowerCase().startsWith(search.toLowerCase()) ||
            (val.lname ?? '').toLowerCase().startsWith(search.toLowerCase()) ||
            (val.name ?? '').toLowerCase().startsWith(search.toLowerCase())
        ))
    }

    useEffect(() => {
        setClose(!props.show);
    }, [props.show]);

    return (
        <PopUp content={<>
            <form className={`block b-3 w-flex`} data-color={`white`} onClick={e => { e.stopPropagation() }}
                  onSubmit={e => {
                        e.preventDefault();
                        setWaitServer(true);
                        SuggestAFriend(
                            friend?.id,
                            props.request?.id,
                            message ? message : null,
                            () => { 
                                setWaitServer(false);
                                setClose(true); 
                            }, () => { 
                                setWaitServer(false); 
                            },
                        )                        
                  }}>
                <p data-size={5} className={`semibold seminarrow mb-2`}>
                    <FormattedMessage id="suggest_friend"/>
                </p>
                
                <div className={`block w-flex p-2 pb-2 mb-1 b-2`} data-border={`gray-1`}>
                    <div className={`row left mb-2 nowrap`}>
                        <NewAvatar size={3} name={props.request?.name} avatar={props.request?.avatar?.link} 
                                   type={props.request?.avatar?.style}/>
                        <div>
                            <p data-size={6} className={`semibold seminarrow`} data-lines={1}>{props.request?.name}</p>
                            <p data-size={7} data-color={`black-40-opacity`} data-lines={1}>{props.request?.occupation}</p>
                        </div>
                    </div>
                    <p data-lines={4} data-size={6}>
                        {(props.request?.answers[0] ?? '').trim().split(`\n`).map((elem: string, index: number) => {return (<Fragment key={index}>
                            {elem}<br/>
                        </Fragment>)})}
                    </p>
                </div>

                <div className={`input-block desktop row nowrap`} data-color={`light-gray`}
                     style={{borderRadius: 8}}>
                    {!friend ?
                    <input className={`mobile`} placeholder={messages[globalContext.locale].search_by_name}
                           data-size={6} value={search}
                           onChange={e => { 
                            setSearch(e.target.value); 
                        }} onBlur={e => { 
                            if (e.relatedTarget?.id === `select-btn`) {

                            } else {
                                setFriendAutoFill(false); 
                            }
                        }} onFocus={e => { 
                            setFriendAutoFill(true); 
                        }}></input> :
                    <button className={`block btn-rectangle-32 mobile row p-left p-right `} onClick={() => { 
                                setFriend(null);
                                setSearch(``);
                            }}
                            style={{margin: 8}}>
                        <NewAvatar size={1} name={friend.name} avatar={friend.avatar?.link} type={friend.avatar?.style}/>
                        <p>{friend.name}</p>
                        <div className={`w-3 h-3 centered`}>
                            <img src={PlusBlack} alt={`+`} style={{transform: `rotate(45deg)`, width: 12}}></img>
                        </div>
                    </button>}
                    <img src={LensBlack} alt={``} className={`w-3`}></img>
                </div>

                <div className={`w-flex input-select-list`} id={`input-friend-list`} hidden={!friendAutoFill}>
                    <div className={`w-flex h-7 y-auto mobile pt`}>
                        {FilterFriends(context.subscriptions?.follow ?? [], search)
                        .map((elem: any, index: number) => {return (
                            <div className={`btn mobile row p-1 row left w-flex`} tabIndex={index}
                                    onClick={() => {
                                        setFriend(elem);
                                        setFriendAutoFill(false);
                                    }} id={`select-btn`} key={index}>
                                <NewAvatar name={elem.name} avatar={elem.avatar?.link} type={elem.avatar?.style} size={3}/>
                                <div>
                                    <p data-size={6} className={`semibold seminarrow`}>{elem.name}</p>
                                    <p data-size={7} data-color={`black-40-opacity`} data-lines={1} className={`ellipsis w-flex`}>
                                        {elem.occupation}
                                    </p>
                                </div>
                            </div>
                        )})}
                    </div>
                </div>

                <textarea className={`mobile w-flex h-6 mt-1 b-1 p-2`} value={message} onChange={e => {
                    setMessage(e.target.value);
                }} placeholder={`Type a message`} data-size={6} hidden={friendAutoFill}></textarea>

                <button className={`block btn-circled-40 mobile row center mt-2`} data-color={`black`} type={`submit`} disabled={!friend}>
                    <p><FormattedMessage id="suggest"/></p>
                    {waitServer &&
                    <Loading width={24} height={24} color={`white`}/>}
                </button>
            </form>
        </>} show={props.show} setShow={props.setShow} close={close} setClose={setClose}/>
    )
}

const PopupCenterUpdatePrivacy = (props: any) => {
    const [ cookie ] = useCookies([`user_id_cookie`]);

    const [ close, setClose ] = useState<boolean>(false);
    const [ privacy, setPrivacy ] = useState<boolean>(false);

    useEffect(() => {
        setClose(!props.show);
    }, [props.show]);

    return (
        <PopUp onlyBg={true} content={<>
            <div className={`block p-4 b-3 w-10 sm-col-9`} 
                 onClick={e => { e.stopPropagation() }}>
                {!privacy ? <>
                    <p data-size={5} className={`center semibold seminarrow mb-4`}>
                        {`Privacy Update`}
                    </p>
                    <p data-size={4} className={`center mb-4 px-4`}>
                        {`Take a moment to review and accept the latest Osmos privacy policy to ensure your data is handled with care.`}
                    </p>
                    <div className={`row w-flex nowrap center`}>
                        <button className={`block btn-circled-40 desktop w-7 row center`} 
                                data-color={`black`}
                                onClick={() => {
                                    FirstLoginAdd(cookie[`user_id_cookie`], 'update_privacy', () => {
                                        setClose(true);
                                    })
                                }}>
                            <p><FormattedMessage id="accept"/></p>
                        </button>
                        <button className={`block btn-circled-40 desktop w-7 row center`} 
                                data-color={`light-gray`}
                                onClick={() => {
                                    setPrivacy(true)
                                }}>
                            <p data-color={`black`}><FormattedMessage id="review"/></p>
                        </button>
                    </div>
                </> : <> 
                <div className={`w-flex h-10 mb-3`}>
                    <PrivacyPolicy className={`w-flex`} desktop={true}/>           
                </div>
                <button className={`block btn-circled-40 desktop w-7 row center`} 
                        data-color={`black`}
                        onClick={() => {
                            FirstLoginAdd(cookie[`user_id_cookie`], 'update_privacy', () => {
                                setClose(true);
                            })
                        }}>
                    <p><FormattedMessage id="accept"/></p>
                </button></>}
            </div>
        </>} show={props.show} setShow={props.setShow} close={close}/>
    )
}

const PopupUpdatePrivacyMobile = (props: any) => {
    const [ cookie ] = useCookies([`user_id_cookie`]);

    const [ close, setClose ] = useState<boolean>(false);
    const [ privacy, setPrivacy ] = useState<boolean>(false);

    useEffect(() => {
        setClose(!props.show);
    }, [props.show]);

    return (
        <PopUp content={<>
            <div className={`w-flex`} 
                 onClick={e => { e.stopPropagation() }}>
                {!privacy ? <>
                    <p data-size={5} className={`center semibold seminarrow mb-3`}>
                        {`Privacy Update`}
                    </p>
                    <p data-size={4} className={`center mb-3`}>
                        {`Take a moment to review and accept the latest Osmos privacy policy to ensure your data is handled with care.`}
                    </p>
                    <div className={`row w-flex nowrap center`}>
                        <button className={`block btn-circled-40 mobile w-7 row center`} 
                                data-color={`black`}
                                onClick={() => {
                                    FirstLoginAdd(cookie[`user_id_cookie`], 'update_privacy', () => {
                                        setClose(true);
                                    })
                                }}>
                            <p><FormattedMessage id="accept"/></p>
                        </button>
                        <button className={`block btn-circled-40 mobile w-7 row center`} 
                                data-color={`light-gray`}
                                onClick={() => {
                                    setPrivacy(true)
                                }}>
                            <p data-color={`black`}><FormattedMessage id="review"/></p>
                        </button>
                    </div>
                </> : <> 
                <div className={`w-flex h-9 mb-3 neg-mt-4`}>
                    <PrivacyPolicy className={`pt-4`}/>           
                </div>
                <button className={`block btn-circled-40 mobile w-7 row center`} 
                        data-color={`black`}
                        onClick={() => {
                            FirstLoginAdd(cookie[`user_id_cookie`], 'update_privacy', () => {
                                setClose(true);
                            })
                        }}>
                    <p><FormattedMessage id="accept"/></p>
                </button></>}
            </div>
        </>} show={props.show} setShow={props.setShow} close={close} setClose={setClose}/>
    )
}

const PopupCenterSubscription = (props: any) => {
    const [ close, setClose ] = useState(false);
    const context = useContext(ProfileContext);
    const navigate = useNavigate();

    const [ waitResponse, setWaitResponse ] = useState<boolean>(false);

    useEffect(() => {
        setClose(!props.show);
    }, [props.show]);

    return (
        <PopUp onlyBg={true} customZIndex={202} blurBg={true} 
               preventClose={IsClosedBlock(context.page, context.tab)} content={<>
            <div className={`block p-4 b-3 w-9 center-popup`} data-color={`coral`} onClick={e => { e.stopPropagation() }}>
                <p data-size={4} className={`semibold seminarrow center mb-2`}>
                    {`Your subscription ${context.profile?.subscription?.is_stripe_customer ? "has expired" : "is not active"}`}
                </p>
                <p data-size={5} className={`center mb-3`}><FormattedMessage id="hope_back"/></p>

                <button className={`block btn-rectangle-40 desktop row center w-flex`} onClick={() => {
                    setWaitResponse(true);
                    OpenStripePortal(
                        context.profile?.subscription?.is_stripe_customer, 
                        () => { setWaitResponse(false) },
                        undefined, 
                        context.profile?.subscription?.is_subscription_active ? '2m' : undefined
                    );
                }} style={{paddingLeft: 14, paddingRight: 14}}>
                    {waitResponse ?
                    <Loading color={`white`} width={24} height={24}/> :
                    <p data-color={`coral`}><FormattedMessage id="activate_subscription"/></p>}
                </button>
                {IsClosedBlock(context.page, context.tab) &&
                <button className={`block btn-circled-40 desktop row center w-flex`} data-color={`transparent`} onClick={() => {
                    setClose(true);
                    if (typeof context.setTabRaw == `function` && typeof context.setPageRaw == `function`) {
                        context.setTabRaw(0);
                        context.setPageRaw(0);
                    } else {
                        navigate(`/profile/me`);
                    }
                }} style={{paddingLeft: 14, paddingRight: 14}}>
                    <p><FormattedMessage id="back_to_profile"/></p>
                </button>}
            </div>
        </>} show={props.show} setShow={props.setShow} close={close}/>
    )
}

const PopupSubscriptionMobile = (props: any) => {
    const [ close, setClose ] = useState(false);
    const context = useContext(ProfileContext);
    const navigate = useNavigate();

    const [ waitResponse, setWaitResponse ] = useState<boolean>(false);

    useEffect(() => {
        setClose(!props.show);
    }, [props.show]);

    return (
        <PopUp blurBg={true} preventClose={IsClosedBlock(context.page, context.tab)} content={<>
            <p data-size={4} className={`semibold seminarrow center mb-2`}>
                {`Your subscription ${context.profile?.subscription?.is_stripe_customer ? "has expired" : "is not active"}`}
            </p>
            <p data-size={5} className={`center mb-3`}><FormattedMessage id="hope_back"/></p>

            <button className={`block btn-rectangle-40 mobile row center w-flex mb-1`} data-color={`black`} onClick={() => {
                setWaitResponse(true);
                OpenStripePortal(
                    context.profile?.subscription?.is_stripe_customer, 
                    () => { setWaitResponse(false) },
                    undefined, 
                    context.profile?.subscription?.is_subscription_active ? '2m' : undefined
                );
            }}>
                {waitResponse ?
                <Loading color={`white`} width={24} height={24}/> :
                <p><FormattedMessage id="activate_subscription"/></p>}
            </button>
            {IsClosedBlock(context.page, context.tab) &&
            <button className={`block btn-circled-40 mobile row center w-flex`} data-color={`transparent`} onClick={() => {
                setClose(true);
                if (typeof context.setTabRaw == `function` && typeof context.setPageRaw == `function`) {
                    context.setTabRaw(0);
                    context.setPageRaw(0);
                } else {
                    navigate(`/profile/me`);
                }
            }}>
                <p data-color={`black`}><FormattedMessage id="go_to_profile"/></p>
            </button>}
        </>} show={props.show} setShow={props.setShow} close={close} setClose={setClose}/>
    )
}

const InfoErrorPopup = (props: any) => {
    const [ close, setClose ] = useState(false);

    useEffect(() => {
        setClose(!props.show);
    }, [props.show]);

    return (
        <PopUp black={true} content={<>                       
            <div className={`row left mb-2 top noscroll nogap nowrap noshrink`}>
                <p data-size={5} className={'semibold'} data-color={`white`}>{props.title}</p>
            </div>

            <button className={`block btn-circled-${!props.desktop ? '32 mobile' : '40 desktop'} row`} data-color={`green`}
                    onClick={() => {
                        setClose(true);
                        setTimeout(() => {
                            props.onOk()
                        }, 500);                       
                    }}>
                <p>{props.buttonTitle}</p>
            </button>
        </>} _id={props._id} show={props.show} setShow={props.setShow} close={close} setClose={setClose}/>
    )
}

const PopupCenterWelcome = (props: any) => {
    const [ close, setClose ] = useState(false);

    useEffect(() => {
        setClose(!props.show);
    }, [props.show]);

    return (
        <PopUp onlyBg={true} customZIndex={202} blurBg={true} transparentBg={true} content={<>
            <div className={`block p-4 b-3 w-9 center-popup column top`} data-color={`blue`} onClick={e => { e.stopPropagation() }}>
                <p data-size={4} className={`semibold seminarrow mb-3`}><FormattedMessage id="welcome_osmos"/></p>
                <p data-size={5} className={`center mb-3`}>
                    <FormattedMessage id="welcome_message_1"/><br/>
                    <FormattedMessage id="welcome_message_2"/>
                </p>

                <button className={`block btn-rectangle-40 desktop row center w-flex`} onClick={() => {
                    setClose(true);
                }} style={{width: 153}}>
                    <p><FormattedMessage id="got_it"/></p>
                </button>
            </div>
        </>} show={props.show} setShow={props.setShow} close={close}/>
    )
}

const PopupWelcomeMobile = (props: any) => {
    const [ close, setClose ] = useState(false);

    useEffect(() => {
        setClose(!props.show);
    }, [props.show]);

    return (
        <PopUp blurBg={true} blue={true} transparentBg={true} content={<>
            <p data-size={4} data-color={`white`} className={`center semibold seminarrow mb-3`}>
                <FormattedMessage id="welcome_osmos"/>
            </p>
            <p data-size={5} data-color={`white`} className={`center mb-3`}>
                <FormattedMessage id="welcome_message_1"/><br/>
                <FormattedMessage id="welcome_message_2"/>
            </p>

            <div className={`row center w-flex`}>
                <button className={`block btn-rectangle-40 desktop row center w-flex`} onClick={() => {
                    setClose(true);
                }} style={{width: 153}}>
                    <p><FormattedMessage id="got_it"/></p>
                </button>
            </div>
        </>} show={props.show} setShow={props.setShow} close={close} setClose={setClose}/>
    )
}

const PopupCenterBecomeAMember = (props: any) => {
    const [ close, setClose ] = useState(false);
    const [ waitStripe, setWaitStripe ] = useState(false);
    const [ cookie ] = useCookies(["osp"]);

    const [ version, setVersion ] = useState<number>();

    useEffect(() => {
        setVersion(
            cookie["osp"] === "p_1" ? 1 :
            cookie["osp"] === "p_2" ? 2 :
            cookie["osp"] === "p_3" ? 3 :
            cookie["osp"] === "p_4" ? 4 :
            cookie["osp"] === "p_5" ? 5 : 
            cookie["osp"] === "p_6" ? 6 :           
            1
        );
    }, [])

    useEffect(() => {
        setClose(!props.show);
    }, [props.show]);

    return (
        <PopUp onlyBg={true} customZIndex={202} blurBg={true} preventClose={true} content={
        <div className={`grid-5-9 center-popup`} onClick={e => { e.stopPropagation() }}
             style={{width: 768}}>
            <div className={`block p-3 bl-4 h-flex column left`} data-color={`blue`}>
                <AnimatedLogo color={`white`} local={true} size={54}/>
                <div className={`block b-2 w-flex row center py-3`}>
                    {version === 1 &&
                    <p data-size={9} className={`semibold seminarrow`} data-color={`blue`}>
                        {`$19.99`}
                        <span data-size={8}>
                            {` / month`}
                        </span>
                    </p>}

                    {version === 2 && <div>
                        <p data-size={9} className={`semibold seminarrow mb`} data-color={`blue`}>
                            {`$1.99`}
                            <span data-size={8}>
                                {`\u00A0/\u00A0first month`}
                            </span><br/>
                        </p>
                        <p data-size={8} style={{opacity: 0.5}} className={`semibold seminarrow`} data-color={`blue`}>
                            {`Renews monthly at $19.99`}
                        </p>
                    </div>}

                    {version === 3 && <div>
                        <p data-size={9} className={`semibold seminarrow mb`} data-color={`blue`}>
                            {`$1.99`}
                            <span data-size={8}>
                                {`\u00A0/\u00A0first month`}
                            </span><br/>
                        </p>
                        <p data-size={8} style={{opacity: 0.5}} className={`semibold seminarrow`} data-color={`blue`}>
                            {`Renews monthly at $99`}
                        </p>
                    </div>}

                    {version === 4 && <div>
                        <p data-size={9} className={`semibold seminarrow mb`} data-color={`blue`}>
                            {`$29.99`}
                            <span data-size={8}>
                                {`\u00A0/\u00A0first month`}
                            </span><br/>
                        </p>
                        <p data-size={8} style={{opacity: 0.5}} className={`semibold seminarrow`} data-color={`blue`}>
                            {`Renews monthly at $59.99`}
                        </p>
                    </div>}

                    {version === 5 && <div>
                        <p data-size={9} className={`semibold seminarrow mb`} data-color={`blue`}>
                            {`$39.99`}
                            <span data-size={8}>
                                {`\u00A0/\u00A0first month`}
                            </span><br/>
                        </p>
                        <p data-size={8} style={{opacity: 0.5}} className={`semibold seminarrow`} data-color={`blue`}>
                            {`Renews monthly at $59.99`}
                        </p>
                    </div>}

                    {version === 6 && <div>
                        <p data-size={9} className={`semibold seminarrow mb`} data-color={`blue`}>
                            {`$9.99`}
                            <span data-size={8}>
                                {`\u00A0/\u00A0first month`}
                            </span><br/>
                        </p>
                        <p data-size={8} style={{opacity: 0.5}} className={`semibold seminarrow`} data-color={`blue`}>
                            {`Renews monthly at $19.99`}
                        </p>
                    </div>}
                </div>
            </div>
            <div className={`block p-3 br-4`}>
                <div className="row top w-flex nowrap gap-2 mb">
                    <p data-size={9} className={`semibold seminarrow`}>
                        {version === 1 ? <FormattedMessage id="become_member"/> : `Special introductory offer`}
                    </p>
                    <a className={`btn desktop row center`} 
                        href={"/profile/match"} data-color={"transparent"}> 
                        <img src={PlusBlack} className="w-2" style={{
                            transform: "rotate(45deg)", opacity: 0.6,
                        }} alt=""></img>
                    </a>
                </div>
                <p data-size={5} className={`mb-3`} data-color={`black-40-opacity`}>
                    {`Becoming a paid member signifies your`}<br/>{`serious intentions, respecting everyone's time.`}
                </p>
                <p data-size={5} className={`mb-2 pt-1`}>
                    {`3 000 professionals from AI, VC, FinTech, MarTech, Education, Media, E-commerce, Web3, and more`}
                </p>

                {[
                    `Discover new ideas and insights.`,
                    `Get support.`,
                    `Find opportunities for collaboration and professional development.`,
                ].map((elem: string, index: number) => {return (
                    <div key={index} className={`row left gap-2 nowrap px-2 mb-2`}>
                        <img src={StarYellow} className={`w-3 h-3`} alt={``}></img>
                        <p data-size={5}>{elem}</p>
                    </div>
                )})}
                <p data-size={5} className={`pb-1`}>
                    {`Connect with people who inspire and help each other.`}
                </p>

                <button className={`block btn-flex desktop high row center w-flex mb-1 mt-3`} onClick={() => {
                    setWaitStripe(true);
                    OpenStripePortal(false, () => { setWaitStripe(false) }, `/profile/match?first_login=true&welcome=true`, version)
                }} data-color={`black`}>
                    {waitStripe ? <Loading width={24} height={24} color={`white`}/> : 
                    <p><FormattedMessage id="become_member"/></p>}
                </button>
                <p data-size={8} data-color={`black-40-opacity`}>
                    {`Subscriptions renew automatically. Cancel anytime.`}
                </p>
            </div>
        </div>} show={props.show} setShow={props.setShow} close={close}/>
    )
}

const PopupBecomeAMemberMobile = (props: any) => {
    const [ close, setClose ] = useState(false);
    const [ waitStripe, setWaitStripe ] = useState(false);
    const [ cookie ] = useCookies(["osp"]);

    const [ version, setVersion ] = useState<number>();

    useEffect(() => {
        setVersion(
            cookie["osp"] === "p_1" ? 1 :
            cookie["osp"] === "p_2" ? 2 :
            cookie["osp"] === "p_3" ? 3 :
            cookie["osp"] === "p_4" ? 4 : 
            cookie["osp"] === "p_5" ? 5 :   
            cookie["osp"] === "p_6" ? 6 :          
            1
        );
    }, [])

    useEffect(() => {
        setClose(!props.show);
    }, [props.show]);

    return (
        <PopUp blurBg={true} onlyBg={true} preventClose={true} content={<div className={`w-screen h-screen block p-3 neg-my-3 y-auto centered`}
        style={{position: `relative`}} data-color={`blue`}>
            {/* <button className={`btn-symbol filled centered w-3 h-3 close`} onClick={() => { setClose(true) }}
                    style={{position: `absolute`, top: 24, right: 24}}>
                <img src={PlusWhite} alt={`+`}></img>
            </button> */}
            <div className={`w-flex`}>
                <p style={{fontSize: version === 1 ? 32 : 24}} className={`center semibold seminarrow mb-1`}>
                    {version === 1 ? <FormattedMessage id="become_member"/> : `Special introductory offer`}
                </p>
                {/* <p data-size={4} className={`semibold seminarrow center`}>
                    {`20$`}
                    <span data-size={5} data-color={`white-40-opacity`}>
                        {` / month`}
                    </span>
                </p> */}

                <p style={{fontSize: 20}} className={`center mb-3`}>
                    {`Becoming a paid member signifies your serious intentions, respecting everyone's time.`}
                </p>
                {[
                    `Join over 3 000 members from top teams`,
                    `Get 10 AI-suggested matches for each business request you make`,
                    `Make as many requests as you wish each day`,
                    `Reach your business goals by collaborating with other founders, VCs, and professionals`,
                ].map((elem: string, index: number) => {return (
                    <div key={index} className={`row left gap-2 nowrap px-1 mb-2`}>
                        <img src={StarYellow} className={`w-3 h-3`} alt={``}></img>
                        <p data-size={8}>{elem}</p>
                    </div>
                )})}

                {version === 1 &&
                <p data-size={4} className={`semibold seminarrow center`}>
                    {`$19.99`}
                    <span data-size={4}>
                        {` / month`}
                    </span>
                </p>}

                {version === 2 && <div className={`row center w-flex`}>
                    <p data-size={5} className={`semibold seminarrow mb`}>
                        {`$1.99`}
                        <span data-size={5}>
                            {`\u00A0\u00A0\u00A0/\u00A0\u00A0\u00A0first month`}
                        </span><br/>
                    </p>
                    <p data-size={5} data-color={`white-40-opacity`} className={`semibold seminarrow`}>
                        {`Renews monthly at $19.99`}
                    </p>
                </div>}

                {version === 3 && <div className={`row center w-flex`}>
                    <p data-size={5} className={`semibold seminarrow mb`}>
                        {`$1.99`}
                        <span data-size={5}>
                            {`\u00A0\u00A0\u00A0/\u00A0\u00A0\u00A0first month`}
                        </span><br/>
                    </p>
                    <p data-size={5} data-color={`white-40-opacity`} className={`semibold seminarrow`}>
                        {`Renews monthly at $99`}
                    </p>
                </div>}

                {version === 4 && <div className={`row center w-flex`}>
                    <p data-size={5} className={`semibold seminarrow mb`}>
                        {`$29.99`}
                        <span data-size={5}>
                            {`\u00A0\u00A0\u00A0/\u00A0\u00A0\u00A0first month`}
                        </span><br/>
                    </p>
                    <p data-size={5} data-color={`white-40-opacity`} className={`semibold seminarrow`}>
                        {`Renews monthly at $59.99`}
                    </p>
                </div>}

                {version === 5 && <div className={`row center w-flex`}>
                    <p data-size={5} className={`semibold seminarrow mb`}>
                        {`$39.99`}
                        <span data-size={5}>
                            {`\u00A0\u00A0\u00A0/\u00A0\u00A0\u00A0first month`}
                        </span><br/>
                    </p>
                    <p data-size={5} data-color={`white-40-opacity`} className={`semibold seminarrow`}>
                        {`Renews monthly at $59.99`}
                    </p>
                </div>}

                {version === 6 && <div className={`row center w-flex`}>
                    <p data-size={5} className={`semibold seminarrow mb`}>
                        {`$9.99`}
                        <span data-size={5}>
                            {`\u00A0\u00A0\u00A0/\u00A0\u00A0\u00A0first month`}
                        </span><br/>
                    </p>
                    <p data-size={5} data-color={`white-40-opacity`} className={`semibold seminarrow`}>
                        {`Renews monthly at $19.99`}
                    </p>
                </div>}

                {/* <p data-size={4} className={`semibold seminarrow center mt-2`}>
                    {`$20`}
                    <span data-size={4} data-color={`white-40-opacity`}>
                        {` / month`}
                    </span>
                </p> */}

                <button className={`block btn-flex mobile row center w-flex mt-3 mb-1`} onClick={() => {
                    setWaitStripe(true);
                    OpenStripePortal(false, () => { setWaitStripe(false) }, `/profile/match?first_login=true&welcome=true`, version)
                }}>
                    {waitStripe ? <Loading width={24} height={24} color={`black`}/> : 
                    <p><FormattedMessage id="become_member"/></p>}
                </button>
                <a className={`block btn-circled-32 mobile row center w-flex mb-2`} href={"/profile/match"} data-color={"transparent"}>
                    <p><FormattedMessage id="skip_and_to_service"/></p>
                </a>
                <p data-size={6} data-color={`white-40-opacity`} className={`center`}>
                    {`Subscriptions renew automatically.`}<br/>{`Cancel anytime.`}
                </p>
            </div>
        </div>} show={props.show} setShow={props.setShow} close={close} setClose={setClose}/>
    )
}

const PopupCenterBecomeAMember2 = (props: any) => {
    const [ close, setClose ] = useState(false);
    const [ waitStripe, setWaitStripe ] = useState(false);

    useEffect(() => {
        setClose(!props.show);
    }, [props.show]);

    return (
        <PopUp onlyBg={true} customZIndex={202} blurBg={true} preventClose={false} content={
        <div className={`grid-5-9 center-popup`} onClick={e => { e.stopPropagation() }}
             style={{width: 768}}>
            <div className={`block p-3 bl-4 h-flex column left`} data-color={`blue`}>
                <AnimatedLogo color={`white`} local={true} size={40}/>
                <div className={`block b-2 w-flex row center py-3 px-2`}>
                    <div>
                        <p data-size={4} className={`semibold seminarrow mb`} data-color={`blue`}>
                            {`$1.99`}
                            <span data-size={8}>
                                {`\u00A0/\u00A0first month`}
                            </span><br/>
                        </p>
                        <p data-size={8} style={{opacity: 0.5}} className={`semibold seminarrow`} data-color={`blue`}>
                            {`Renews monthly at $19.99`}
                        </p>
                    </div>
                </div>
            </div>
            <div className={`block p-3 br-4`}>
                <p data-size={9} className={`semibold seminarrow mb`}>
                    {`The New Osmos Experience`}
                </p>
                <p data-size={5} className={`mb-2`} data-color={`black-40-opacity`}>
                    {`Becoming a paid member signifies your`}<br/>{`serious intentions, respecting everyone's time.`}
                </p>
                <p data-size={5} className={`mb-2 pt-1`}>
                    {`Each week, you'll be matched with one industry peer for a deep 1:1 conversation. This approach ensures that you get to have engaging, thoughtful discussions that provide real value.`}
                </p>

                <button className={`block btn-flex desktop high row center w-flex mb-1 mt-5`} onClick={() => {
                    setWaitStripe(true);
                    OpenStripePortal(false, () => { setWaitStripe(false) }, `/profile/match?first_login=true&welcome=true`, "2")
                }} data-color={`black`}>
                    {waitStripe ? <Loading width={24} height={24} color={`white`}/> : 
                    <p><FormattedMessage id="become_member"/></p>}
                </button>
                <p data-size={8} data-color={`black-40-opacity`}>
                    {`Subscriptions renew automatically. Cancel anytime.`}
                </p>
            </div>
        </div>} show={props.show} setShow={props.setShow} close={close}/>
    )
}

const PopupBecomeAMemberMobile2 = (props: any) => {
    const [ close, setClose ] = useState(false);
    const [ waitStripe, setWaitStripe ] = useState(false);

    useEffect(() => {
        setClose(!props.show);
    }, [props.show]);

    return (
        <PopUp blurBg={true} onlyBg={true} preventClose={true} content={<div className={`w-screen h-screen block p-3 neg-my-3 y-auto centered`}
        style={{position: `relative`}} data-color={`blue`}>
            {/* <button className={`btn-symbol filled centered w-3 h-3 close`} onClick={() => { setClose(true) }}
                    style={{position: `absolute`, top: 24, right: 24}}>
                <img src={PlusWhite} alt={`+`}></img>
            </button> */}
            <div className={`w-flex`}>
                <p style={{fontSize: 32}} className={`center semibold seminarrow mb-1`}>
                    {`The New Osmos Experience`}
                </p>
                {/* <p data-size={4} className={`semibold seminarrow center`}>
                    {`20$`}
                    <span data-size={5} data-color={`white-40-opacity`}>
                        {` / month`}
                    </span>
                </p> */}

                <p style={{fontSize: 20}} className={`center mb-3`}>
                    {`Becoming a paid member signifies your serious intentions, respecting everyone's time.`}
                </p>

                <p data-size={5} className={`center mb-3`} data-color={`white-40-opacity`}>
                    {`Each week, you'll be matched with one industry peer for a deep 1:1 conversation. This approach ensures that you get to have engaging, thoughtful discussions that provide real value.`}
                </p>

                <div className={`row center w-flex`}>
                    <p data-size={5} className={`semibold seminarrow mb`}>
                        {`$1.99`}
                        <span data-size={5}>
                            {`\u00A0/\u00A0first month`}
                        </span><br/>
                    </p>
                    <p data-size={5} data-color={`white-40-opacity`} className={`semibold seminarrow`}>
                        {`Renews monthly at $19.99`}
                    </p>
                </div>

                {/* <p data-size={4} className={`semibold seminarrow center mt-2`}>
                    {`$20`}
                    <span data-size={4} data-color={`white-40-opacity`}>
                        {` / month`}
                    </span>
                </p> */}

                <button className={`block btn-flex mobile row center w-flex mt-3 mb-2`} onClick={() => {
                    setWaitStripe(true);
                    OpenStripePortal(false, () => { setWaitStripe(false) }, `/profile/match?first_login=true&welcome=true`, "2")
                }}>
                    {waitStripe ? <Loading width={24} height={24} color={`black`}/> : 
                    <p><FormattedMessage id="become_member"/></p>}
                </button>
                <p data-size={6} data-color={`white-40-opacity`} className={`center`}>
                    {`Subscriptions renew automatically.`}<br/>{`Cancel anytime.`}
                </p>
            </div>
        </div>} show={props.show} setShow={props.setShow} close={close} setClose={setClose}/>
    )
}

const PopupCenterAddAboutAvatar= (props: any) => {
    const [ close, setClose ] = useState(false);
    const globalContext = useContext(GlobalContext);

    useEffect(() => {
        setClose(!props.show);
    }, [props.show]);

    return (
        <PopUp onlyBg={true} customZIndex={202} blurBg={true} preventClose={false} content={
            <div className={`block p-4 b-4 w-10`}>
                <p data-size={4} className={`semibold seminarrow mb-3`}>
                    <FormattedMessage id="profile_add_info_title"/>
                </p>

                <div className="row nowrap top gap-2 mb-3">
                    <div>
                        <div className="block w-6 h-6 b-2 row center noshrink" data-color={'black-20-opacity'}>
                            <img src={HumanWhite} alt={""} className="w-4"></img>
                        </div>
                        <div className="block h-3 w-6 b-2 mt-2" data-color={'black-10-opacity'}></div>
                    </div>
                    <div className="w-flex">
                        <div className="row left nowrap mb-2">
                            <div className="block h-3 w-6 b-2" data-color={'black-10-opacity'}></div>
                            <div className="block h-3 w-6 b-2" data-color={'black-10-opacity'}></div>
                            <div className="block h-3 w-6 b-2" data-color={'black-10-opacity'}></div>
                        </div>
                        <div className="w-flex b-2 h-6 block p-2" data-color={'black-20-opacity'}>
                            <div className="block h-1 w-flex b-2 mb-1" data-color={'white'}></div>
                            <div className="block h-1 w-flex b-2 mb-1" data-color={'white'}></div>
                            <div className="block h-1 w-flex b-2 mb-1" data-color={'white'}></div>
                            <div className="block h-1 col-8 b-2" data-color={'white'}></div>
                        </div>
                    </div>
                </div>  

                <p data-size={5}>
                    <FormattedMessage id="profile_add_info_hint"/>
                </p>              

                <a className={`block btn-flex desktop high row center w-flex mt-3`} href={"/profile/edit"} data-color={`black`} onClick={() => {
                    setClose(true);
                }}>
                    <p><FormattedMessage id="profile_add_info_btn"/></p>
                </a>
            </div>} show={props.show} setShow={props.setShow} close={close} setClose={setClose}/>
    )
}

const PopupAddAboutAvatarMobile= (props: any) => {
    const [ close, setClose ] = useState(false);
    const globalContext = useContext(GlobalContext);

    useEffect(() => {
        setClose(!props.show);
    }, [props.show]);

    return (
        <PopUp blurBg={true} preventClose={false} content={<>
            <p data-size={4} className={`semibold seminarrow mb-3`}>
                <FormattedMessage id="profile_add_info_title"/>
            </p>

            <div className="row nowrap top gap-2 mb-3">
                <div>
                    <div className="block w-6 h-6 b-2 row center noshrink" data-color={'black-20-opacity'}>
                        <img src={HumanWhite} alt={""} className="w-4"></img>
                    </div>
                    <div className="block h-3 w-6 b-2 mt-2" data-color={'black-10-opacity'}></div>
                </div>
                <div className="w-flex">
                    <div className="row left nowrap mb-2">
                        <div className="block h-3 w-5 b-2" data-color={'black-10-opacity'}></div>
                        <div className="block h-3 w-5 b-2" data-color={'black-10-opacity'}></div>
                    </div>
                    <div className="w-flex b-2 h-6 block p-2" data-color={'black-20-opacity'}>
                        <div className="block h-1 w-flex b-2 mb-1" data-color={'white'}></div>
                        <div className="block h-1 w-flex b-2 mb-1" data-color={'white'}></div>
                        <div className="block h-1 w-flex b-2 mb-1" data-color={'white'}></div>
                        <div className="block h-1 col-8 b-2" data-color={'white'}></div>
                    </div>
                </div>
            </div>  

            <p data-size={5}>
                <FormattedMessage id="profile_add_info_hint"/>
            </p>              

            <a className={`block btn-circled-40 mobile high row center w-flex mt-3`} href={"/profile/edit"} 
                data-color={`black`} onClick={() => {
                setClose(true);
            }}>
                <p><FormattedMessage id="profile_add_info_btn"/></p>
            </a>
        </>} show={props.show} setShow={props.setShow} close={close} setClose={setClose}/>
    )
}

const EditColorPopup = (props: any) => {
    const [ close, setClose ] = useState(false);

    useEffect(() => {
        setClose(!props.show);
    }, [props.show]);

    return (
        <PopUp content={<>
            <p data-size={5} data-color={`black`} className={`semibold mb-2`}><FormattedMessage id="edit_request_choose_color"/></p>
            <div className={`row left gap-1 w-flex`}>
                {[
                    `green`,
                    `light-blue`,
                    `pink`,
                    `yellow`,
                    `blue`,
                    `coral`,
                    `light-pink`,
                    `light-yellow`,
                    `gray`,
                    `dark-gray`,
                ].map((elem, index) => {return (
                    <div className={`block b-1`} data-color={elem} style={{
                        width: (document.documentElement.clientWidth - 32 - 80) / 5,
                        height: (document.documentElement.clientWidth - 32 - 80) / 5,
                        overflow: `visible`,
                    }} onClick={() => {
                        if (props.setColor) {
                            props.setColor(elem);
                        }
                    }}>
                        {props.color === elem && 
                            <div className={`neg-m`} style={{                                
                                width: (document.documentElement.clientWidth - 32 - 80) / 5 + 8,
                                height: (document.documentElement.clientWidth - 32 - 80) / 5 + 8,
                                border: `solid 2px rgba(0, 0, 0, 0.4)`,
                                borderRadius: 10,
                            }}></div>
                        }
                    </div>
                )})}
            </div>
        </>} show={props.show} setShow={props.setShow} close={close}/>
    )
}

const EditColorPopupDesktop = (props: any) => {
    const [ close, setClose ] = useState(false);

    useEffect(() => {
        setClose(!props.show);
    }, [props.show]);

    return (
        <PopUp black={true} content={<>
            <p data-size={5} data-color={`white`} className={`semibold mb-2`}><FormattedMessage id="edit_request_choose_color"/></p>
            <div className={`row left gap-1 w-flex`}>
                {[
                    `green`,
                    `light-blue`,
                    `pink`,
                    `yellow`,
                    `blue`,
                    `coral`,
                    `light-pink`,
                    `light-yellow`,
                    `gray`,
                    `dark-gray`,
                ].map((elem, index) => {return (
                    <div className={`block b-1 w-6`} data-color={elem} style={{
                        width: 52,
                        height: 52,
                        overflow: `visible`,
                    }} onClick={() => {
                        if (props.setColor) {
                            props.setColor(elem);
                        }
                    }}>
                        {props.color === elem && 
                            <div className={`neg-m`} style={{                                
                                width: 60,
                                height: 60,
                                border: `solid 2px rgba(255, 255, 255, 0.4)`,
                                borderRadius: 10,
                            }}></div>
                        }
                    </div>
                )})}
            </div>
        </>} show={props.show} setShow={props.setShow} close={close}/>
    )
}

const ProfilePopup = (props: any) => {
    const context = useContext(ProfileContext);

    const [ close, setClose ] = useState<boolean>(false);
    const [ show, setShow ] = useState<boolean>(true);

    useEffect(() => {
        if (!show) {
            props.setUser(null);
        }
    }, [show])

    return (<ProfileContext.Provider value={{
            ...context, 
            profile: props.user, 
            setProfile: props.setUser,
            setClose: setClose,
        }}>
        <PopUpRight content={<div className={`block px-5 py-4`} data-color={`light-gray`} style={{
            paddingLeft: 20, 
            width: 804,
            minWidth: 804,
            minHeight: `100%`,
        }}> 
            <div className={`row right`} style={{
                position: `sticky`, top: 38, zIndex: 2,
            }}>
                <button className={`btn-close neg-ml-3 centered neg-mb-3`} onClick={() => {
                        setClose(true);
                    }} id={`close-popup-btn`} style={{zIndex: 1}}> 
                    <div className={`btn-symbol w-3 h-3 filled close centered hover`}>
                        <img src={PlusWhite} alt={`+`}></img>
                    </div>
                </button>
            </div>
            <ProfilePageDesktop loaded={true} other={true} isNarrow={true} 
                                onSubscribe={props.onSubscribe} 
                                onStartChat={typeof props.user?.onStartChat === `function` ? () => {
                                    props.user?.onStartChat();
                                } : null}/> 
        </div>} show={show} setShow={setShow} close={close}/>
    </ProfileContext.Provider>)
}

const ProfilePopupMobile = (props: any) => {
    const context = useContext(ProfileContext);

    const [ close, setClose ] = useState<boolean>(false);
    const [ show, setShow ] = useState<boolean>(true);

    useEffect(() => {
        if (!show) {
            props.setUser(null);
        }
    }, [show])

    return (<ProfileContext.Provider value={{
            ...context, 
            profile: props.user, 
            setProfile: props.setUser,
            setClose: setClose,
        }}>
        <PopUp content={<div className={`block neg-mx-3 neg-my-4 w-flex-p-3 y-auto`} data-color={`light-gray`}
                             style={{height: 500}}> 
            <div className={`mb-4`} id={`close-popup-btn`} onClick={() => {
                        setClose(true);
                    }} >
            </div>
            <ProfilePageMobile loaded={true} other={true} isNarrow={true}
                               onSubscribe={props.onSubscribe} 
                               onStartChat={typeof props.user?.onStartChat === `function` ? () => {
                                    props.user?.onStartChat();
                               } : null}/> 
        </div>} show={show} setShow={setShow} close={close} setClose={setClose}/>
    </ProfileContext.Provider>)
}

const PopupHintMobile = (props: any) => {
    const [ close, setClose ] = useState(false);

    useEffect(() => {
        setClose(!props.show);
    }, [props.show]);

    useEffect(() => {
        if (close) {
            if (typeof props.onClick === `function`) {
                props.onClick();
            }
            if (props.close) {
                setClose(true);
            }
        }
    }, [close])

    return (
        <PopUp content={<>                       
            <p data-size={5} className={`semibold seminarrow mb-1`}>{props.title}</p>
            
            {!props.description_type ?
            <p data-size={6}>{props.description}</p> :
            props.description}

            {props.hint &&
            <div className={`block w-flex b-2 p-2 mt-2`} data-color={`light-green`}>
                <p data-size={6} data-color={`dark-green`}>{props.hint}</p>    
            </div>}
            
            <button className={`block btn-circled-40 mobile row center mt-2 min-w-4`} data-color={`green`}
                    onClick={e => {
                        if (typeof props.onClick === `function`) {
                            props.onClick();
                        }
                        if (props.close) {
                            setClose(true);
                        }                      
                    }}>
                <p>{props.buttonTitle}</p>
            </button>
        </>} show={props.show} setShow={props.setShow} close={close} setClose={setClose} hideBg={props.hideBg}/>
    )
}

export {
    PopUp,
    PopUpRight,
    PopupRightSettings,
    PopupBottomSettings,
    AddProjectPopup,
    SelectPopup,
    SelectPopupRight,
    SelectSeveralPopup,
    SelectSeveralPopupRight,
    ConfirmPopup,
    InfoPopup,
    GradePopup,
    AddProjectDesktop,
    InfoErrorPopup,
    SettingsPopupRight,
    GradePopupCenter,
    PopupCenter,
    PopupCenterCanHelp,
    PopupCanHelpMobile,
    PopupCenterCanHelp2,
    PopupCanHelpMobile2,
    PopupCenterCanHelpLanding,
    PopupCanHelpMobileLanding,
    PopupCenterInvite,
    PopupInviteMobile,
    PopupCenterChangeEmail,
    PopupCenterUnsubscribe,
    PopupUnsubscribeMobile,
    PopupCenterLogOut,
    PopupLogOutMobile,
    PopupCenterSignIn,
    PopupSignInMobile,
    PopupCenterSuggestAFriend,
    PopupSuggestAFriendMobile,
    EditColorPopup,
    EditColorPopupDesktop,
    ProfilePopup,
    ProfilePopupMobile,
    AddRequestPopup,
    AddRequestDesktop,
    PopupHintMobile,
    NewEventsPopup,
    NewMatchPopupMobile,
    PopupNotifications,
    PopupCenterUpdatePrivacy,
    PopupUpdatePrivacyMobile,
    FirstOfferPopup,
    FirstOfferPopupMobile,
    PopupCenterSubscription,
    PopupSubscriptionMobile,
    PopupCenterWelcome,
    PopupWelcomeMobile,
    PopupCenterBecomeAMember,
    PopupBecomeAMemberMobile,
    PopupCenterBecomeAMember2,
    PopupBecomeAMemberMobile2,
    PopupCenterDelete,
    PopupCenterAddAboutAvatar,
    PopupAddAboutAvatarMobile,
}
