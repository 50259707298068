import React, { HTMLAttributes, useContext, useEffect, useRef, useState } from "react";
import { CheckLatin, CommunityLogo, DescriptionLevel, EmailRegExp, Industries, MergeArrays, ProfileStyles, SetDict, Sticky } from "../elements/Data";
import { 
    AngleRightBlue,
    FacebookBlack, 
    LinkedInBlack, 
    PhoneBlack, 
    PlusBlack,  
    PlusGreen, 
    PlusWhite, 
    TelegramBlack,
    TrashCoral,
    XBlack, 
} from "../elements/Imgs";
import { ProfileUpdate } from "../Requests";
import {  
    Loading, 
    NewAvatar, 
    Edit,
} from "../elements/Elements.jsx";
import { 
    InfoErrorPopup, 
    InfoPopup,
    PopupCenterChangeEmail,  
} from "../elements/Popups";
import { GlobalContext, ProfileContext } from "../elements/Contexts";
import { FormattedMessage } from "react-intl";
import { messages } from "../../i18n/messages";

const EditProfileDesktop = (props: any) => {
    const context = useContext(ProfileContext);
    const globalContext = useContext(GlobalContext);

    const [ isInvalid, setIsInvalid ] = useState<boolean>(false);
    const [ waitUpdate, setWaitUpdate ] = useState<boolean>(false);
    // const [ addLang, setAddLang ] = useState<boolean>(false);  
    // const [ newLang, setNewLang ] = useState<string>('');   
    const [ addIndustry, setAddIndustry ] = useState<boolean>(false);  
    const [ newIndustry, setNewIndustry ] = useState<string>('');    
    const [ editOkPopup, setEditOkPopup ] = useState<boolean>(false);
    const [ errorPopup, setErrorPopup ] = useState<boolean>(false);
    const [ confirmEmailPopup, setConfirmEmailPopup ] = useState<boolean>(false);

    const [ initEmail, setInitEmail ] = useState<string>(props.profile?.links?.email);

    const [ avatar_, setAvatar_ ] = useState<File | null>(null);
    const [ avatarUrl, setAvatarUrl ] = useState<string>('')

    const [ profile_, setProfile_ ] = useState<any>(props.profile); 
    const [ loaded, setLoaded ] = useState<boolean>(false);
    
    const avatarScrollElem = useRef<HTMLDivElement | null>(null);
    const avatarScrollElem2 = useRef<HTMLDivElement | null>(null);
    const [ showLeftAvatarBtn, setShowLeftAvatarBtn ] = useState<boolean>(false);
    const [ showRightAvatarBtn, setShowRightAvatarBtn ] = useState<boolean>(false);
    const popularIndustries = [
        "Impact", 
        "Tech", 
        "AI", 
        "Media/Content", 
        "Future of Work", 
        "Education", 
        "FinTech", 
        "E-commerce", 
        "MarketingTech", 
        "Web3", 
        "Social Networks", 
        "Consulting", 
    ]

    const hideIndustryList = () => {
        setNewIndustry(''); 
        setAddIndustry(false);
    }

    useEffect(() => {
        setAvatarUrl(avatar_ ? URL.createObjectURL(avatar_) : ``);
    }, [avatar_])

    useEffect(() => {
        if (!avatarUrl && !props?.profile?.avatar?.link && props?.profile?.avatar?.style < 2) {
            setProfile_((val: any) => SetDict(val, ['avatar', 'style'], 2))
        } else {
            setProfile_((val: any) => SetDict(val, ['avatar', 'style'], 0))
        }   
    }, [avatarUrl, props.profile])
    
    useEffect(() => {
        if (props.profile) {  
            setAvatarUrl(avatar_ ? URL.createObjectURL(avatar_) : props.profile?.avatar?.link) 
            setProfile_(props.profile);
            setLoaded(true);
            setInitEmail(val => val ?? props.profile?.links?.email); 
        }
    }, [props.profile])

    useEffect(() => {
        context.setIsEdit(true);
        setIsInvalid(false);

        if (CommunityLogo[globalContext.community]?.redirect_on_edit) {
            window.location.href = CommunityLogo[globalContext.community]?.redirect_on_edit;
        }

        return () => {
            context.setIsEdit(false);
        }
    }, [])

    useEffect(() => {
        console.warn(profile_, profile_?.industry)
    }, [profile_?.industry])

    return (<>
    <div className={`w-flex column pb-6`}>
        {loaded ? <>
        <div data-invalid-form={isInvalid} className={`w-10 sm-w-flex`}>    
            <div className={`block block p-3 mb-3 b-3`}>
                <p data-size={8} className={` semibold mb-2`}>
                    <FormattedMessage id="ob_photo_header"/>*
                </p>
                <div id={`bg-img`} className={`row top block b-2 mb-3 w-8 ${avatarUrl ? "valid" : "invalid"}`} data-color={`light-gray`}
                     style={{position: `relative`, overflow: `hidden`}}>
                    <input id={`input-img`} className={``} type={`file`} accept={`image/png, image/jpeg, image/gif`}
                        onChange={e => {
                            if (e.target.files?.length) {
                                setAvatar_(e.target.files[0]);
                            }
                        }} name={`preview`}></input>
                    {!!avatarUrl ? <>
                        <div className={`w-8 h-8`} style={{
                                backgroundImage: `url(${avatarUrl})`,
                            }}></div>
                        <div className={`row nowrap left gap-0`} style={{position: `absolute`, top: 8, right: 8}}>
                            <button className={`block btn desktop w-4 h-4 b-1 centered`}
                                    onClick={() => { 
                                        // setAvatar_(null); 
                                        // setAvatarUrl(``);
                                        // (document.querySelector(`#input-img`) as HTMLInputElement).value = '';
                                        if (document.querySelector(`#input-img`)) {
                                            (document.querySelector(`#input-img`) as HTMLInputElement).click()
                                        } 
                                    }} type={`button`} data-color={`gray`}>
                                <Edit color={'black'} className={`w-3 h-3`} hover={false}/>
                            </button> 
                            <button className={`block btn desktop w-4 h-4 b-1 centered`}
                                    onClick={() => { 
                                        setAvatar_(null); 
                                        setAvatarUrl(``);
                                        (document.querySelector(`#input-img`) as HTMLInputElement).value = '';
                                    }} type={`button`} data-color={`gray`}>
                                <img src={TrashCoral} alt={``} className={`w-3 h-3`}></img>
                            </button>    
                        </div>                                                            
                    </> :
                    <div className={`w-flex h-8 centered`}>
                        <button className={`block btn-flex desktop centered`} data-color={`gray`} type={`button`}
                                onClick={() => { 
                                    if (document.querySelector(`#input-img`)) {
                                        (document.querySelector(`#input-img`) as HTMLInputElement).click()
                                    } 
                                }}>
                            <p className={`text-6 semibold`} data-color={`black`}>
                                <FormattedMessage id="edit_upload_photo"/>
                            </p>
                        </button>
                    </div>}
                </div>

                <p data-size={8} className={`semibold mb-2`}>
                    <FormattedMessage id="fname"/>*
                </p>
                <input className={`desktop mb-3 ${(profile_?.fname ?? '').trim().length < 2 ? 'in' : ''}valid ${CheckLatin(profile_?.fname) ? 'only-latin' : ''}`} 
                        placeholder={messages[globalContext.locale].fname_ph} type={`text`} required={true} id={`nameInput`}
                        onChange={e => {
                            setProfile_((val: any) => SetDict(val, ['name'], `${e.target.value.trim()} ${(val.lname ?? '').trim()}`));
                            setProfile_((val: any) => SetDict(val, ['fname'], e.target.value));
                        }} value={profile_?.fname ?? ''}></input> 
                <p className={`after neg-mt-3 mb-3 pt-1`} data-color={`coral`}></p>

                <p data-size={8} className={` semibold mb-2`}>
                    <FormattedMessage id="lname"/>*
                </p>
                <input className={`desktop mb-3 ${(profile_?.lname ?? '').trim().length < 2 ? 'in' : ''}valid ${CheckLatin(profile_?.lname) ? 'only-latin' : ''}`} 
                        placeholder={messages[globalContext.locale].lname_ph} type={`text`} required={true} id={`nameInput`}
                        onChange={e => {
                            setProfile_((val: any) => SetDict(val, ['name'], `${(val.fname ?? '').trim()} ${e.target.value.trim()}`));
                            setProfile_((val: any) => SetDict(val, ['lname'], e.target.value));
                        }} value={profile_?.lname ?? ""}></input> 
                <p className={`regular after neg-mt-3 mb-3 pt-1`} data-color={`coral`}></p>

                <p data-size={8} className={` semibold mb-2`}>
                    {`Email`}*
                </p>
                <input className={`desktop ${!EmailRegExp.test(profile_?.links?.email) ? 'in' : ''}valid ${CheckLatin(profile_?.links?.email) ? 'only-latin' : ''}`} type={`email`} required={true} id={`emailInput`}
                        value={profile_?.links?.email ?? ''} onChange={e => {
                            setProfile_((val: any) => SetDict(val, [`links`, `email`], e.target.value));
                        }}></input> 
                <p className={`regular after pt-1`} data-color={`coral`}></p>

                {/* <p data-size={8} className={` semibold mb-2`}>
                    {`LinkedIn*`}
                </p>
                <div className={`row input-block desktop ${!profile_?.links?.linkedin ? 'in' : ''}valid`}>
                    <input className={`desktop`} placeholder={`LinkedIn`} type={`text`} id={`liInput`}
                            onChange={e => {
                                setProfile_((val: any) => SetDict(val, ['links', 'linkedin'], e.target.value))
                            }} value={profile_?.links?.linkedin ?? ""}></input> 
                    <img className={`w-3`} src={LinkedInLogo} alt={`li`}></img>
                </div>
                    <p className={`after pt-1`} data-color={`coral`}><FormattedMessage id="required_field"/></p>                 */}

                {[
                    {title: 'LinkedIn', logo: LinkedInBlack},
                    {title: 'X', logo: XBlack},
                    {title: 'WhatsApp', logo: PhoneBlack},
                    // {title: 'iMessage', logo: AppleBlack},
                    {title: 'Facebook', logo: FacebookBlack},
                    {title: 'Telegram', logo: TelegramBlack},
                ].map((elem, index) => {return (<div key={index}>
                    {((profile_?.links ?? {})[elem.title.toLowerCase()] || (profile_?.links ?? {})[`${elem.title.toLowerCase()}Input`]) ? 
                    <>
                        <p data-size={8} className={` semibold my-2`}>
                            {elem.title}
                        </p>
                        <div className={`row input-block desktop`}>
                            <input className={`desktop`} placeholder={elem.title} type={`text`} id={`waInput`}
                                onChange={e => {
                                    setProfile_((val: any) => SetDict(val, ['links', `${elem.title.toLowerCase()}Input`], true));
                                    setProfile_((val: any) => SetDict(val, ['links', elem.title.toLowerCase()], e.target.value));
                                }} value={profile_?.links[elem.title.toLowerCase()] ?? ""}></input> 
                            <img className={`w-3`} src={elem.logo} alt={``}></img>
                        </div>
                    </> : 
                    <button className={`row btn-circled-24 desktop p-left left mt-2 noscroll`} onClick={() => {
                            setProfile_((val: any) => SetDict(val, ['links', `${elem.title.toLowerCase()}Input`], true));
                        }}>
                        <div className={`btn-symbol w-3 h-3 centered`} data-color={`black`}>
                            <img src={PlusBlack} alt={`+`}></img>
                        </div>    
                        <p data-size={6}>{`Add ${elem.title}`}</p>                      
                    </button>}
                </div>)})}

                <p data-size={8} className={`semibold mb-2 mt-3`}>
                    <FormattedMessage id="industry"/>*
                </p>
                {addIndustry ? <>  
                <input  value={newIndustry} onChange={e => {
                        setNewIndustry(e.target.value);
                    }} onKeyDown={e => {
                        if (e.key === "Enter") {
                            e.preventDefault();
                            e.stopPropagation();
                            if (newIndustry) {
                                setProfile_((val: any) => SetDict(
                                    val, ["industry"],
                                    val?.industry.includes(newIndustry.trim()) ? val?.industry : val?.industry.concat([newIndustry.trim()])
                                ))
                                hideIndustryList();
                            }
                        }
                    }} onFocus={e => {
                        setAddIndustry(true);
                    }} className={`desktop mb-2`} placeholder={messages[globalContext.locale].industry_ph}
                    style={{
                        lineHeight: `30px`, paddingTop: 14, paddingBottom: 14,
                    }} id={`input-industry`}></input>               
                <div className={`row left mb-2`}>
                    {((
                        Industries.filter((val: string) => val.toLowerCase().includes(newIndustry.toLocaleLowerCase().trim()))?.length &&
                        newIndustry.trim()?.length
                    ) ?
                    MergeArrays(profile_?.industry ?? [], Industries.filter((val: string) => val.toLowerCase().includes(newIndustry.toLocaleLowerCase().trim()))) : (profile_?.industry?.length ? profile_?.industry : popularIndustries))
                    .map((elem: string, index: number) => {return (
                        <button className={`block btn-circled-32 desktop row`} 
                                data-color={profile_?.industry.includes(elem) ? 'transparent' : `light-gray`}
                                data-border={profile_?.industry.includes(elem) ? `green-1` : null}
                                onClick={() => {
                                    setProfile_((val: any) => SetDict(
                                        val, ["industry"],
                                        val?.industry.includes(elem) ? val?.industry.filter((val_: string) => val_ !== elem) : val?.industry.concat([elem])
                                    ))
                                    const input = document.querySelector(`#input-tag`) as HTMLInputElement;
                                    if (input) {
                                        input.focus();
                                    }
                                }} type={`button`} id={`select-btn`} key={index}>
                            <p data-color={profile_?.industry.includes(elem) ? 'green' : `black-40-opacity`}>
                                {elem}
                            </p>
                            {profile_?.industry.includes(elem) &&
                            <img src={PlusGreen} alt={`+`} style={{transform: `rotate(45deg)`}} className={` neg-mr w-2`}></img>}
                        </button>
                    )})}
                </div>
                <button className={`block btn-circled-32 desktop row center w-7`} onClick={e => {
                        e.stopPropagation();
                        hideIndustryList();
                    }} data-color={`black`} type={`button`}>
                        <p><FormattedMessage id="apply"/></p>
                </button>
                </> : <>
                <div className={`row left mb-1`} hidden={!profile_?.industry?.length}>
                    {profile_?.industry.map((elem: string, index: number) => 
                    <button className={`block btn-circled-32 desktop row`} data-color={`transparent`} data-border={`green-1`}
                            onClick={() => {
                                setProfile_((val: any) => SetDict(
                                    val, [`industry`],
                                    val?.industry.filter((elem_: string) => elem_ !== elem)
                                )) 
                            }} key={index} type={`button`}>
                        <p data-color={`green`}>{elem}</p>
                        <img src={PlusGreen} alt={`+`} style={{transform: `rotate(45deg)`}} className={` neg-mr w-2`}></img>
                    </button>)}
                </div>
                <button className={`block btn-circled-32 desktop p-left row left noscroll nowrap`} onClick={e => {
                    e.stopPropagation();
                    setAddIndustry(true);
                }} data-color={`black`} type={`button`}>
                    <div className={`btn-symbol w-3 h-3 centered`}>
                        <img src={PlusWhite} alt={`+`}></img>
                    </div>
                    <p><FormattedMessage id="add_industry"/></p>
                </button></>}

                <p data-size={8} className={`semibold mb-2 mt-3`}>
                    <FormattedMessage id="ob_occupation_company"/>*
                </p>
                <input className={`desktop mb-3 ${!profile_?.company ? 'in' : ''}valid ${CheckLatin(profile_?.company) ? 'only-latin' : ''}`} 
                        placeholder={messages[globalContext.locale].occupation_company_ph} 
                        required={true} id={`companyInput`}
                        onChange={e => {
                            setProfile_((val: any) => SetDict(val, ['company'], e.target.value))
                        }} value={profile_?.company ?? ""}></input>
                <p className={`after neg-mt-3 mb-3 pt-1`} data-color={`coral`}></p>

                <p data-size={8} className={`semibold mb-2`}>
                    <FormattedMessage id="ob_occupation_role"/>*
                </p>
                <input className={`desktop mb-3 ${!profile_?.occupation ? 'in' : ''}valid ${CheckLatin(profile_?.occupation) ? 'only-latin' : ''}`} 
                        placeholder={`Enter your role in the company`} 
                        required={true} id={`occupationInput`}
                        onChange={e => {
                            setProfile_((val: any) => SetDict(val, ['occupation'], e.target.value))
                        }} value={profile_?.occupation ?? ""}></input>
                <p className={`after neg-mt-3 mb-3 pt-1`} data-color={`coral`}></p>

                <p data-size={8} className={` semibold mb`}>
                    <FormattedMessage id="profile_tell_about"/>*
                </p>
                <p data-size={6} className={`semibold mb-2`} data-color={`black-40-opacity`}>
                    <FormattedMessage id="ob_intro_hint"/>
                </p>
                <textarea className={`p-2 b-1 h-7 ${!DescriptionLevel(profile_?.about)?.isValid ? 'in' : ''}valid`} 
                        placeholder={`Describe yourself...`} 
                        required={true} id={`aboutInput`}
                        onChange={e => {
                            setProfile_((val: any) => SetDict(val, ['about'], e.target.value))
                        }} value={profile_?.about ?? ""}></textarea> 
                <div className={`block h w-flex mt-1 b row left`} data-color={`light-gray`}>
                    <div className={`h-flex b`} style={DescriptionLevel(profile_?.about)?.style}></div>
                </div>
                {DescriptionLevel(profile_?.about)?.isText &&
                <p data-size={6} className={`mt`}
                    data-color={!DescriptionLevel(profile_?.about)?.isValid ? `coral` : `black-40-opacity`}>
                    <FormattedMessage id={DescriptionLevel(profile_?.about)?.locale_text}/>
                    {/* {DescriptionLevel(profile_?.about)?.text} */}
                </p>}

                <p data-size={8} className={`semibold mb-2 mb-2 mt-3`}>
                    <FormattedMessage id="ob_occupation_city"/>*
                </p>
                <div className={`row input-block desktop ${!profile_?.location?.city ? 'in' : ''}valid ${CheckLatin(profile_?.location?.city) ? 'only-latin' : ''}`}>
                    <input className={`desktop`} placeholder={`Your location`} type={`text`}
                           onChange={e => {
                                setProfile_((val: any) => SetDict(val, ['location', 'city'], e.target.value))
                           }} id={`locationInput`} value={profile_?.location?.city ?? ""}></input> 
                    {/* <img className={`w-3`} src={isInvalid && !profile_?.location.city ? LocationCoral : LocationBlack} alt={`tg`}></img> */}
                </div>
                <p className={`after pt-1`} data-color={`coral`}></p> 

                <div className={`w-flex mb-3`} hidden={true}
                    onMouseEnter={() => {
                        if (avatarScrollElem2.current && 
                            avatarScrollElem2.current.scrollWidth > avatarScrollElem2.current.clientWidth) { 
                            if (avatarScrollElem2.current.scrollLeft > 0) {
                                setShowLeftAvatarBtn(true);
                            }
                            if (avatarScrollElem2.current.scrollLeft < avatarScrollElem2.current.scrollWidth - avatarScrollElem2.current.clientWidth) {
                                setShowRightAvatarBtn(true);
                            }
                        }
                    }} onMouseLeave={() => { 
                        setShowLeftAvatarBtn(false); 
                        setShowRightAvatarBtn(false);
                    }}>
                    <p data-size={8} className={` semibold mb-2`}>
                        <FormattedMessage id="edit_profile_style"/>
                    </p>
                    <div className={`w-flex-p-4 neg-mx-4 row nogap nowrap`}>
                        {showLeftAvatarBtn && 
                        <div className={`w-4 h-flex centered neg-mr-4`}>
                            <button className={`btn btn-symbol filled back centered w-3 h-3 mb-4 mt`} style={{
                                boxShadow: '0 0 13px #00000020',
                                zIndex: 1,
                            }} data-color={'white'} onClick={() => {
                                if (avatarScrollElem2.current) {
                                    avatarScrollElem2.current.scrollTo({
                                        left: avatarScrollElem2.current.scrollLeft - 100, 
                                        behavior: 'smooth'
                                    });
                                }
                            }}>
                                <img className={`h-2`} src={AngleRightBlue} alt={`>`}></img>
                            </button>
                        </div>}
                        <div className={`row x-auto left nowrap noshrink w-flex px-4 py-1 neg-my-1`} ref={avatarScrollElem2}
                            onScroll={() => {
                                if (avatarScrollElem2.current && 
                                    avatarScrollElem2.current.scrollWidth > avatarScrollElem2.current.clientWidth) { 
                                    if (avatarScrollElem2.current.scrollLeft > 0) {
                                        setShowLeftAvatarBtn(true);
                                    } else {
                                        setShowLeftAvatarBtn(false);
                                    }
                                    if (avatarScrollElem2.current.scrollLeft < avatarScrollElem2.current.scrollWidth - avatarScrollElem2.current.clientWidth) {
                                        setShowRightAvatarBtn(true);
                                    } else {
                                        setShowRightAvatarBtn(false);
                                    }
                                }
                            }}>
                            {ProfileStyles.map((elem, index) => {return ((avatarUrl || props?.profile?.avatar?.link || (!!(index - 1) && !!index)) ?                   
                            <button className={`column w-6 top btn-checkbox desktop custom-style`} style={{position: `relative`}}
                                    onClick={() => {setProfile_((val: any) => SetDict(val, ['avatar', 'style'], index))}} key={index}>
                                <NewAvatar size={6} type={index} name={profile_?.name} className={` circled`}
                                        avatar={(!!avatarUrl ? avatarUrl : props.profile?.avatar?.link) ?? ' '}/>  
                                {index === profile_?.avatar?.style &&
                                <div style={{
                                    right: -5, bottom: -5, top: -5, left: -5,
                                    position: `absolute`, borderRadius: 19,
                                }} className={`block`} data-color={`tp-blue-3`}></div>}                          
                            </button> : null
                            )})}
                        </div>
                        {showRightAvatarBtn &&
                        <div className={`w-4 h-flex centered neg-ml-4`}>
                            <button className={`btn btn-symbol filled centered w-3 h-3 mb-4 mt`} style={{
                                boxShadow: '0 0 13px #00000020',
                                zIndex: 1,
                            }} data-color={'white'} onClick={() => {
                                if (avatarScrollElem2.current) {
                                    avatarScrollElem2.current.scrollTo({
                                        left: avatarScrollElem2.current.scrollLeft + 100, 
                                        behavior: 'smooth'
                                    });
                                }
                            }}>
                                <img className={`h-2`} src={AngleRightBlue} alt={`>`}></img>
                            </button>
                        </div>}
                    </div>
                </div>

                {/* <p data-size={8} className={`semibold mb-2 mt-3`}>
                    {`What languages are you fluent in?`}
                </p>
                <div className={`row left noscroll block p-1 b-4 w-flex ${!profile_?.languages?.length ? 'in' : ''}valid`} data-color={`light-gray`}>
                    {profile_ && (profile_?.languages ?? []).map((elem: string, index: number) => {return (
                        <button key={index} className={`block btn-circled-40 desktop b-3 row left nowrap noscroll ${elem.toLowerCase() === 'english' ? `` : `p-left`}`} data-color={`black`}
                                onClick={() => {
                                    if (elem.toLowerCase() !== 'english') {
                                        setProfile_((val: any) => SetDict(val, ['languages'], val.languages.slice(0, index).concat(val.languages.slice(index + 1))));
                                    }
                                }} type={'button'}>
                            {(elem.toLowerCase() !== 'english') &&
                            <div className={`btn-symbol close w-3 h-3 centered`} data-color={`white`}>
                                <img src={PlusWhite} alt={`+`}></img>
                            </div>}
                            <p data-color={'white'}>{elem}</p>
                        </button>
                    )})}
                    {addLang ? 
                    <form className={`block label btn-circled-40 desktop`} data-color={`black-40-opacity`}
                        onSubmit={e => {
                            e.preventDefault();
                            if (addLang && newLang) {
                                setProfile_((val: any) => SetDict(val, ['languages'], (val?.languages ?? []).concat([newLang])));
                                setNewLang('');
                            }
                            setAddLang(val => !val);
                        }} style={{height: 40}}>
                        <input onChange={e => {setNewLang(e.target.value)}} className={`w-7 not-invalid`}
                               autoFocus={true} style={{
                                    backgroundColor: `transparent`,
                                    color: `#FFFFFF`, height: 22,
                               }}></input>
                    </form> :
                    <button className={`btn-circled-40 desktop p-left row left noscroll nowrap e`} onClick={() => {
                        if (addLang && newLang) {
                            setProfile_((val: any) => SetDict(val, ['languages'], (val?.languages ?? []).concat([newLang])));
                            setNewLang('');
                        }
                        setAddLang(val => !val);
                    }} type={'button'}>
                        <div className={`btn-symbol w-3 h-3 centered`} data-color={'black'}>
                            <img src={PlusBlack} alt={`+`}></img>
                        </div>
                        <p data-color={'dark-gray'}><FormattedMessage id="add"/></p>
                    </button>}
                </div> */}

                <button className={`block btn-flex desktop w-8 row center mt-3`} data-color={`black`}
                        onClick={() => {                        
                            setIsInvalid(true);
                            const invalidElem = document.querySelector(`input.invalid, textarea.invalid, .invalid input, .invalid textarea, div.invalid, input.only-latin, textarea.only-latin, .only-latin input, .only-latin textarea, div.only-latin`);
                            if (invalidElem) {
                                window.scrollTo({
                                    top: invalidElem.getBoundingClientRect().top + window.scrollY - 100,
                                    behavior: 'smooth',
                                });
                                (invalidElem as HTMLInputElement).focus();
                            } else {
                                setWaitUpdate(true);
                                ProfileUpdate(profile_?.id, profile_, avatar_, () => {
                                    context.setIsEdit(false);
                                    context.UpdateData();
                                    setWaitUpdate(false);
                                    if (profile_?.links?.email === initEmail) {
                                        context.setTabRaw(0);
                                    } else {
                                        setConfirmEmailPopup(true);
                                    }
                                }, () => { 
                                    setWaitUpdate(false);
                                    setErrorPopup(true);
                                });
                            }
                        }}>
                    <p><FormattedMessage id="settings_profile_save"/></p>
                    {waitUpdate &&
                    <Loading color={`white`} width={24} height={24}/>}
                </button>
            </div>
        </div>
        
        <div style={Sticky(82) as HTMLAttributes<HTMLDivElement>} hidden={true}>
            <div className={`block block p-4 pt-3 b-3 w-flex mb-3`}
                onMouseEnter={() => {
                    if (avatarScrollElem.current && 
                        avatarScrollElem.current.scrollWidth > avatarScrollElem.current.clientWidth) { 
                        if (avatarScrollElem.current.scrollLeft > 0) {
                            setShowLeftAvatarBtn(true);
                        }
                        if (avatarScrollElem.current.scrollLeft < avatarScrollElem.current.scrollWidth - avatarScrollElem.current.clientWidth) {
                            setShowRightAvatarBtn(true);
                        }
                    }
                }} onMouseLeave={() => { 
                    setShowLeftAvatarBtn(false); 
                    setShowRightAvatarBtn(false);
                }}>
                <p data-size={4} className={`semibold mb-2`}><FormattedMessage id="edit_profile_style"/></p>
                <div className={`row nogap nowrap neg-mx-4`}>
                    {showLeftAvatarBtn && 
                    <div className={`w-4 h-flex centered neg-mr-4`}>
                        <button className={`btn btn-symbol filled back centered w-3 h-3 mb-4 mt`} style={{
                            boxShadow: '0 0 13px #00000020',
                            zIndex: 1,
                        }} data-color={'white'} onClick={() => {
                            if (avatarScrollElem.current) {
                                avatarScrollElem.current.scrollTo({
                                    left: avatarScrollElem.current.scrollLeft - 100, 
                                    behavior: 'smooth'
                                });
                            }
                        }}>
                            <img className={`h-2`} src={AngleRightBlue} alt={`>`}></img>
                        </button>
                    </div>}
                    <div className={`row x-auto left nowrap noshrink w-flex min-w-7 px-4 py-1 neg-my-1`} ref={avatarScrollElem}
                        onScroll={() => {
                            if (avatarScrollElem.current && 
                                avatarScrollElem.current.scrollWidth > avatarScrollElem.current.clientWidth) { 
                                if (avatarScrollElem.current.scrollLeft > 0) {
                                    setShowLeftAvatarBtn(true);
                                } else {
                                    setShowLeftAvatarBtn(false);
                                }
                                if (avatarScrollElem.current.scrollLeft < avatarScrollElem.current.scrollWidth - avatarScrollElem.current.clientWidth) {
                                    setShowRightAvatarBtn(true);
                                } else {
                                    setShowRightAvatarBtn(false);
                                }
                            }
                        }}>
                        {ProfileStyles.map((elem, index) => {return ((avatarUrl || props?.profile?.avatar?.link || (!!(index - 1) && !!index)) ?                   
                        <button className={`column w-6 top btn-checkbox desktop custom-style`} style={{position: `relative`}}
                                onClick={() => {setProfile_((val: any) => SetDict(val, ['avatar', 'style'], index))}} key={index}>
                            <NewAvatar size={6} type={index} name={profile_?.name} className={` circled`}
                                    avatar={(!!avatarUrl ? avatarUrl : props.profile?.avatar?.link) ?? ' '}/>  
                            {index === profile_?.avatar?.style &&
                            <div style={{
                                right: -5, bottom: -5, top: -5, left: -5,
                                position: `absolute`, borderRadius: 19,
                            }} className={`block`} data-color={`tp-blue-3`}></div>}                          
                        </button> : null
                        )})}
                    </div>
                    {showRightAvatarBtn &&
                    <div className={`w-4 h-flex centered neg-ml-4`}>
                        <button className={`btn btn-symbol filled centered w-3 h-3 mb-4 mt`} style={{
                            boxShadow: '0 0 13px #00000020',
                            zIndex: 1,
                        }} data-color={'white'} onClick={() => {
                            if (avatarScrollElem.current) {
                                avatarScrollElem.current.scrollTo({
                                    left: avatarScrollElem.current.scrollLeft + 100, 
                                    behavior: 'smooth'
                                });
                            }
                        }}>
                            <img className={`h-2`} src={AngleRightBlue} alt={`>`}></img>
                        </button>
                    </div>}
                </div>
            </div>
        </div>
        </> : <div className={`w-10 b-3 h-10 sm-w-flex skeleton`}></div>}
    </div>
    
    {editOkPopup &&
    <InfoPopup show={editOkPopup} setShow={setEditOkPopup}
               title={`Your updates saved`} desktop={true}
               buttonTitle={<FormattedMessage id="ok"/>} onOk={() => {setEditOkPopup(false)}}/>}

    {errorPopup &&
    <InfoErrorPopup show={errorPopup} setShow={setErrorPopup}
                    title={<FormattedMessage id="reg_error"/>}
                    buttonTitle={`Return to profile`} onOk={() => {
                            setErrorPopup(false);
                            context.setIsEdit(false);
                            context.UpdateData();
                            context.setTabRaw(0);
                    }}/>}

    {confirmEmailPopup &&
    <PopupCenterChangeEmail show={confirmEmailPopup} setShow={setConfirmEmailPopup} desktop={true} mailbox={null}/>}
</>)
}

export {
    EditProfileDesktop,
}