import React, { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { GlobalContext, ProfileContext } from "./Contexts";
import {
    ShowNotificationsPopupButtonDesktop,
    Edit,
    NewAvatar,
} from "./Elements.jsx";
import { 
    CommunityLogo,
    LogoLetters, 
    MakeNiceURL, 
    MakeProperURL, 
    MakeValidURL, 
} from "./Data";
import { 
    AddProjectDesktop, 
    AddProjectPopup, 
    ConfirmPopup, 
    PopupCenterSignIn 
} from "./Popups";
import { 
    ChatBlack, 
    LinkBlack, 
    ListBlack, 
    PageListBlack, 
    ProfileBlack, 
    StarBlack, 
    TrashCoral 
} from "./Imgs";
import { ProjectDelete } from "../Requests";
import { FormattedMessage } from "react-intl";

export const AnimatedLogo = (props: any) => {  
    const [ color, setColor ] = useState<string>(props.color ?? `black`);
    const [ cookie ] = useCookies([`user_id_cookie`]);
    const navigate = useNavigate();

    const [ logoStep, setLogoStep ] = useState<number>(1);
    const [ enableAnimation, setEnableAnimation ] = useState<boolean>(true);

    const sizes = [ 
        `${Math.floor((props.size ?? 32) / 6.55)}px`, 
        `${Math.floor((props.size ?? 32) / 4.1)}px`, 
        `${Math.floor((props.size ?? 32) / 2.56)}px`, 
        `${Math.floor((props.size ?? 32) / 1.6)}px`, 
        `${Math.floor((props.size ?? 32) / 1)}px`, 
        `${Math.floor((props.size ?? 32) / 1.6)}px`, 
        `${Math.floor((props.size ?? 32) / 2.56)}px`, 
        `${Math.floor((props.size ?? 32) / 4.1)}px`, 
        `${Math.floor((props.size ?? 32) / 6.55)}px` 
    ]
    const states = [ 4, 3, 2, 1, 0, 1, 2, 3 ]

    useEffect(() => {
        if (enableAnimation && !props.autoAnimation) {
            returnLogo(logoStep);  
        }      
    }, [logoStep, enableAnimation])

    useEffect(() => {
        if (props.autoAnimation) {
            autoStep(0);
        }
    }, [])

    const autoStep = (n: number) => {
        if (n < 5) {
            setTimeout(() => {
                setLogoStep(val => (val + 1) % 5);
                autoStep(n + 1);
            }, 500);
        }
    }

    const returnLogo = (val: number) => {
        if (val < 1) {
            setTimeout(() => {
                setLogoStep((val + 1) % 5);
            }, 300);
        } else if (val > 1) {
            setTimeout(() => {
                setLogoStep((val + 4) % 5);
            }, 300);
        }
    }

    return (
        <div className={`row center nogap w-6 osmos-logo-desktop nowrap ${props.className} clickable`} style={{...props.style, height: `40px`}} 
                onMouseEnter={() => { setEnableAnimation(false) }} onMouseLeave={() => { setEnableAnimation(true) }}
                onClick={e => {
                    e.stopPropagation();
                    if (typeof props.onClick === `function`) {
                        props.onClick()
                    } else {
                        navigate(cookie[`user_id_cookie`] ? `/profile` : `/`);
                    }
                }}>
            <img src={LogoLetters[color].O} alt={`O`} style={{
                height: sizes[states[logoStep] % 8],
                WebkitFilter: `drop-shadow(0px 0px 20px ${props.shadowColor})`,
                filter: `drop-shadow(0px 0px 7px ${props.shadowColor})`,
            }} onMouseEnter={() => { setLogoStep(0) }}></img>
            <img src={LogoLetters[color].S} alt={`S`} style={{
                height: sizes[(states[logoStep] + 1) % 8], marginRight: 1,
                filter: `drop-shadow(0px 0px 7px ${props.shadowColor})`,
            }} onMouseEnter={() => { setLogoStep(1) }}></img>
            <img src={LogoLetters[color].M} alt={`M`} style={{
                height: sizes[(states[logoStep] + 2) % 8],
                filter: `drop-shadow(0px 0px 7px ${props.shadowColor})`,
            }} onMouseEnter={() => { setLogoStep(2) }}></img>
            <img src={LogoLetters[color].O} alt={`O`} style={{
                height: sizes[(states[logoStep] + 3) % 8],
                filter: `drop-shadow(0px 0px 7px ${props.shadowColor})`,
            }} onMouseEnter={() => { setLogoStep(3) }}></img>
            <img src={LogoLetters[color].S} alt={`S`} style={{
                height: sizes[(states[logoStep] + 4) % 8],
                filter: `drop-shadow(0px 0px 7px ${props.shadowColor})`,
            }} onMouseEnter={() => { setLogoStep(4) }}></img>

            <button style={{height: 0, width: 0, pointerEvents: `none`}} onClick={e => {
                e.stopPropagation();
                if ((e.target as HTMLDivElement).getAttribute(`navbar-color`)) {
                    setColor((e.target as HTMLDivElement).getAttribute(`navbar-color`) ?? `black`); 
                }
            }} id={props.local ? `logo` : `navbar-button-color`}></button>
        </div>
    )
}

export const Loading = (props: any) => {
    return (
        <svg className={`loading`} width={props.width} height={props.height}>
            <g fill="none"
                strokeWidth="10%">
                <circle r="40%" cx="50%" cy="50%"
                        stroke={props.color}
                        // strokeDasharray={`220% 230%`}
                        strokeDasharray={`255% 255%`}
                        strokeLinecap="round"/>
            </g>
        </svg>
)}

export const TopNavBar = (props: any) => {
    const navigate = useNavigate();
    const [ cookie ] = useCookies(["user_id_cookie", "no_sign_in_cookie"]);
    const context = useContext(ProfileContext);   
    const globalContext = useContext(GlobalContext); 

    const [ signInPopup, setSignInPopup ] = useState<boolean>(!cookie[`user_id_cookie`] && !cookie[`no_sign_in_cookie`]);
    const [ bgColor, setBgColor ] = useState<string>();

    const topScroller = () => {
        const topNavBar = document.querySelector(`#feed-top-navigation`) as HTMLDivElement;
        if (topNavBar) {
            topNavBar.style.boxShadow = window.scrollY > 0 ? "0px 3px 3px -2px rgba(0, 0, 0, 0.14)" : "";
        }
    }
    
    useEffect(() => {        
        document.addEventListener('scroll', topScroller);

        return () => {
            document.removeEventListener('scroll', topScroller);
        }
    }, [])

    useEffect(() => {
        setBgColor((document.querySelector(`body`) as HTMLBodyElement).style.backgroundColor);
    }, [(document.querySelector(`body`) as HTMLBodyElement).style.backgroundColor])

    return (<>
        <div style={{
            left: 0, right: 0, top: 0,
            height: 68, position: `fixed`,
        }} id={"feed-top-navigation"}></div>
        <div className={`row nogap w-flex px-5 md-px-3 py-2`} style={{
            flexDirection: `row-reverse`, position: `sticky`,
            zIndex: 50, top: 0, backgroundColor: bgColor?.length ? bgColor : "#F5F5F5",
        }}>
            {!cookie[`user_id_cookie`] ?
            <div className={`row nowrap left`}>
                <a className={`block btn-circled-40 desktop p-left p-right row`}
                    href={`/blog`} hidden={!!globalContext.community}>
                    <img src={PageListBlack} alt={``} className={`w-3`}></img>
                </a> 
                <button className={`block btn-circled-40 desktop`} data-color={`black`} 
                        onClick={() => { setSignInPopup(true) }}>
                    <p><FormattedMessage id="sign_in"/></p>
                </button>
            </div> : <>
            <div className={`row right`} style={{flex: 1}}>
                {!props.hideNav && 
                <ShowNotificationsPopupButtonDesktop disabled={!context.notifications?.length} 
                    number={(context.notifications ?? []).filter((val: any) => !val.is_read && !val.isRead)?.length}/>}
            </div>
            <div className={`row center nowrap noshrink`} style={{flex: 1}}>
            {!props.hideNav && globalContext.community !== undefined && [
                {
                    text: <FormattedMessage id="match"/>,
                    selected: !((context.page !== 5) || props.other), 
                    onClick: () => {
                        if (props.other) {
                            navigate(`/profile/match`);
                        } else {
                            context.setPage(5, 0);
                            context.setTab(0, 5)
                        }
                    },
                    disabled: !context.isSubscriptionActive,
                },
                {
                    text: <FormattedMessage id="feed"/>,
                    selected: !((context.page !== 1) || props.other), 
                    onClick: () => {
                        if (props.other) {
                            navigate(`/profile/explore`);
                        } else {
                            context.setPage(1, 1);
                            context.setTab(1, 1)
                        }
                    },
                    disabled: !context.isSubscriptionActive,
                },
                {
                    text: <FormattedMessage id="chat"/>,
                    selected: !((context.page !== 2) || props.other), 
                    onClick: () => {
                        if (props.other) {
                            navigate(`/profile/chat`);
                        } else {
                            context.setPage(2, 0);
                            context.setTab(0, 2);
                        }
                    }
                },
                // {
                //     text: <FormattedMessage id="match_requests"/>,
                //     selected: !((context.page !== 4) || props.other), 
                //     onClick: () => {
                //         if (props.other) {
                //             navigate(`/profile/requests`);
                //         } else {
                //             context.setPage(4, 0);
                //             context.setTab(0, 4);
                //         }
                //     },
                //     disabled: !context.isSubscriptionActive,
                // },
                {
                    text: <FormattedMessage id="profile"/>,
                    selected: !((context.page !== 0) || props.other), 
                    onClick: () => {
                        if (props.other) {
                            navigate(`/profile/me`);
                        } else {
                            context.setPage(0, 0);
                            context.setTab(0, 0);
                        }
                    }
                },
                // {
                //     text: <FormattedMessage id="invites"/>,
                //     selected: !((context.page !== 3) || props.other), 
                //     onClick: () => {
                //         // setShowNotif(true);
                //         if (props.other) {
                //             navigate(`/profile/invites`);
                //         } else {
                //             context.setPage(3, 0);
                //             context.setTab(0, 3);
                //         }
                //     },
                //     disabled: !context.isSubscriptionActive,
                // },
            ].map((elem, index) => {return (
                <button className={`block btn-circled-40 p-left p-right desktop`} data-color={`transparent`}
                        key={index} onClick={() => { elem.onClick() }}>
                    <p data-color={elem?.selected ? `black` : `black-40-opacity`}>{elem?.text}</p>
                </button>
            )})}
            </div></>}
            <div style={{display: `flex`, flex: 1, opacity: props.community === undefined ? 0 : 1}}>
                {props.community ? 
                <img src={CommunityLogo[props.community]?.topIconBlack ?? CommunityLogo[props.community]?.black} 
                    alt="" style={{height: 40}} className="clickable" onClick={() => { 
                        // const newWindow: WindowProxy | null = window.open(
                        //     CommunityLogo[props.community]?.url ?? `https://osmos.social?nologin=true`, 
                        //     '_blank'
                        // );
                        // if (newWindow) {
                        //     newWindow.focus();
                        // }
                        if (context.setTab && context.setPage) {
                            context.setTab(0, 5);
                            context.setPage(5, 0);
                        } else {
                            navigate("/profile");
                        }
                    }}></img> :
                <AnimatedLogo className={`pl-1`} onClick={props.onLogoClick}/>}
            </div>
        </div>        

        {signInPopup && !props.noSignIn &&
            <PopupCenterSignIn show={signInPopup} setShow={setSignInPopup}/>}
    </>)
}

export const BottomNavBar = (props: any) => {
    const navigate = useNavigate();
    const context = useContext(ProfileContext);
    const [ showNotif, setShowNotif ] = useState<boolean>(false);
    const [ hide, setHide ] = useState<boolean>(false);

    // const [ currentScroll, setCurrentScroll ] = useState(window.scrollY)
    

    useEffect(() => {
        let lastScroll = window.scrollY
        const onScroll = () => {
            if (window.scrollY > lastScroll) {
                setHide(true);
            } else if (window.scrollY < lastScroll) {
                setHide(false);
            }
            lastScroll = window.scrollY;
        };


        window.addEventListener('scroll', onScroll);

        return () => {
            window.removeEventListener("scroll", onScroll);
        }
    }, [])

    return (<>
        {!props.hideBlock &&
        <div style={{
            position: 'sticky',
            bottom: 0,
            zIndex: 10,
            height: 48,
            transform: hide ? `translate(0, 46px)` : undefined,
        }}></div>}
        {showNotif &&
        <div className={`block b-3 p-3`} data-color={`dark-gray`}
             style={{position: `fixed`, left: 14, right: 14, bottom: 62, zIndex: 50}}>
            <p style={{fontSize: 16}} className={`semibold mb-1`}><FormattedMessage id="invites_under_constructing"/></p>
            <p data-size={6} className={`mb-2`}>{`They'll be back soon.`}</p>
            <button className={`block btn-circled-40 mobile w-flex`} onClick={() => { setShowNotif(false) }}>
                <p className={`center`}><FormattedMessage id="close"/></p>
            </button>
        </div>}
        <div className={`block w-flex px-3 box-shadow`} style={{
            position: 'fixed',
            transition: `transform 0.3s ease-in-out`,
            bottom: 0,
            zIndex: 10,
            transform: hide ? `translate(0, 46px)` : undefined,
        }}>
            {/* <div className={`block b-4 h-flex`} style={{
                backgroundImage: `url(${props.profile?.avatar?.link})`, aspectRatio: 1,
            }} data-color={`gray`} onClick={(() => {
                if (props.other) {
                    navigate(`/profile/requests`);
                } else {
                    context.setPage(0, 0);
                    context.setTab(0, 0);
                }
            })}>
                <div className={`neg-m-1 b-4 w-flex-p-1`} style={{
                    border: `solid 8px #1033AF`,
                    height: `calc(100% + 16px)`
                }} hidden={(context.page !== 0) || props.other}></div>
            </div> */}

            <div className={`row nowrap px-2 py w-flex`}>
                {[
                    {
                        icon: StarBlack,
                        selected: !((context.page !== 5) || props.other), 
                        onClick: () => {
                            if (props.other) {
                                navigate(`/profile/match`);
                            } else {
                                context.setPage(5, 0);
                                context.setTab(0, 5)
                            }
                        },
                        // disabled: !context.isSubscriptionActive,
                        disabled: false,
                    },
                    {
                        icon: ListBlack,
                        selected: !((context.page !== 1) || props.other), 
                        onClick: () => {
                            if (props.other) {
                                navigate(`/profile/explore`);
                            } else {
                                context.setPage(1, 1);
                                context.setTab(1, 1)
                            }
                        },
                        // disabled: !context.isSubscriptionActive,
                    },
                    {
                        icon: ChatBlack,
                        selected: !((context.page !== 2) || props.other), 
                        onClick: () => {
                            if (props.other) {
                                navigate(`/profile/chat`);
                            } else {
                                context.setPage(2, 0);
                                context.setTab(0, 2);
                            }
                        }
                    },
                    // {
                    //     icon: DocBlack,
                    //     selected: !((context.page !== 4) || props.other), 
                    //     onClick: () => {
                    //         if (props.other) {
                    //             navigate(`/profile/requests`);
                    //         } else {
                    //             context.setPage(4, 0);
                    //             context.setTab(0, 4);
                    //         }
                    //     },
                    //     disabled: !context.isSubscriptionActive,
                    // },
                    {
                        icon: ProfileBlack,
                        selected: !((context.page !== 0) || props.other), 
                        onClick: () => {
                            if (props.other) {
                                navigate(`/profile/${props.profile?.id}`);
                            } else {
                                context.setPage(0, 0);
                                context.setTab(0, 0);
                            }
                        }
                    },
                    // {
                    //     icon: HumansBlack,
                    //     selected: !((context.page !== 3) || props.other), 
                    //     onClick: () => {
                    //         // setShowNotif(true);
                    //         if (props.other) {
                    //             navigate(`/profile/invites`);
                    //         } else {
                    //             context.setPage(3, 0);
                    //             context.setTab(0, 3);
                    //         }
                    //     },
                    //     disabled: !context.isSubscriptionActive,
                    // },
                ].map((elem, index) => {return (
                    <button className={`w-4 h-4 centered`} key={index} onClick={() => { elem.onClick() }}>
                        <img src={elem.icon} alt={``} className={`h-3`} style={{
                            opacity: elem.disabled ? 0.2 : elem.selected ? 1 : 0.4,
                        }}></img>
                    </button>
                )})}
            </div>
        </div></>
    )
}

export const SiteCard = (props: any) => {
    const [ editPopup, setEditPopup ] = useState<boolean>(false);
    const [ deletePopup, setDeletePopup ] = useState<boolean>(false);
    const [ scrollAnswers, setScrollAnswers ] = useState<boolean>(false);
    const [ plug, setPlug ] = useState<any[]>([]);

    useEffect(() => {
        setScrollAnswers(false);
    }, [props.project])

    useEffect(() => {
        setScrollAnswers(false);
    }, [props.hidden])

    useEffect(() => {
        document.querySelectorAll(`[data-lines]`)
        .forEach(elem => {
            elem.scrollTo({
                top: 0,
                behavior: 'instant',
            })
        })
    }, [scrollAnswers])

    useEffect(() => {
        setPlug([0, 1, 2, 3, 4, 5].reduce((prev: any[], curr: number) => {
            return prev.concat([{
                size: Math.floor(Math.random() * 4) + 1,
                style: {
                    position: `absolute`,
                    top: `${Math.random() * 45 - 10 + 50 * (curr % 2)}%`,
                    left: `${Math.random() * 30 - 10 + 33 * (curr % 3)}%`,
                } 
            }])
        }, []))
    }, [])

    return (<>
        <div className={`block p-2 b-2 ${props.className} ${(props.isEditable && props.desktop) ? "hover-dark-white" : ""}`} 
             style={{
                ...props.style, position: `relative`,
                width: props.desktop ? 283 : `100%`,
            }} hidden={props.hidden} onMouseEnter={e => {
                const localBtns = document.querySelector(`#project-card-btns-${props.project?.id}`) as HTMLButtonElement;
                if (localBtns && props.desktop && props.isEditable) {
                    localBtns.hidden = false;
                }
            }} onMouseLeave={e => {
                const localBtns = document.querySelector(`#project-card-btns-${props.project?.id}`) as HTMLButtonElement;
                if (localBtns && props.desktop && props.isEditable) {
                    localBtns.hidden = true;
                }
            }} onClick={e => {
                const localBtns = document.querySelector(`#project-card-btns-${props.project?.id}`) as HTMLButtonElement;
                const localBlock = document.querySelector(`#project-card-${props.project?.id}`) as HTMLDivElement;
                if (localBtns && localBlock && props.mobile && props.isEditable) {
                    localBlock.style.backgroundColor = localBtns.hidden ? `#FAFAFA` : '';
                    localBtns.hidden = !localBtns.hidden;
                }
            }} id={`project-card-${props.project?.id}`} data-border={`gray-1`}>
            
            <div className={`row left gap-0`} style={{position: `absolute`, top: 8, right: 8, zIndex: 2}}
                    id={`project-card-btns-${props.project?.id}`} hidden={true}>
                <button className={`block btn b centered px-1 btn-box-shadow`} data-color={`white`} 
                        style={{height: 40}}
                        onClick={e => {
                            e.stopPropagation();
                            setEditPopup(true);
                        }} type={`submit`}>
                    <Edit color={'black'} className={`w-3 h-3`} hover={false}/>
                </button>
                <button className={`block btn b centered px-1 btn-box-shadow`} data-color={`white`} 
                        style={{height: 40}}
                        onClick={e => {
                            e.stopPropagation();
                            setDeletePopup(true);
                        }} type={`submit`}>
                    <img src={TrashCoral} alt={``} className={`w-3 h-3`}></img>
                </button>
            </div>
            <div className={`block w-flex b-1 mb-2 row top right`}
                 style={{
                    backgroundImage: `url(${props.project?.preview ? MakeProperURL(props.project?.url, props.project?.preview) : null})`,
                    aspectRatio: 2, position: `relative`, overflow: `hidden`
                 }} data-color={`gray`}>
                    {!props.project?.preview && plug.map((elem: any, index: number) => 
                        <NewAvatar size={elem.size} style={elem.style} type={[`black-5-opacity`, `white-40-opacity`]} key={index}/>)}
            </div>

            <div className={`w-flex column left`}>
                <div className={`w-flex`} style={{height: props.lower ? 146 : 170}}>
                    <p data-size={8} className={`semibold seminarrow mb-1`} data-color={`black`} data-lines={2}>
                        {props.project?.title}
                    </p>
                    <p data-size={6} data-lines={props.lower ? 4 : 5} data-color={'black-40-opacity'}>
                        {(props.project?.description ?? '').trim().split('\n').map((elem: string, index: number) => {return ( elem ?
                            <span key={index} className={`article`}>
                                {elem}
                            </span> : null
                        )})}
                    </p>
                </div>
                <a className={`row left nowrap btn-circled-24 ${props.mobile ? `mobile` : `desktop`} p-left w-flex`} 
                   href={MakeValidURL(props.project?.url)} target={`_blank`} onClick={e => { e.stopPropagation() }}>
                    <img src={LinkBlack} alt={``}></img>
                    <p className={`ellipsis col-7`} style={{fontSize: 16}} data-color={`black`}>
                        {MakeNiceURL(props.project?.url)}
                    </p>
                </a>
            </div>
        </div>
                            
        {props.isEditable && deletePopup &&
        <ConfirmPopup title=<FormattedMessage id="confirm_header"/> subtitle={`Are your sure you want to delete your\u00A0project?`} 
                        show={deletePopup} setShow={setDeletePopup} _id={`yesno`} white={props.desktop}
                        color_2={`light-gray`} buttonTitle_2={<FormattedMessage id="cancel"/>} onClick_2={() => {}} black={props.desktop} desktop={props.desktop}
                        color_1={`coral`} buttonTitle_1={<FormattedMessage id="delete"/>} onClick_1={() => {
                            ProjectDelete(props.project?.id, () => {
                                props.onUpdate();
                            });
                            setDeletePopup(false);
                        }} close={'second'}/>}

        {props.isEditable && editPopup && (props.mobile ? 
        <AddProjectPopup project={props.project} onClose={() => { setEditPopup(false) }}
                        onSubmit={() => {
                                props.onUpdate();
                        }} canClose={true} canDelete={true} onDelete={() => {
                                props.onUpdate();                                             
                        }}/> :
        <AddProjectDesktop show={editPopup} setShow={setEditPopup} canClose={true} 
                        canDelete={true} project={props.project}
                        onSubmit={() => {
                            props.onUpdate();
                        }} onDelete={() => {
                            props.onUpdate();
                        }} headerId={`add-project-popup-content`}/>)}
    </>)
}